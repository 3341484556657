/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from 'react';
import Cookies from 'js-cookie';
/* globals gtag: true */

export const consent = [
    {
        id: 'functionality_storage',
        label: 'Fonctionnel',
        description: 'Témoins requis pour le bon fonctionnement du site',
        value: true,
        disabled: true,
    },
    {
        id: 'analytics_storage',
        label: 'Statistiques',
        description:
            'Témoins utilisés pour mesurer le comportement des utilisateurs sur le site (Google Analytics)',
        value: false,
        disabled: false,
    },
];

export const onSubmit = (values) => {
    const gtagItems = (values || []).reduce((acc, { id = null, value = false }) => {
        if (id !== null) {
            const nextValue = value ? 'granted' : 'denied';
            Cookies.set(`${id}`, nextValue, { secure: true, expires: 60 });
            if (id.match(/_storage$/)) {
                return { ...acc, [id]: nextValue };
            }
            return acc;
        }
        return acc;
    }, {});
    // console.log('call me', gtagItems);
    if (typeof gtag !== 'undefined') {
        // eslint-disable-next-line no-console
        // console.log('consent', gtag, gtagItems);
        gtag('consent', 'update', gtagItems);
    }
    Cookies.set('popup_cookies', false, { secure: true, expires: 182 });
};

const propTypes = {
    showCookiesPopup: React.PropTypes.func.isRequired,
};

const defaultProps = {};

class PopupCookies extends Component {
    constructor(props) {
        super(props);

        this.onClickOpenForm = this.onClickOpenForm.bind(this);
        this.onClickCloseForm = this.onClickCloseForm.bind(this);
        this.onClickAccept = this.onClickAccept.bind(this);
        this.onClickDeny = this.onClickDeny.bind(this);
        this.onClickClose = this.onClickClose.bind(this);
        this.onClickChoice = this.onClickChoice.bind(this);
        this.onClickSave = this.onClickSave.bind(this);

        this.setCookies = this.setCookies.bind(this);

        this.state = {
            collapsed: true,
            choices: (consent || []).map((it) => ({
                ...it,
                value:
                    // eslint-disable-next-line no-nested-ternary
                    Cookies.get(it.id) === 'granted'
                        ? true
                        : Cookies.get(it.id) === 'denied'
                        ? false
                        : it.value,
            })),
        };
    }

    onClickAccept(e) {
        e.preventDefault();
        e.stopPropagation();
        const { choices } = this.state;
        const newChoices = choices.map((c) => ({ ...c, value: true }));
        this.setState({
            choices: newChoices,
        });
        onSubmit(newChoices);
        this.onClickClose(e);
    }

    onClickDeny(e) {
        e.preventDefault();
        e.stopPropagation();
        const { choices } = this.state;
        const newChoices = choices.map((c) => ({ ...c, value: c.disabled }));
        this.setState({
            choices: newChoices,
        });
        onSubmit(newChoices);
        this.onClickClose(e);
    }

    onClickSave(e) {
        e.preventDefault();
        e.stopPropagation();
        const { choices } = this.state;
        this.setState({
            choices,
        });
        onSubmit(choices);
        this.onClickClose(e);
    }

    onClickClose(e) {
        e.preventDefault();
        e.stopPropagation();
        const { showCookiesPopup = null } = this.props;
        if (showCookiesPopup !== null) {
            showCookiesPopup(false);
        }
    }

    onClickOpenForm(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            collapsed: false,
        });
    }

    onClickCloseForm(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            collapsed: true,
        });
    }

    onClickChoice(choices, id, newValue) {
        this.setState({
            choices: (choices || []).reduce((acc, it) => {
                if (it.id === id) {
                    return [...acc, { ...it, value: newValue }];
                }
                return [...acc, it];
            }, []),
        });
    }

    // eslint-disable-next-line class-methods-use-this
    setCookies() {
        const previous = parseInt(Cookies.get('showPopup'), 10) || 0;
        Cookies.set('showPopup', previous + 1, { expires: 7 });
    }

    render() {
        const { choices, collapsed } = this.state;

        return (
            <div className="cookies">
                <button type="button" className="close" onClick={this.onClickClose} />
                <div className="inner">
                    <div className="summary">
                        <div className="top">
                            <h3 className="title">Vos paramètres de confidentialité</h3>
                            <p className="description">
                                Nous utilisons des témoins de connexion (cookies) pour améliorer
                                votre expérience sur notre site et optimiser nos efforts de
                                marketing. En acceptant, vous consentez à l&apos;utilisation de ces
                                témoins.{' '}
                                <a
                                    href="https://nte.qc.ca/politique-de-confidentialite"
                                    className="underline"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Lire notre politique de confidentialité.
                                </a>
                            </p>
                        </div>
                        <div className="buttons">
                            <div className="acceptDeny">
                                <button
                                    type="button"
                                    className="button"
                                    onClick={this.onClickAccept}
                                >
                                    Tout accepter
                                </button>
                                <button type="button" className="button" onClick={this.onClickDeny}>
                                    Tout rejeter
                                </button>
                            </div>
                            <button
                                type="button"
                                className="button"
                                onClick={collapsed ? this.onClickOpenForm : this.onClickCloseForm}
                            >
                                Paramètres
                                {collapsed ? ' ↑' : ' ↓'}
                            </button>
                            {!collapsed
                                ? choices.map(
                                      ({
                                          id = null,
                                          label = null,
                                          value = false,
                                          description = null,
                                          disabled = false,
                                      }) => (
                                          <div className="choice" key={`cookie-${id}`}>
                                              <input
                                                  id={`check-${id}`}
                                                  type="checkbox"
                                                  className="checkbox"
                                                  checked={value || disabled}
                                                  disabled={disabled}
                                                  label={label}
                                                  onClick={() =>
                                                      this.onClickChoice(choices, id, !value)
                                                  }
                                              />
                                              <label
                                                  htmlFor={`check-${id}`}
                                                  className="checkbox-label"
                                              >
                                                  {label}
                                              </label>
                                              <p className="checkbox-description">{description}</p>
                                          </div>
                                      ),
                                  )
                                : null}
                            {!collapsed ? (
                                <button
                                    type="button"
                                    className="button mt-5"
                                    onClick={this.onClickSave}
                                >
                                    Enregistrer mes paramètres
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

PopupCookies.propTypes = propTypes;
PopupCookies.defaultProps = defaultProps;

export default PopupCookies;
