import React from 'react';

import Link from './Link';
import withUrl from '../../lib/withUrl';

const propTypes = {
    url: React.PropTypes.object.isRequired, // eslint-disable-line
    showCookiesPopup: React.PropTypes.func,
};

const defaultProps = {
    showCookiesPopup: null,
};

const Footer = ({ url, showCookiesPopup }) => (
    <div className="footer">
        <div className="left">
            <a
                className="nte-logo"
                href="http://www.nte.qc.ca"
                rel="noopener noreferrer"
                target="_blank"
            >
                <span />
            </a>
            <div className="nte-links">
                <div>
                    <Link to={url.route('about')}>À propos</Link>
                </div>
                <div>
                    <a
                        href="https://nte.qc.ca/equipe-et-coordonnees"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Nous joindre
                    </a>
                </div>
                <div>
                    <a
                        href="https://nte.qc.ca/club-select"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        S&apos;abonner à l&apos;infolettre
                    </a>
                </div>
                <div>
                    <a
                        href="https://nte.qc.ca/politique-de-confidentialite"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Politique de confidentialité
                    </a>
                </div>
            </div>
        </div>
        <div className="right">
            <div className="calq-description">
                <h4>Site réalisé grâce à l’appui du CALQ</h4>
                <p>
                    Mesure d’aide à la numérisation de
                    <br />
                    contenus artistiques et littéraires
                </p>
            </div>
            <a
                className="calq-logo"
                href="https://www.calq.gouv.qc.ca/"
                rel="noopener noreferrer"
                target="_blank"
            >
                <img src="/img/deco/calq-logo.png" alt="calq" />
            </a>
        </div>
        <div className="clearfix" />
        <div className="bottom">
            <p>
                © {new Date().getFullYear()} {'NTE - Tous droits réservés'}
                {showCookiesPopup !== null ? (
                    <span>
                        {' - '}
                        <button
                            type="button"
                            className="button button-plain"
                            onClick={() => showCookiesPopup(true)}
                        >
                            Paramètres de confidentialité
                        </button>
                    </span>
                ) : null}
            </p>
        </div>
    </div>
);

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default withUrl(Footer);
