import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import SoundEffect from '../utils/soundEffect';

const propTypes = {
    mobile: PropTypes.bool.isRequired,
    router: PropTypes.object.isRequired,
    onPause: PropTypes.func,
    onToggleMenu: PropTypes.func.isRequired,
    hidden: PropTypes.bool,
    invisible: PropTypes.bool,
    slug: PropTypes.string,
    items: PropTypes.objectOf(
        PropTypes.shape({
            path: PropTypes.string,
            label: PropTypes.string,
            soundEffect: PropTypes.string,
        }),
    ),
    keys: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
    onPause: null,
    hidden: false,
    invisible: false,
    slug: '1',
    items: [],
    keys: ['nte', 'histoire', 'reflexions', 'rencontres'],
};

function createMarkup(html) {
    return { __html: html };
}

class BottomMenu extends PureComponent {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.renderMenuItem = this.renderMenuItem.bind(this);

        const sounds = {};
        Object.keys(props.items).forEach((key) => {
            sounds[key] = new SoundEffect({ src: props.items[key].soundEffect });
        });

        this.state = {
            sounds,
        };
    }

    onClick(e, key, idx, path) {
        e.preventDefault();
        const { onToggleMenu, hidden, slug, mobile, router, onPause } = this.props;
        if (!mobile && (hidden || key === slug)) {
            onToggleMenu();
        } else {
            if (!mobile) {
                onPause();
            }
            this.playSound(key);
            setTimeout(() => {
                router.push(path);
            }, 2000);
        }
    }

    playSound(name) {
        const { sounds } = this.state;
        Object.keys(sounds).forEach((key, i) => {
            const sound = sounds[key];
            if (name !== i) {
                sound.stop();
            }
        });
        sounds[name].play();
    }

    renderMenuItem(key, idx) {
        if (key === 'all') return null;
        const { items, slug, hidden } = this.props;
        const { path, label, exclude } = items[key];

        if (exclude) {
            return null;
        }

        const linkClassName = classNames({
            'menu-bottom-item': true,
            active: slug === key,
            hidden,
        });

        const iconClassName = classNames({
            'bottom-icon-section': true,
            active: slug === key,
            [`icon-${key}`]: true,
        });

        const passiveIconClassName = classNames({
            'bottom-icon-passive': true,
            active: slug === key,
            [`icon-${key}`]: true,
        });

        const labelClassName = classNames({
            'bottom-label-section': true,
            [`label-${key}`]: true,
        });

        return (
            <button
                key={key}
                type="button"
                className={linkClassName}
                onClick={(e) => {
                    this.onClick(e, key, idx, `${path}/0`);
                }}
            >
                <div className={iconClassName} />
                <div className={passiveIconClassName} />
                <div className={labelClassName}>
                    <span className="outer-span">
                        <span
                            className="inner-span"
                            dangerouslySetInnerHTML={createMarkup(label)}
                        />
                    </span>
                </div>
            </button>
        );
    }

    render() {
        const { hidden, invisible, keys } = this.props;
        const menuClassName = classNames({
            'menu-bottom': true,
            small: hidden,
            invisible,
        });
        return (
            <div
                className={menuClassName}
                ref={(ref) => {
                    this.menu = ref;
                }}
            >
                {keys.map(this.renderMenuItem)}
            </div>
        );
    }
}

BottomMenu.propTypes = propTypes;
BottomMenu.defaultProps = defaultProps;

export default withRouter(BottomMenu);
