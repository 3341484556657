import React, { Component } from 'react';
import Relay from 'react-relay';
import { connect } from 'react-redux';
import { TimelineLite } from 'gsap';
import Cookies from 'js-cookie';

import { getWidth } from '../../lib/getSize';
import GridMenuContainer from '../../containers/GridMenu';
import PopupCookies from '../partials/PopupCookies';
import { showCookiesPopup } from '../../actions/LayoutActions';

import Meta from '../partials/Meta';
import News from '../partials/News';
import Logo from '../partials/Logo';
import Slideshow from '../Slideshow';

import CssScanner from '../CssScanner';
import Splash from '../partials/Splash';

const propTypes = {
    // eslint-disable-next-line react/require-default-props
    bubble: React.PropTypes.shape({
        menu_top: React.PropTypes.arrayOf(
            React.PropTypes.shape({
                title: React.PropTypes.string,
                subtitle: React.PropTypes.string,
                link: React.PropTypes.string,
                // eslint-disable-next-line react/forbid-prop-types
                bubble: React.PropTypes.object,
                section: React.PropTypes.string,
            }),
        ),
        menu_grid: React.PropTypes.shape({
            layout: React.PropTypes.string,
            items: React.PropTypes.arrayOf(
                React.PropTypes.shape({
                    title: React.PropTypes.string,
                    subtitle: React.PropTypes.string,
                    link: React.PropTypes.string,
                    // eslint-disable-next-line react/forbid-prop-types
                    bubble: React.PropTypes.object,
                    section: React.PropTypes.string,
                }),
            ),
        }),
        home_gallery: React.PropTypes.arrayOf(
            React.PropTypes.shape({
                title: React.PropTypes.string,
                subtitle: React.PropTypes.string,
                description: React.PropTypes.string,
                // eslint-disable-next-line react/forbid-prop-types
                picture_full: React.PropTypes.object,
            }),
        ),
    }),
    layout: React.PropTypes.shape({
        isPrerender: React.PropTypes.bool,
        popupCookies: React.PropTypes.bool,
    }).isRequired,
    dispatch: React.PropTypes.func.isRequired,
};

const defaultProps = {
    bubble: {
        menu_top: [],
        menu_grid: {
            layout: 'default',
            items: [],
        },
        home_gallery: [],
    },
};

class BubbleHomePage extends Component {
    constructor(props) {
        super(props);

        this.start = this.start.bind(this);
        this.onScan = this.onScan.bind(this);
        this.onReady = this.onReady.bind(this);
        this.onSlideChange = this.onSlideChange.bind(this);
        this.onHideCookies = this.onHideCookies.bind(this);

        this.onPreviewChange = this.onPreviewChange.bind(this);
        this.changeLogoColor = this.changeLogoColor.bind(this);

        this.timeout = null;
        this.timeline = null;
        this.pageTimeline = null;

        this.splash = null;
        this.pageContent = null;
        this.scanner = null;

        this.state = {
            ready: false,
            ended: false,
            previewIdx: null,
            logoColor: '#000000',
            popupCookies:
                (Cookies.get('popup_cookies') || null) === 'true' ||
                (Cookies.get('popup_cookies') || null) === null,
        };
    }

    onReady() {
        const { layout } = this.props;
        if (!this.state.ready) {
            if (layout.isPrerender === true) {
                this.setState({
                    ready: true,
                });
            } else {
                this.pageTimeline = this.createPageTimeline();
                this.timeline = this.createTimeline();
                this.setState(
                    {
                        ready: true,
                    },
                    () => {
                        this.start();
                    },
                );
            }
        }
    }

    onPreviewChange(previewIdx) {
        this.setState({
            previewIdx,
        });
    }

    onHideCookies(visible = false) {
        const { dispatch } = this.props;
        this.setState({ popupCookies: visible });
        Cookies.set('popup_cookies', false, { secure: true, expires: 182 });
        if (typeof dispatch === 'function') {
            dispatch(showCookiesPopup(visible));
        }
    }

    onScan() {
        if (this.pageTimeline !== null) {
            this.pageTimeline.play(0);
        }
    }

    onSlideChange() {
        if (this.pageTimeline !== null) {
            this.pageTimeline.play(0);
        }
    }

    changeLogoColor(dark = true) {
        this.setState({
            logoColor: dark ? '#000000' : '#FFFFFF',
        });
    }

    createTimeline() {
        const time = 1.3;
        const timeline = new TimelineLite({
            paused: true,
            onComplete: () => {
                if (this.isMounted) {
                    this.setState({
                        ended: true,
                    });
                }
            },
        });

        timeline.add(this.scanner.getUncoverTimeline(), 1);
        timeline.add(this.splash.getTween(time), 1);

        return timeline;
    }

    createPageTimeline() {
        return this.scanner.createPageTimeline();
    }

    /**
     * Child methods
     */

    start() {
        if (!this.timeline.isActive() && this.state.ready === true) {
            this.timeline.getChildren().forEach((child) => {
                child.play();
            });
            this.timeline.play();
        }
    }

    renderInner() {
        const { logoColor, popupCookies } = this.state;
        const menuTop = this.props.bubble.menu_top;
        const menuGrid = this.props.bubble.menu_grid;
        const homeGallery = this.props.bubble.home_gallery;
        const meta = this.props.bubble;
        meta.link = null;

        return (
            <div>
                <div className="page-content">
                    <div
                        className="page-content-container"
                        ref={(ref) => {
                            this.pageContent = ref;
                        }}
                    >
                        <section className="aside-container aside-topleft">
                            <Logo home color={logoColor} />
                        </section>

                        <section className="container-table">
                            <div className="container-middle">
                                <GridMenuContainer
                                    layout={menuGrid.layout}
                                    items={menuGrid.items}
                                    onPreviewChange={this.onPreviewChange}
                                />
                            </div>
                        </section>

                        <section className="aside-container aside-topright">
                            <News items={menuTop} />
                        </section>
                    </div>
                </div>
                <Slideshow
                    items={homeGallery}
                    previewItems={menuGrid.items}
                    onScan={this.onScan}
                    previewIdx={this.state.previewIdx}
                    interfaceVisible={this.state.ended}
                    onChange={this.onSlideChange}
                    changeLogoColor={this.changeLogoColor}
                    autoPlay={getWidth() >= 768}
                />
                {popupCookies ? <PopupCookies showCookiesPopup={this.onHideCookies} /> : null}
            </div>
        );
    }

    render() {
        const { layout } = this.props;
        const meta = this.props.bubble;
        const next = {};
        next.link = null;
        next.title = 'Archives du Nouveau Théâtre Expérimental';

        return (
            <div className="home container">
                <Meta bubble={{ ...meta }} {...next} />
                {layout.isPrerender === true ? (
                    this.renderInner()
                ) : (
                    <Splash
                        visible={!this.state.ended}
                        ref={(ref) => {
                            this.splash = ref;
                        }}
                    >
                        {this.renderInner()}
                    </Splash>
                )}

                <CssScanner
                    ref={(ref) => {
                        this.scanner = ref;
                    }}
                    onReady={this.onReady}
                    hasLines
                    paused
                />
            </div>
        );
    }
}

BubbleHomePage.propTypes = propTypes;
BubbleHomePage.defaultProps = defaultProps;

BubbleHomePage.RelayComponent = Relay.createContainer(BubbleHomePage, {
    fragments: {
        bubble: () =>
            Relay.QL`
                fragment on BubbleHome {
                    bubble_id
                    title
                    snippet {
                        title
                        subtitle
                        link
                    }
                    menu_grid {
                        layout
                        items {
                            title
                            subtitle
                            link
                            bubble {
                                id
                                type
                                snippet {
                                    title
                                    link
                                    picture_splash: picture(filter:"splash"){
                                        url
                                    }
                                }
                            }
                            section
                        }
                    }
                    menu_top {
                        title
                        subtitle
                        link
                        bubble {
                            id
                            type
                            snippet {
                                title
                                link
                            }
                        }
                        section
                    }
                    home_gallery {
                        title
                        subtitle
                        description
                        picture_splash: picture(filter:"splash"){
                            url
                        }
                    }
                }
            `,
    },
});

BubbleHomePage.RelayComponent = connect((state) => ({
    layout: state.layout.toJS(),
}))(BubbleHomePage.RelayComponent);

export default BubbleHomePage;
