import Immutable from 'immutable';
import Cookies from 'js-cookie';

import {
    LAYOUT_SCROLL_Y,
    LAYOUT_SIDEBAR_CLOSE,
    LAYOUT_SIDEBAR_OPEN,
    LAYOUT_HEADER_COLLAPSE,
    LAYOUT_HEADER_UNCOLLAPSE,
    LAYOUT_SHOW_COOKIES_POPUP,
} from '../actions/LayoutActions';

const initialState = {
    sidebarClosed: false,
    headerCollapsed: false,
    scrollY: 0,
    isPrerender: true,
    popup: null,
    newsletter: null,
    popupCookies:
        (Cookies.get('popup_cookies') || null) === 'true' ||
        (Cookies.get('popup_cookies') || null) === null,
};

const LayoutReducer = (previousState, action) => {
    // console.log('hey', previousState ? previousState.toJS() : null, action);
    const state = previousState || new Immutable.Map(initialState);

    switch (action.type) {
        case LAYOUT_SCROLL_Y:
            return state.set('scrollY', action.payload);
        case LAYOUT_SHOW_COOKIES_POPUP:
            return state.set('popupCookies', action.payload);
        case LAYOUT_SIDEBAR_CLOSE:
            return state.set('sidebarClosed', true);
        case LAYOUT_SIDEBAR_OPEN:
            return state.set('sidebarClosed', false);
        case LAYOUT_HEADER_COLLAPSE:
            return state.set('headerCollapsed', true);
        case LAYOUT_HEADER_UNCOLLAPSE:
            return state.set('headerCollapsed', false);
        default:
            return state;
    }
};

export default LayoutReducer;
