import reduce from 'lodash/reduce';

class URL {

    constructor(routes) {
        this.routes = routes;
    }

    route(key, opts) {
        const prefix = typeof window !== 'undefined' ? `http://${window.location.host}` : '';
        let url = (typeof this.routes[key] !== 'undefined' ? this.routes[key] : key).replace(prefix, '');
        if (typeof opts !== 'undefined') {
            url = reduce(opts, (str, v, k) => str.replace(`:${k}`, v), url);
        }
        if (url === '' || url === null) {
            url = '/';
        }
        // console.log('key', key, opts, url);
        return url;
    }
}

export default URL;
