import { TimelineLite, Sine } from 'gsap';
import * as PIXI from 'pixi.js';
import merge from 'lodash/merge';
import { getSizeWithinMax } from '../utils/size';

class Decor {
    constructor(props = { width: 0, height: 0 }) {
        this.props = merge(
            {
                time: 2,
                width: 300,
                height: 300,
                margin: 100,
                marginBottom: 150,
                color: 0x404040,
            },
            props,
        );

        this.graphics = null;
        this.timeline = null;
        this.resizeTimeline = null;
        this.createTimeline = null;

        this.container = new PIXI.Container();
        this.containers = [];

        return this;
    }

    timeline(start = 0, onComplete = null) {
        const timeline = new TimelineLite({
            // onComplete: () => {},
        });
        return timeline;
    }

    resize(props) {
        this.props = merge(this.props, props);

        if (this.resizeTimeline !== null) {
            this.resizeTimeline.kill();
            this.resizeTimeline = null;
        }

        this.resizeTimeline = new TimelineLite({
            onComplete: () => {
                this.containers.forEach(c =>
                    c.destroy({
                        children: true,
                    }),
                );
                this.containers = [];
                this.create();
            },
        });

        if (this.containers) {
            this.resizeTimeline.to(this.containers, 1, { alpha: 0, ease: Sine.easeInOut });
        }
    }

    create() {
        const { width, height, margin, marginBottom } = this.props;
        // set a fill and a line style again and draw a rectangle

        for (let i = 0; i < 4; i += 1) {
            const c = new PIXI.Container();
            c.alpha = 0;
            this.container.addChild(c);
            this.containers.push(c);
        }

        // Bottom
        let graphics = new PIXI.Graphics();
        graphics.lineStyle(0, 0x000000, 1);
        graphics.beginFill(0x222222, 1);
        graphics.drawPolygon(
            new PIXI.Polygon(
                new PIXI.Point(0, height),
                new PIXI.Point(width, height),
                new PIXI.Point(width - margin, height - marginBottom),
                new PIXI.Point(0 + margin, height - marginBottom),
                new PIXI.Point(0, height),
            ),
        );
        this.containers[0].addChild(graphics);

        // Right
        graphics = new PIXI.Graphics();
        graphics.lineStyle(0, 0x000000, 1);
        graphics.beginFill(0x222222, 0.5);
        graphics.drawPolygon(
            new PIXI.Polygon(
                new PIXI.Point(width, height),
                new PIXI.Point(width, 0),
                new PIXI.Point(width - margin, 0 + margin),
                new PIXI.Point(width - margin, height - marginBottom),
                new PIXI.Point(width, height),
            ),
        );
        this.containers[1].addChild(graphics);

        // Top
        graphics = new PIXI.Graphics();
        graphics.lineStyle(0, 0x000000, 1);
        graphics.beginFill(0x222222, 0.7);
        graphics.drawPolygon(
            new PIXI.Polygon(
                new PIXI.Point(width, 0),
                new PIXI.Point(0, 0),
                new PIXI.Point(0 + margin, 0 + margin),
                new PIXI.Point(width - margin, 0 + margin),
                new PIXI.Point(width, 0),
            ),
        );
        this.containers[2].addChild(graphics);

        // Left
        graphics = new PIXI.Graphics();
        graphics.lineStyle(0, 0x000000, 1);
        graphics.beginFill(0x222222, 0.5);
        graphics.drawPolygon(
            new PIXI.Polygon(
                new PIXI.Point(0, 0),
                new PIXI.Point(0, height),
                new PIXI.Point(0 + margin, height - marginBottom),
                new PIXI.Point(0 + margin, 0 + margin),
                new PIXI.Point(0, 0),
            ),
        );
        this.containers[3].addChild(graphics);

        if (this.createTimeline !== null) {
            this.createTimeline.kill();
            this.createTimeline = null;
        }

        this.createTimeline = new TimelineLite();
        this.createTimeline.to(this.containers[0], 1, { alpha: 1 });
        // this.createTimeline.staggerTo(
        //     [this.containers[1], this.containers[3], this.containers[2]],
        //     0.3,
        //     {
        //         cycle: {
        //             alpha: () => 1,
        //         },
        //         ease: Sine.easeInOut,
        //     },
        //     1,
        // );
    }

    destroy() {
        this.containers.forEach((container) => {
            container.destroy({
                children: true,
            });
        });
    }

    getMaxSize() {
        return getSizeWithinMax(
            this.sprite.width,
            this.sprite.height,
            this.props.width,
            this.props.height,
            { cover: false },
        );
    }
}

export default Decor;
