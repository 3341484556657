/* global PIXI */
import { TimelineMax, Sine } from 'gsap';
// import Chance from 'chance';
import filters from './filters';

// const chance = new Chance();

class Loading extends PIXI.Container {
    constructor(props) {
        super(props);

        this.timeline = null;

        this.sprite = null;
        this.pixelFilter = null;

        // this.innerWidth = props.width;
        // this.innerHeight = props.height;

        this.timeline = null;

        this.play = this.play.bind(this);
        this.resize = this.resize.bind(this);

        this.build(props.width, props.height);

        return this;
    }

    resize({ width, height }) {
        if (this.timeline !== null) {
            this.timeline.kill();
            this.timeline = null;
        }
        // this.innerWidth = width;
        // this.innerHeight = height;
        this.destroy();
        this.build(width, height);
    }

    play() {
        if (this.timeline !== null) {
            this.timeline.restart();
        }
    }

    build(width, height) {
        this.sprite = Loading.createRedGlow(width, height);
        this.addChild(this.sprite);

        this.pixelFilter = new filters.AsciiFilter();
        const size = Math.floor(width / 10);
        this.pixelFilter.size = size / 4;

        this.sprite.filters = [this.pixelFilter];

        this.timeline = this.getTimeline();
    }

    getTimeline() {
        const glow = this.sprite;
        const timeline = new TimelineMax({
            repeat: -1,
            yoyo: true,
            onStart: () => {
                console.log('start loading');
            },
            onComplete: () => {
                console.log('done loading');
                if (this.pixelFilter !== null) {
                    // this.pixelFilter.size = chance.integer({ min: 60, max: 61 });
                }
            },
        });

        timeline.to(
            glow,
            2,
            {
                alpha: 0,
                ease: Sine.easeIn,
            },
            0,
        );

        timeline.to(
            glow,
            2,
            {
                alpha: 0,
                ease: Sine.easeIn,
            },
            0,
        );

        // timeline.to(
        //     this.pixelFilter,
        //     2,
        //     {
        //         size: chance.integer({ min: 20, max: 30 }),
        //     },
        //     0,
        // );

        return timeline;
    }

    destroy() {
        if (this.sprite !== null) {
            this.sprite.destroy();
        }
    }

    static createRedGlow(width, height) {
        const glow = PIXI.Sprite.fromImage('/img/scanner/loading.png');
        const size = Math.floor(width / 10);
        const halfSize = size / 2;
        const sixthSize = size / 6;
        const twelvethSize = size / 12;
        glow.anchor.x = 0;
        glow.anchor.y = 0;
        glow.x = Math.floor(width / 2) - halfSize;
        glow.y = Math.floor(height / 2) - halfSize;
        glow.width = Math.floor(size) - twelvethSize;
        glow.height = Math.floor(size) - sixthSize;
        glow.alpha = 0.1;

        return glow;
    }
}

export default Loading;
