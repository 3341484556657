import React, { Component } from 'react';
import find from 'lodash/find';
import GridLayouts from './grids/index';

const propTypes = {
    layout: React.PropTypes.string,
    items: React.PropTypes.array,
    preview: React.PropTypes.bool,
    onPreviewChange: React.PropTypes.func.isRequired,
    sections: React.PropTypes.array.isRequired,
    url: React.PropTypes.object.isRequired,
};

const defaultProps = {
    layout: 'default',
    items: [],
    preview: false,
    sections: null,
};

class GridMenu extends Component {
    getLink(item) {
        const { sections, url } = this.props;
        let link = url.route('catalog.index');
        let internal = true;
        if (item.link) {
            link = `http://${item.link}`;
            internal = false;
        } else if (item.bubble !== null) {
            link =
                item.bubble.snippet && item.bubble.snippet.link
                    ? item.bubble.snippet.link
                    : url.route('catalog.index');
            internal = true;
        } else if (item.section !== null) {
            internal = true;
            if (item.section === 'catalog') {
                link = url.route('catalog.index');
            } else {
                const section = sections.find(sec => sec.key === item.section);
                if (section) {
                    const route = url.route(`catalog.${section.key}.index`);
                    if (route) {
                        link = route;
                    }
                }
            }
        }

        return { link, internal };
    }

    format(items) {
        return items.map((item, key) => {
            const defaultItem = {
                id: key,
                title: '',
                subtitle: 'Te',
                link: '/',
                preview: this.props.preview,
                internal: false,
            };
            if (item !== null) {
                if (item.title) {
                    defaultItem.title = item.title;
                }
                if (item.subtitle) {
                    defaultItem.subtitle = item.subtitle;
                }
                if (this.props.preview === false) {
                    const linkObject = this.getLink(item);
                    defaultItem.link = linkObject.link;
                    defaultItem.internal = linkObject.internal;
                }
            }
            return defaultItem;
        });
    }

    render() {
        const { layout } = this.props;
        const items = this.format(this.props.items);

        if (layout === null) {
            return false;
        }

        const GridLayout = find(
            GridLayouts,
            (entry, key) => key.toLowerCase() === layout.toLowerCase(),
        );

        if (!GridLayout) {
            return null;
        }

        return (
            <div className="grid-menu">
                <GridLayout items={items} onPreviewChange={this.props.onPreviewChange} />
            </div>
        );
    }
}

GridMenu.propTypes = propTypes;
GridMenu.defaultProps = defaultProps;

export default GridMenu;
