import React, { Component } from 'react';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import { TimelineMax, TweenMax } from 'gsap';
import Transition from 'react-transition-group/Transition';

import { getWidth, getMobile } from '../../lib/getSize';
import CssScanner from '../CssScanner';
import HeaderContainer from '../../containers/partials/Header';
import FullContainer from '../../containers/layouts/Full';
import Popup from '../partials/Popup';
import PopupCookies from '../partials/PopupCookies';

const propTypes = {
    location: React.PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    sidebarClosed: React.PropTypes.bool.isRequired,
    headerCollapsed: React.PropTypes.bool.isRequired,
    closeSidebar: React.PropTypes.func.isRequired,
    updateScroll: React.PropTypes.func.isRequired,
    openSidebar: React.PropTypes.func.isRequired,
    collapseHeader: React.PropTypes.func.isRequired,
    uncollapseHeader: React.PropTypes.func.isRequired,
    children: React.PropTypes.oneOfType([
        React.PropTypes.arrayOf(React.PropTypes.node),
        React.PropTypes.node,
    ]).isRequired,
    scrollThreshold: React.PropTypes.number,
    isPrerender: React.PropTypes.bool.isRequired,
    popup: React.PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    showCookiesPopup: React.PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
    popupCookies: React.PropTypes.bool.isRequired, // eslint-disable-line react/forbid-prop-types
};

const defaultProps = {
    scrollThreshold: 10,
};

class MainLayout extends Component {
    constructor(props) {
        super(props);

        this.ticking = false;
        this.timeline = null;
        this.children = null;
        this.scanner = null;

        // To enable page transitions
        this.onReady = this.onReady.bind(this);
        this.onResize = this.onResize.bind(this);
        this.toggleHeader = this.toggleHeader.bind(this);
        this.onHeaderToggleClick = this.onHeaderToggleClick.bind(this);

        this.onPageScroll = this.onPageScroll.bind(this);

        // console.log(window.innerWidth);

        this.state = {
            width: getWidth(),
            // height: getHeight(),
            mobile: getMobile(),
            animationReady: props.isPrerender,
            sidebarScrollTop: 0,
            pageScrollTop: 0,
            location: props.location.pathname,
        };
    }

    componentDidMount() {
        const { mobile } = this.state;
        const { isPrerender } = this.props;

        window.addEventListener('resize', this.onResize);
        window.addEventListener('scroll', this.onPageScroll);

        if (!isPrerender) {
            TweenMax.set(this.children, {
                autoAlpha: 0,
            });
        }

        if (mobile) {
            this.onReady();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const scrollChanged = this.state.pageScrollTop !== prevState.pageScrollTop;
        const locationChanged = prevProps.location.pathname !== this.props.location.pathname;
        if (scrollChanged) {
            this.toggleHeader();
        }

        // Note: dont't change this
        if (locationChanged) {
            this.onLocationChange();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onPageScroll);
        window.removeEventListener('resize', this.onResize);
    }

    onLocationChange() {
        this.setState({
            location: this.props.location.pathname,
        });
    }

    onHeaderToggleClick(e) {
        if (e) {
            e.preventDefault();
        }
        if (this.props.sidebarClosed) {
            this.props.openSidebar();
        } else {
            this.props.closeSidebar();
        }
    }

    onPageScroll() {
        if (!this.ticking) {
            const scrollTop =
                window.scrollY ||
                (document.documentElement && document.documentElement.scrollTop) ||
                document.body.scrollTop;
            window.requestAnimationFrame(() => {
                this.scroll(scrollTop);
                this.ticking = false;
            });
        }
        this.ticking = true;
    }

    onReady() {
        const { animationReady } = this.state;
        if (!animationReady) {
            this.timeline = this.createTimeline();
            this.setState({
                animationReady: true,
            });
        }
    }

    onResize() {
        this.setState({
            width: getWidth(),
            // height: getHeight(),
        });
    }

    scroll(scrollTop) {
        const { updateScroll, scrollThreshold } = this.props;
        const { pageScrollTop } = this.state;
        if (
            (scrollTop < scrollThreshold && pageScrollTop >= scrollThreshold) ||
            (scrollTop > scrollThreshold && pageScrollTop <= scrollThreshold)
        ) {
            this.setState({
                pageScrollTop: scrollTop,
            });
            updateScroll(scrollTop);
        }
    }

    createTimeline() {
        const timeline = new TimelineMax();

        timeline.to(this.children, 1, { autoAlpha: 1 }, 0);

        if (this.scanner !== null && this.scanner.pageTimeline) {
            timeline.add(this.scanner.pageTimeline, 0);
        }

        timeline.play();

        return timeline;
    }

    toggleHeader() {
        const threshold = this.props.scrollThreshold;
        if (
            this.props.headerCollapsed &&
            this.state.sidebarScrollTop <= threshold &&
            this.state.pageScrollTop <= threshold
        ) {
            this.props.uncollapseHeader();
        } else if (
            !this.props.headerCollapsed &&
            (this.state.sidebarScrollTop > threshold || this.state.pageScrollTop > threshold)
        ) {
            this.props.collapseHeader();
        }
    }

    render() {
        const { width } = this.state;

        const {
            headerCollapsed,
            sidebarClosed,
            location,
            isPrerender,
            popup,
            popupCookies,
            showCookiesPopup,
        } = this.props;

        const isFull = this.props.children.type === FullContainer;

        const layoutClassNames = classNames({
            layout: true,
            'layout-main': true,
            'sidebar-closed': sidebarClosed,
            'header-collapsed': headerCollapsed,
        });

        const enter = location.pathname !== this.state.location;

        // console.log('popupCookies', popupCookies);

        return (
            <div className={layoutClassNames}>
                <HeaderContainer
                    toggleSidebarButton={!isFull}
                    closed={sidebarClosed}
                    collapsed={headerCollapsed}
                    onToggleClick={this.onHeaderToggleClick}
                />
                {isPrerender ? (
                    <div
                        className="children"
                        ref={(ref) => {
                            this.children = ref;
                        }}
                    >
                        <div key={location.pathname}>{this.props.children}</div>
                    </div>
                ) : (
                    <Transition
                        in={enter}
                        timeout={1000}
                        enter
                        exit={false}
                        onEnter={() => {
                            this.timeline.play(0);
                        }}
                    >
                        <div
                            className="children"
                            ref={(ref) => {
                                this.children = ref;
                            }}
                        >
                            <div key={location.pathname}>{this.props.children}</div>
                        </div>
                    </Transition>
                )}
                {!isPrerender && width > 767 ? (
                    <CssScanner
                        ref={(ref) => {
                            this.scanner = ref;
                        }}
                        onReady={this.onReady}
                    />
                ) : null}
                {popup !== null ? (
                    <div className="modals">
                        <Popup popup={popup} pathname={location.pathname} />
                    </div>
                ) : null}
                {popupCookies === true ? (
                    <PopupCookies showCookiesPopup={showCookiesPopup} />
                ) : null}
            </div>
        );
    }
}

MainLayout.propTypes = propTypes;
MainLayout.defaultProps = defaultProps;

export default withRouter(MainLayout);
