/* global PIXI */
import React, { Component } from 'react';
import Relay from 'react-relay';
import classNames from 'classnames';
import { TweenMax } from 'gsap';

import AudioObject from '../lib/Audio';

const propTypes = {
    src: React.PropTypes.string.isRequired,
    duration: React.PropTypes.number.isRequired,
    pictureURL: React.PropTypes.string,
    maskURL: React.PropTypes.string,
    maskLoadingURL: React.PropTypes.string,
};

const defaultProps = {
    pictureURL: '',
    maskURL: '',
    maskLoadingURL: '',
};

class Audio extends Component {
    constructor(props) {
        super(props);

        this.listenTracker = null;

        // The audio stuff
        this.onPlay = this.onPlay.bind(this);
        this.onSeek = this.onSeek.bind(this);
        this.onListen = this.onListen.bind(this);

        this.onAudioPlay = this.onAudioPlay.bind(this);
        this.onAudioStop = this.onAudioStop.bind(this);
        this.onAudioLoaded = this.onAudioLoaded.bind(this);

        this.onLoad = this.onLoad.bind(this);
        this.listenTrack = this.listenTrack.bind(this);
        this.clearListenTrack = this.clearListenTrack.bind(this);
        this.onResize = this.onResize.bind(this);

        this.loadPct = 0;
        this.audio = null;

        this.refPlayer = null;
        this.refBar = null;
        this.refSeek = null;

        // The animated background
        this.mask = null;

        // this.onReady = this.onReady.bind(this);
        // this.onStageReady = this.onStageReady.bind(this);
        //  this.onStageSizeChange = this.onStageSizeChange.bind(this);
        // this.onDestroy = this.onDestroy.bind(this);

        this.state = {
            playing: false,
            loaded: false,
            sceneWidth: 0,
        };
    }

    componentDidMount() {
        this.createAudio();
        this.loadingTracker = window.requestAnimationFrame(this.onLoad);
        this.onResize();
        window.addEventListener('resize', this.onResize);
    }

    componentWillReceiveProps(nextProps) {
        const { src } = this.props;
        const srcChanged = src !== nextProps.src;
        if (srcChanged) {
            this.createAudio();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const loadChanged = prevState.loaded !== this.state.loaded;

        if (loadChanged && this.state.loaded === true) {
            if (this.loadingMask !== null) {
                if (this.loadingMask !== null && this.refBar !== null) {
                    TweenMax.to(this.loadingMask, 0.1, {
                        width: 0,
                    });
                    TweenMax.to(this.refBar, 0.1, {
                        width: 0,
                        backgroundColor: '#3333cc',
                    });
                }
            }

            if (this.loadingTracker) {
                window.cancelAnimationFrame(this.loadingTracker);
                this.loadingTracker = null;
            }
        }
    }

    componentWillUnmount() {
        if (this.audio !== null) {
            this.audio.howl.off('play', this.onAudioPlay);
            this.audio.howl.off('load', this.onAudioLoaded);

            this.audio.howl.off('playerror', this.onAudioStop);
            this.audio.howl.off('loaderror', this.onAudioStop);
            this.audio.howl.off('end', this.onAudioStop);
            this.audio.howl.off('stop', this.onAudioStop);
            this.audio.howl.off('pause', this.onAudioStop);

            if (this.state.playing) {
                this.audio.pause();
            }
            this.audio.destroy();
        }

        this.clearListenTrack();
        window.removeEventListener('resize', this.onResize);
    }

    onResize() {
        if (this.refPlayer !== null) {
            const bounds = this.refPlayer.getBoundingClientRect();
            const { width = 0 } = bounds;
            this.setState({
                sceneWidth: width,
            });
        }
    }

    /**
     * Audio events
     */

    onAudioLoaded() {
        this.setState({
            loaded: true,
        });
    }

    onAudioPlay() {
        this.listenTrack();
    }

    onAudioStop() {
        this.setState(
            {
                playing: false,
            },
            () => {
                this.clearListenTrack();
            },
        );
    }

    onPlay(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setPosition();

        if (this.state.playing) {
            this.audio.pause();
        } else {
            this.audio.play();
        }

        this.setState({
            playing: !this.state.playing,
        });
    }

    onSeek(e) {
        let posx = 0;
        if (e.pageX) {
            posx = e.pageX;
        } else if (e.clientX || e.clientY) {
            posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
        }
        const rect = this.refSeek.getBoundingClientRect();
        const offset = posx - rect.left;
        const max = rect.right - rect.left;
        const pct = (offset / max) * 100;
        const currentTime = (pct / 100) * this.props.duration;

        this.audio.seek(currentTime.toFixed(2));
        this.setPosition();
        if (!this.audio.howl.playing()) {
            this.audio.play();
        }

        if (!this.state.playing) {
            this.setState({
                playing: true,
            });
        }
    }

    onLoad(e, loop = true) {
        this.loadPct += 0.001;
        const width = Math.min(
            parseInt(this.loadPct * this.state.sceneWidth, 10),
            this.state.sceneWidth,
        );
        if (this.loadingMask !== null && this.refBar !== null) {
            TweenMax.to(this.loadingMask, 0.1, {
                width,
            });
            TweenMax.to(this.refBar, 0.1, {
                width,
                backgroundColor: '#333333',
            });
        }
        if (loop === true) {
            this.loadingTracker = window.requestAnimationFrame(this.onLoad);
        }
    }

    onListen() {
        const pct = this.audio.seek() / this.props.duration;
        const width = Math.min(parseInt(pct * this.state.sceneWidth, 10), this.state.sceneWidth);

        if (this.mask !== null && this.refBar !== null && width !== this.mask.width) {
            TweenMax.set(this.mask, {
                width: '100%',
                clipPath: `inset(0px ${this.state.sceneWidth - width}px 0px 0px)`,
            });
            TweenMax.set(this.refBar, {
                width,
            });
        }
        this.listenTracker = window.requestAnimationFrame(this.onListen);
    }

    setPosition() {
        const pct = this.audio.seek() / this.props.duration;
        const width = Math.min(parseInt(pct * this.state.sceneWidth, 10), this.state.sceneWidth);

        if (this.mask !== null && this.refBar !== null) {
            TweenMax.set(this.mask, {
                width: '100%',
                clipPath: `inset(0px ${this.state.sceneWidth - width}px 0px 0px)`,
            });
            TweenMax.set(this.refBar, {
                width,
            });
        }
    }

    listenTrack() {
        if (this.listenTracker === null) {
            this.listenTracker = window.requestAnimationFrame(this.onListen);
        }
    }

    clearListenTrack() {
        if (this.listenTracker !== null) {
            window.cancelAnimationFrame(this.listenTracker);
            this.listenTracker = null;
            this.setPosition();
        }
    }

    /* Audio */

    createAudio() {
        const { src } = this.props;
        if (this.audio === null && src) {
            this.audio = new AudioObject(src, {
                muted: false,
                loop: false,
                volume: 1,
                preload: true,
            });

            this.audio.howl.on('play', this.onAudioPlay);
            this.audio.howl.on('load', this.onAudioLoaded);

            this.audio.howl.on('playerror', this.onAudioStop);
            this.audio.howl.on('loaderror', this.onAudioStop);
            this.audio.howl.on('end', this.onAudioStop);
            this.audio.howl.on('stop', this.onAudioStop);
            this.audio.howl.on('pause', this.onAudioStop);
        }
    }

    render() {
        const playbackBtnClass = classNames({
            btn: true,
            'btn-playback': true,
            'btn-playback-loading': !this.state.loaded,
            'btn-playback-playing': this.state.playing,
            'btn-playback-paused': !this.state.playing,
        });

        const callback = this.state.loaded ? this.onSeek : null;

        // TODO: improve this

        return (
            <div
                className="audio-player"
                ref={(ref) => {
                    this.refPlayer = ref;
                }}
            >
                <div className="audio-player-inner">
                    <button type="button" className={playbackBtnClass} onClick={this.onPlay} />
                </div>
                <div className="audio-player-playback">
                    <div
                        className="audio-player-playback-current"
                        ref={(ref) => {
                            this.refBar = ref;
                        }}
                    />
                </div>
                <button
                    type="button"
                    className="btn audio-player-seek"
                    onClick={callback}
                    ref={(ref) => {
                        this.refSeek = ref;
                    }}
                >
                    <img className="waveform" src={this.props.pictureURL} alt="visualisation" />
                    <img
                        className="waveform mask"
                        ref={(ref) => {
                            this.mask = ref;
                        }}
                        src={this.props.maskURL}
                        alt="visualisation"
                    />
                    <img
                        className="waveform mask"
                        ref={(ref) => {
                            this.loadingMask = ref;
                        }}
                        src={this.props.maskLoadingURL}
                        alt="visualisation"
                    />
                </button>
            </div>
        );
    }
}

Audio.propTypes = propTypes;
Audio.defaultProps = defaultProps;

const AudioContainer = (props) => {
    const { audio } = props;

    if (!audio) {
        return null;
    }

    const { src, duration, name } = audio;

    /**
     *  OVERRIDE THINGS TO TEST HERE
     */
    // const name = audio.name;
    // const src = '/files/audio/2016-09-27/1-071616.mp3';
    // const duration = 1623;

    const minutes = Math.floor(duration / 60);
    let seconds = Math.floor(duration - minutes * 60); // eslint-disable-line
    seconds = seconds >= 10 ? seconds : `0${seconds}`;
    const time = `${minutes}:${seconds}`;

    const caption = name.replace('Bobines/', '').replace('.mp3', '');

    const pictureFull = audio.picture_full;
    const pictureMask = audio.picture_mask;
    const pictureMaskLoading = audio.picture_mask_loading;

    const pictureURL = pictureFull ? pictureFull.url : '';
    const maskURL = pictureMask ? pictureMask.url : '';
    const maskLoadingURL = pictureMaskLoading ? pictureMaskLoading.url : '';

    return (
        <div className="container container-audio">
            <Audio
                src={src}
                duration={duration}
                pictureURL={pictureURL}
                maskURL={maskURL}
                maskLoadingURL={maskLoadingURL}
            />
            <div className="caption audio-caption">
                <p>
                    <strong>{caption}</strong> / {time}
                </p>
            </div>
        </div>
    );
};

AudioContainer.propTypes = {
    audio: React.PropTypes.shape({
        src: React.PropTypes.string,
        duration: React.PropTypes.number,
    }).isRequired,
};

Audio.RelayComponent = Relay.createContainer(AudioContainer, {
    fragments: {
        audio: () =>
            Relay.QL`
                fragment on Audio {
                    id
                    name
                    src
                    duration
                    picture_full: picture(filter:"big"){
                        url
                    }
                    picture_mask: picture(filter:"big_blue"){
                        url
                    }
                    picture_mask_loading: picture(filter:"big_gray"){
                        url
                    }
                }
            `,
    },
});

export default AudioContainer;
