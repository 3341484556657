import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TweenMax, TimelineMax } from 'gsap';
import * as PIXI from 'pixi.js';
import createDebug from 'debug';
import classNames from 'classnames';

import FFTAudio from './pixi/fft';
import Saynete from './pixi/saynete';
import Decor from './pixi/decor';
import Scanner from './pixi/scanner';
import Viewer from './pixi/viewer';
import Loading from './pixi/loading';

const debug = createDebug('Scene');

const propTypes = {
    router: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    ready: PropTypes.bool.isRequired,
    zoomedIn: PropTypes.bool,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    mobile: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,

    scene: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    segments: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    rendererOptions: PropTypes.object, // eslint-disable-line react/forbid-prop-types

    margin: PropTypes.number,
    marginSmall: PropTypes.number,
    marginBottom: PropTypes.number,

    onTimelineReady: PropTypes.func,
    onOpenOverlay: PropTypes.func.isRequired,
    onOpenMenu: PropTypes.func, // eslint-disable-line react/forbid-prop-types
};

const defaultProps = {
    scene: null,
    segments: {},
    zoomedIn: false,
    rendererOptions: {
        antialias: true,
        roundPixels: false,
        transparent: true,
        autoResize: true,
        resolution: 1,
    },
    margin: 100,
    marginSmall: 40,
    marginBottom: 150,
    onTimelineReady: () => {},
    onOpenMenu: () => {},
};

class Scene extends PureComponent {
    constructor(props) {
        super(props);

        this.refCanvas = null;
        this.refCanvasEl = null;

        this.viewer = null;
        this.elements = [];
        this.saynetes = [];
        this.scanner = null;

        this.onTick = this.onTick.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onClearScene = this.onClearScene.bind(this);

        this.state = {
            renderer: null,
            stage: null,
            sceneContainer: null,
            viewerContainer: null,
            topContainer: null,
            decor: null,
            saynete: null,
            viewer: null,
            scanner: null,
            fftAudio: null,
            ...this.getStageSize(),
        };
    }

    componentDidMount() {
        this.createPIXI();
        TweenMax.ticker.addEventListener('tick', this.onTick);
    }

    componentDidUpdate(prevProps) {
        const { ready, width, height, mobile, onTimelineReady, zoomedIn, router, loading } =
            this.props;
        const { actionsContainer, viewerContainer, topContainer, scanner } = this.state;
        const readyChanged = prevProps.ready !== ready;
        const zoomChanged = prevProps.zoomedIn !== zoomedIn;
        const sizeChanged = prevProps.width !== width || prevProps.height !== height;
        const loadingChanged = prevProps.loading !== loading;

        if (sizeChanged || zoomChanged) {
            this.onSizeChange();
        }

        if (loadingChanged && this.state.loading) {
            // console.log('loadingChanged', loading);
            // if (loading) {
            //     this.state.loading.timeline.play();
            //     this.state.loading.sprite.alpha = 1;
            // } else {
            //     this.state.loading.timeline.pause();
            //     this.state.loading.sprite.alpha = 0;
            // }
        }

        // Start the new wave
        if (readyChanged && ready === true) {
            const { scene, onOpenOverlay, onOpenMenu, segments } = this.props;

            if (this.viewer !== null) {
                this.viewer.destroy();
            }

            const timeline = new TimelineMax();

            const sizeProps = this.getSizeProps();
            const viewer = new Viewer({ ...sizeProps, mobile, segments, onOpenOverlay });

            viewerContainer.addChild(viewer.container);
            actionsContainer.addChild(viewer.fullContainer);

            timeline.add(viewer.getTimeline(), 0);
            timeline.add(viewer.getActionsTimeline(), 0);

            this.viewer = viewer;

            // refresh
            this.saynetes.forEach((say) =>
                say.destroy({
                    children: true,
                }),
            );
            this.saynetes = [];

            if (scene) {
                if (scene.in) {
                    const saynete = new Saynete({ ...scene.in, width, height, mobile, router });
                    if (saynete.container) {
                        viewerContainer.addChild(saynete.container);
                    }
                    if (saynete.topContainer) {
                        topContainer.addChild(saynete.topContainer);
                    }
                    timeline.add(saynete.getTimeline(onOpenMenu, scene.in.options), 0);
                    this.saynetes.push(saynete);
                }
            }

            if (segments) {
                Object.keys(segments).forEach((time) => {
                    const segment = segments[time];
                    if (segment.scene) {
                        debug('cue saynete', scene);
                        const saynete = new Saynete({ ...segment.scene, width, height });
                        viewerContainer.addChild(saynete.container);
                        timeline.add(saynete.getTimeline(), segment.time);
                        this.saynetes.push(saynete);
                    }
                });
            }

            // For Scanner effect on scene change
            if (scanner !== null && scene && !scene.in) {
                timeline.add(scanner.getTimeline(), 0);
            }

            onTimelineReady(timeline);
        }
    }

    componentWillUnmount() {
        this.destroyPIXI();
        TweenMax.ticker.removeEventListener('tick', this.onTick);
    }

    onTick() {
        const { renderer, fftAudio, stage } = this.state;
        if (renderer) {
            renderer.render(stage);
        }

        // if (this.viewer) {
        //     this.viewer.onTick();
        // }

        if (fftAudio) {
            fftAudio.visualize();
        }
    }

    onStageReady() {
        const { mobile } = this.props;
        const { sceneContainer } = this.state;
        const sizeProps = this.getSizeProps();

        const fftAudio = new FFTAudio(window.innerWidth, window.innerHeight);
        sceneContainer.addChild(fftAudio.container);
        this.elements.push(fftAudio);

        let scanner = null;
        if (!mobile) {
            scanner = new Scanner(sizeProps);
            sceneContainer.addChild(scanner);
            this.elements.push(scanner);
            this.scanner = scanner;
        }

        const decor = new Decor(sizeProps);
        this.elements.push(decor);
        sceneContainer.addChild(decor.container);

        const loading = new Loading(sizeProps);
        this.elements.push(loading);
        sceneContainer.addChild(loading);

        this.setState(
            {
                decor,
                fftAudio,
                scanner,
                loading,
            },
            () => {
                this.onSizeChange();
            },
        );
    }

    // Takes two times
    onSizeChange() {
        const { width, height } = this.props;
        const { renderer } = this.state;
        renderer.resize(width, height);
        this.setState(
            {
                ...this.getStageSize(),
            },
            () => {
                this.onResize();
            },
        );
    }

    onResize() {
        const { margin, viewerContainer } = this.state;
        viewerContainer.x = margin;
        viewerContainer.y = margin;
        const sizeProps = this.getSizeProps();

        if (this.viewer) {
            this.viewer.resize(sizeProps);
        }

        this.elements.forEach((item) => {
            if (item.resize) {
                item.resize(sizeProps);
            }
        });

        this.saynetes.forEach((item) => {
            if (item.resize) {
                item.resize(sizeProps);
            }
        });
    }

    onClearScene(resolve) {
        const { stage, margin, viewerContainer } = this.state;

        if (stage !== null && viewerContainer !== null) {
            debug('DESTROY ALL');
            stage.removeChild(viewerContainer);
            viewerContainer.destroy({ children: true });

            const nextViewer = new PIXI.Container();
            nextViewer.x = margin;
            nextViewer.y = margin;
            stage.addChild(nextViewer);

            this.setState(
                {
                    viewerContainer: nextViewer,
                },
                resolve,
            );
        }
    }

    getStageSize() {
        const { width, height, zoomedIn, margin, marginSmall, marginBottom, mobile } = this.props;
        if (zoomedIn) {
            // prettier-ignore
            const gap = mobile ? 20 + 2 : 2 + (marginSmall * 2);
            return {
                innerWidth: width - gap,
                innerHeight: height - gap,
                margin: mobile ? 10 + 1 : marginSmall + 1,
                marginBottom: mobile ? 10 + 1 : marginSmall + 1,
            };
        }
        // prettier-ignore
        const gapHorizontal = mobile ? 40 + 2 : 2 + (margin * 2);
        // prettier-ignore
        const gapVertical = mobile ? 40 + 2 : 2 + margin + marginBottom;
        return {
            innerWidth: width - gapHorizontal,
            innerHeight: height - gapVertical,
            margin: mobile ? 20 + 1 : margin + 1,
            marginBottom: mobile ? 20 + 1 : marginBottom + 1,
        };
    }

    getSizeProps() {
        const { width, height } = this.props;
        const { margin, marginBottom, innerWidth, innerHeight } = this.state;
        return { width, height, margin, marginBottom, innerWidth, innerHeight };
    }

    createPIXI() {
        const { width, height } = this.props;
        const { margin } = this.state;
        const renderer = PIXI.autoDetectRenderer({
            width,
            height,
            view: this.refCanvasEl,
            ...this.props.rendererOptions,
        });

        const stage = new PIXI.Container();

        const fftContainer = new PIXI.Container();
        fftContainer.x = 0;
        fftContainer.y = 0;

        const sceneContainer = new PIXI.Container();
        sceneContainer.x = 0;
        sceneContainer.y = 0;

        const actionsContainer = new PIXI.Container();
        actionsContainer.x = 0;
        actionsContainer.y = 0;

        const viewerContainer = new PIXI.Container();
        viewerContainer.x = margin;
        viewerContainer.y = margin;

        const topContainer = new PIXI.Container();
        topContainer.x = margin;
        topContainer.y = margin;

        stage.addChild(sceneContainer);
        stage.addChild(actionsContainer);
        stage.addChild(viewerContainer);
        stage.addChild(topContainer);
        stage.addChild(fftContainer);

        this.setState(
            {
                renderer,
                stage,
                sceneContainer,
                viewerContainer,
                actionsContainer,
                topContainer,
                fftContainer,
            },
            this.onStageReady,
        );
    }

    destroyPIXI() {
        const { stage, renderer } = this.state;

        if (renderer !== null) {
            renderer.destroy();
        }

        if (stage !== null) {
            stage.destroy({
                children: true,
            });
        }

        this.setState({
            renderer: null,
            stage: null,
        });
    }

    render() {
        const { loading } = this.props;
        const canvasClassName = classNames({
            'canvas-container': true,
        });

        const loadingClassName = classNames({
            'canvas-loading': true,
            hidden: !loading,
        });

        return (
            <div
                ref={(ref) => {
                    this.refCanvas = ref;
                }}
                className={canvasClassName}
                role="button"
            >
                <canvas
                    ref={(ref) => {
                        this.refCanvasEl = ref;
                    }}
                />
                <div className={loadingClassName}>
                    <span>Chargement</span>
                </div>
            </div>
        );
    }
}

Scene.propTypes = propTypes;
Scene.defaultProps = defaultProps;

export default Scene;
