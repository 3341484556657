import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TimelineMax } from 'gsap';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import Link from '../../components/partials/Link';

const propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    mobile: PropTypes.bool.isRequired,
    label: PropTypes.string,
    finished: PropTypes.array.isRequired,
    segmentSlug: PropTypes.string,
    sectionSlug: PropTypes.string,
    hidden: PropTypes.bool,
    main: PropTypes.array.isRequired,
    group: PropTypes.shape({
        track: PropTypes.string,
        items: PropTypes.array,
    }),
    onToggleMenu: PropTypes.func.isRequired,
};

const defaultProps = {
    mobile: true,
    label: "L'histoire du NTE",
    segmentSlug: null,
    sectionSlug: null,
    hidden: false,
    group: {
        items: [],
    },
    main: [
        { label: "L'histoire du NTE", link: '/voyages/nte/0' },
        { label: 'Matériau Histoire', link: '/voyages/histoire/0' },
        { label: 'Réflexions sur le théâtre', link: '/voyages/reflexions/0' },
        { label: 'Rencontres', link: '/voyages/rencontres/0' },
    ],
};

function createMarkup(html) {
    return { __html: html };
}

class NavMenu extends PureComponent {
    constructor(props) {
        super(props);

        this.onToggleBack = this.onToggleBack.bind(this);
        this.renderMenuItem = this.renderMenuItem.bind(this);

        this.timeline = null;
        this.shadow = null;
        this.menu = null;

        this.state = {
            title: props.label,
            items: props.group.items,
            back: false,
            showArrow: true,
        };
    }

    componentDidMount() {
        this.onItemsChanged();
        this.refreshTimeline();

        if (this.props.sectionSlug === 'menu') {
            this.toggleHidden();
        }
    }

    componentDidUpdate(prevProps) {
        const sizeChanged =
            prevProps.width !== this.props.width || prevProps.height !== this.props.height;

        // const backChanged = prevState.back !== this.state.back;
        const hiddenChanged = prevProps.hidden !== this.props.hidden;
        const itemsChanged = !isEqual(prevProps.group.items, this.props.group.items);
        const labelChanged = prevProps.label !== this.props.label;

        if (sizeChanged) {
            // this.refreshTimeline();
        }

        if (hiddenChanged) {
            this.toggleHidden();
        }

        if (labelChanged) {
            this.setState({
                title: this.props.label,
            });
        }

        // if (backChanged) {
        // this.onBackChanged();
        // }

        if (itemsChanged) {
            this.onItemsChanged();
        }
    }

    onToggleBack() {
        const { back } = this.state;
        this.setState({
            back: !back,
        });
    }

    onItemsChanged() {
        if (this.props.group && !this.props.group.items) {
            this.setState({
                back: true,
            });
        }
    }

    toggleHidden() {
        const { hidden } = this.props;
        const { title } = this.state;
        if (!title) return;
        if (hidden === false) {
            this.setState(
                {
                    back:
                        (this.props.group && !this.props.group.items) ||
                        this.props.group.items.length === 0,
                },
                () => {
                    this.timeline.play(0);
                },
            );
        } else {
            this.timeline.reverse(0);
        }
    }

    refreshTimeline(time = 1) {
        const { title } = this.state;
        if (!title) return;

        this.timeline = new TimelineMax({
            paused: true,
        });

        this.timeline.fromTo(
            this.menu,
            time,
            {
                autoAlpha: 0,
            },
            {
                autoAlpha: 1,
            },
            0,
        );

        this.timeline.fromTo(
            this.shadow,
            time,
            {
                opacity: 0,
            },
            {
                opacity: 1,
            },
            0,
        );
    }

    renderMenuItem(item, idx) {
        const { segmentSlug, sectionSlug, finished, onToggleMenu, mobile } = this.props;
        const { back } = this.state;
        const itemLabel = item.label;
        const key = `item-${itemLabel}-${idx}`;
        const active = !back ? parseInt(segmentSlug, 10) === idx : false;

        const linkClassName = classNames({
            'scene-menu-link': true,
            active,
            desktop: !mobile,
            mobile,
            finished: finished.indexOf(`${sectionSlug}-${idx}`) > -1,
        });

        const link = item.link ? item.link : `/voyages/${sectionSlug}/${idx}`;
        const content = (
            <div className={'scene-menu-item'}>
                {itemLabel ? (
                    <p>
                        <span
                            className="foreground"
                            dangerouslySetInnerHTML={createMarkup(itemLabel)}
                        />
                    </p>
                ) : (
                    <span>{`Segment #${idx + 1}`}</span>
                )}
            </div>
        );

        return active ? (
            <button key={key} className={linkClassName} onClick={onToggleMenu}>
                {content}
            </button>
        ) : (
            <Link key={key} to={link} className={linkClassName}>
                {content}
            </Link>
        );
    }

    render() {
        const { sectionSlug, onToggleMenu, main, group } = this.props;
        const { title, back } = this.state;
        const currentItems = back || !group.items ? main : group.items || [];
        const showArrow = group.items && group.items.length > 0;

        return (
            <div>
                <div
                    className="scene-menu-shadow"
                    ref={(ref) => {
                        this.shadow = ref;
                    }}
                />
                <div
                    className="nav-menu"
                    ref={(ref) => {
                        this.menu = ref;
                    }}
                >
                    <div className="inner-table">
                        <div className="inner-controls">
                            {showArrow ? (
                                <button
                                    className={back ? 'back reverse' : 'back'}
                                    onClick={this.onToggleBack}
                                />
                            ) : null}
                            {!back ? <button className="close" onClick={onToggleMenu} /> : null}
                        </div>
                        {currentItems && currentItems.length > 0 ? (
                            <div className="inner-scene-menu">
                                <div className="title">
                                    <h2>
                                        {back ? (
                                            <span>Voyages</span>
                                        ) : (
                                            <Link to={`/voyages/${sectionSlug}/0`}>{title}</Link>
                                        )}
                                    </h2>
                                </div>
                                <div className="scene-menu-items">
                                    {currentItems.map(this.renderMenuItem)}
                                    {back || !group.items ? (
                                        <a href="/" className="scene-menu-link">
                                            <div className="scene-menu-item">
                                                <p>
                                                    <span className="foreground small">
                                                        {"Retour à l'accueil"}
                                                    </span>
                                                </p>
                                            </div>
                                        </a>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

NavMenu.propTypes = propTypes;
NavMenu.defaultProps = defaultProps;

export default NavMenu;
