import React from 'react';
import { connect } from 'react-redux';
import WithSidebar from '../../components/layouts/WithSidebar';

const propTypes = {
    dispatch: React.PropTypes.func.isRequired,
    layout: React.PropTypes.shape({
        get: React.PropTypes.func,
    }).isRequired,
};

const WithSidebarContainer = (props) => {
    const { layout, ...otherProps } = props;
    const layoutProps = layout.toJS();
    return <WithSidebar {...otherProps} {...layoutProps} />;
};

WithSidebarContainer.propTypes = propTypes;

export default connect((state) => ({
    layout: state.layout,
}))(WithSidebarContainer);
