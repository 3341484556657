import React, { Component } from 'react';
import Relay from 'react-relay';
import find from 'lodash/find';
import each from 'lodash/each';
import { withRouter } from 'react-router';
import { parse } from 'query-string';
import FilterComponents from './filters/index';

const propTypes = {
    filters: React.PropTypes.arrayOf(
        React.PropTypes.shape({
            name: React.PropTypes.string,
        }),
    ).isRequired,
    values: React.PropTypes.shape({}),
    relay: React.PropTypes.shape({}),
    clearFilter: React.PropTypes.func.isRequired,
    updateFilter: React.PropTypes.func.isRequired,
    location: React.PropTypes.object.isRequired, // eslint-disable-line
};

const defaultProps = {
    relay: null,
    values: {},
};

class Filters extends Component {
    componentDidMount() {
        const { location = null, filters = null } = this.props;
        const { search = null } = location || {};

        const params = parse(search || '', { arrayFormat: 'bracket' });
        (Object.keys(params) || [])
            .filter((key) => key !== null && filters.find((f) => f.name === key))
            .forEach((key) => {
                if (params[key]) {
                    this.props.updateFilter(key, params[key]);
                }
            }, {});
    }

    onFilterChange(filter, value) {
        if (value === null) {
            this.props.clearFilter(filter.name);
        } else {
            this.props.updateFilter(filter.name, value);
        }
    }

    renderFilters(filters) {
        return filters.map((filter, i) => {
            const key = `filter-col-${filter.name}`;
            return (
                <div className="filters-section" key={key}>
                    {this.renderFilter(filter, i)}
                </div>
            );
        });
    }

    renderFilter(filter) {
        let FilterComponent = find(
            FilterComponents,
            (entry, key) => key.toLowerCase() === filter.type.toLowerCase(),
        );
        FilterComponent = this.props.relay ? FilterComponent.RelayComponent : FilterComponent;

        if (!FilterComponent) {
            return null;
        }

        const value = this.props.values[filter.name] || null;

        const onChange = (val) => this.onFilterChange(filter, val);

        return <FilterComponent value={value} filter={filter} onChange={onChange} />;
    }

    render() {
        return <div className="filters">{this.renderFilters(this.props.filters || [])}</div>;
    }
}

Filters.propTypes = propTypes;
Filters.defaultProps = defaultProps;

const filtersWithRouter = withRouter(Filters);

// Get fragments from filter compoments
const filtersFragments = [];
each(FilterComponents, (FilterComponent) => {
    if (FilterComponent.RelayComponent) {
        filtersFragments.push(FilterComponent.RelayComponent.getFragment('filter'));
    }
});

filtersWithRouter.RelayComponent = Relay.createContainer(filtersWithRouter, {
    fragments: {
        filters: () => Relay.QL`
            fragment on Filter @relay(plural: true) {
                label
                name
                type
                ${filtersFragments}
            }
        `,
    },
});

export default filtersWithRouter;
