import React, { PureComponent } from 'react';
import classNames from 'classnames';
// import { TweenMax } from 'gsap';

const propTypes = {
    itemSize: React.PropTypes.string.isRequired,
    view: React.PropTypes.string.isRequired,
};

class LoadingItem extends PureComponent {
    componentDidMount() {
        // TweenMax.fromTo(this.refWrap, 2, {
        //     // backgroundSize: '100px',
        //     alpha: 0.5,
        // }, {
        //     // backgroundSize: '10px',
        //     // loop: true,
        //     repeat: -1,
        //     alpha: 0.2,
        //     yoyo: true,
        // });
    }

    componentWillUnmount() {
        // TweenMax.killTweensOf(this.refWrap);
    }

    render() {
        const itemClassNames = classNames({
            'list-item': true,
            'list-item-grid': this.props.view === 'grid',
            'list-item-vertical': this.props.view === 'list',
            'list-item-loading': true,
            'list-item-small': this.props.itemSize === 'small',
        });

        return (
            <div className={itemClassNames}>
                <div
                    className="wrap"
                    ref={(ref) => {
                        this.refWrap = ref;
                    }}
                >
                    {this.props.view === 'grid' ? (
                        <div className="loading-grid-fill" />
                    ) : (
                        <div className="container-picture" />
                    )}
                </div>
            </div>
        );
    }
}

LoadingItem.propTypes = propTypes;

export default LoadingItem;
