/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from 'react';
import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';

const propTypes = {
    popup: React.PropTypes.object, // eslint-disable-line react/forbid-prop-types
    pathname: React.PropTypes.string.isRequired,
};

const defaultProps = {
    popup: null,
};

class Popup extends Component {
    constructor(props) {
        super(props);
        this.onClickClose = this.onClickClose.bind(this);
        this.selectVisibility = this.selectVisibility.bind(this);

        this.state = {
            shouldShowPopup: parseInt(Cookies.get('showPopup') || 0, 10) < 3,
            showPopup: false,
            shownPopup: false,
        };
    }

    componentDidMount() {
        const { popup } = this.props;
        if (popup !== null) {
            this.selectVisibility(this.props);
        }
    }

    componentWillReceiveProps(nextProps) {
        // console.log('got props baby', this.props, nextProps);
        const { popup, pathname } = this.props;
        if (popup !== null && nextProps.pathname !== pathname) {
            this.selectVisibility(nextProps);
        }
    }

    onClickClose(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setCookie();
        this.setState({
            showPopup: false,
            shownPopup: true,
        });
    }

    // eslint-disable-next-line class-methods-use-this
    setCookie() {
        const previous = parseInt(Cookies.get('showPopup'), 10) || 0;
        Cookies.set('showPopup', previous + 1, { expires: 7 });
    }

    selectVisibility(currentProps) {
        const { showPopup = false } = this.state;
        const { popup = null, pathname = '' } = currentProps || {};
        const { fields = null } = popup || {};
        const { filter = null } = fields || {};

        if (popup !== null && !showPopup) {
            if (pathname !== null && !isEmpty(pathname) && filter !== null && !isEmpty(filter)) {
                const parts = pathname
                    .split('/')
                    .filter((i) => !isEmpty(i))
                    .map((i) => i.replace(',', '').trim())
                    .map((i) => new RegExp(i));
                const text = filter;

                if (parts.length === 3 && parts.some((rx) => rx.test(text))) {
                    this.setState({ showPopup: true });
                } else if (parts.length === 2 && parts.some((rx) => rx.test(text))) {
                    this.setState({ showPopup: true });
                }
            } else {
                // Default if empty
                this.setState({ showPopup: true });
            }
        }
    }

    render() {
        const { showPopup, shouldShowPopup, shownPopup } = this.state;
        const { popup } = this.props;
        const { fields = null } = popup || {};
        const { title = null, description = null, url = null, picture = null } = fields || {};
        const { fr: titleText = null } = title || {};
        const { fr: descriptionText = null } = description || {};
        const { fr: link = null } = url || {};
        const { link: pictureLink = null, name: pictureName = null } = picture || {};

        return popup !== null && showPopup && shouldShowPopup && !shownPopup ? (
            <div className="popup">
                <div className="inner">
                    <button type="button" className="close" onClick={this.onClickClose} />
                    <div className="wrapper">
                        {pictureLink !== null ? (
                            <a
                                className="link"
                                href={link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    className="left"
                                    src={pictureLink}
                                    alt={pictureName || 'popup'}
                                />
                            </a>
                        ) : null}
                        <div className="right">
                            <a
                                className="link"
                                href={link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <h1 className="title">{titleText}</h1>
                                <div
                                    className="description"
                                    dangerouslySetInnerHTML={{ __html: descriptionText }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <button type="button" className="background" onClick={this.onClickClose} />
            </div>
        ) : null;
    }
}

Popup.propTypes = propTypes;
Popup.defaultProps = defaultProps;

export default Popup;
