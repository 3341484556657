import _ from 'lodash';
import { Howl } from 'howler';

const Audio = function (url, opts) {
    this.unlocked = false;
    this.url = url;

    this.options = _.extend(
        {
            src: [url],
            loop: false,
            useAudioContext: true,
            volume: 0.2,
            autoplay: false,
            html5: true,
        },
        opts,
    );

    this.howl = new Howl(this.options);

    return this;
};

Audio.prototype.play = function () {
    if (!this.howl) return;
    this.howl.play();
};

Audio.prototype.pause = function () {
    if (!this.howl) return;
    this.howl.pause();
};

Audio.prototype.stop = function () {
    if (!this.howl) return;
    this.howl.stop();
};

Audio.prototype.mute = function (muted) {
    if (!this.howl) return;
    this.howl.mute(muted);
};

Audio.prototype.seek = function (time = null) {
    if (!this.howl) return null;
    if (time === null) {
        return this.howl.seek();
    }
    return this.howl.seek(time);
};

Audio.prototype.fade = function (startVolume = 1, endVolume = 0, time = 2) {
    if (!this.howl) return;
    this.howl.fade(startVolume, endVolume, time);
};

Audio.prototype.destroy = function () {
    if (!this.howl) return;
    this.howl.unload();
    this.howl = null;
};

export default Audio;
