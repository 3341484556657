import React from 'react';
import { RootContainer } from 'react-relay';
import { connect } from 'react-redux';
import { updateFilter } from '../../actions/FiltersActions';

import CategoriesComponent from '../../components/sections/Categories';

const propTypes = {
    dispatch: React.PropTypes.func.isRequired,
    categories: React.PropTypes.array.isRequired,
    section: React.PropTypes.string.isRequired,
};

const CategoriesContainer = (props) => {
    const { dispatch, categories, section } = props;
    return (
        <CategoriesComponent
            section={section}
            categories={categories}
            updateFilter={(name, value) => dispatch(updateFilter(name, value))}
        />
    );
};

CategoriesContainer.propTypes = propTypes;

export default connect(state => ({
    filters: state.filters,
}))(CategoriesContainer);
