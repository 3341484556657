import React from 'react';
import Relay from 'react-relay';

import Title from '../partials/Title';
import Meta from '../partials/Meta';
import Footer from '../../containers/partials/Footer';
import CreditsHtml from '../partials/CreditsHtml';

import Video from '../Video';

import DescriptionSection from '../sections/Description';
import CreditsTextSection from '../sections/CreditsText';
import BubblesListSection from '../sections/BubblesList';

const propTypes = {
    bubble: React.PropTypes.shape({
        bubble_id: React.PropTypes.string,
        snippet: React.PropTypes.shape({
            link: React.PropTypes.string,
            title: React.PropTypes.string,
            subtitle: React.PropTypes.string,
        }),
        credits_technicians: React.PropTypes.string,
        credits_other: React.PropTypes.string,
        videos: React.PropTypes.array,
        description: React.PropTypes.string,
    }).isRequired,
    newsletter: React.PropTypes.object,
};

const BubbleVideoPage = (props) => {
    const id = props.bubble.bubble_id;
    const { title, subtitle } = props.bubble.snippet;
    const { description, videos } = props.bubble;
    const creditsTechnicians = props.bubble.credits_technicians;
    const creditsOther = props.bubble.credits_other;
    const videoItems = videos || [];
    const noCaptions = !creditsOther;
    const { newsletter = null } = props || {};

    return (
        <article className="container-page container-video">
            <Meta bubble={props.bubble} section="Vidéo" />

            {title ? (
                <div className="container">
                    <Title text={title} newsletter={newsletter} />
                </div>
            ) : null}

            {videoItems.length > 0 ? (
                <section
                    className={`page-section page-section-video ${noCaptions ? 'no-captions' : ''}`}
                >
                    {videoItems.map((video, index) => (
                        <div key={`videoplayer-${video.id ? video.id : null}-${index + 1}`}>
                            <Video video={video} />
                        </div>
                    ))}
                </section>
            ) : null}

            <CreditsHtml text={creditsOther} />

            <div className="container">
                <DescriptionSection title={subtitle} description={description} />

                <CreditsTextSection title="Techniciens" credits={creditsTechnicians} />

                <BubblesListSection name={`shows:${id}`} title="Productions" />

                <BubblesListSection name={`videos:${id}`} title="Video" sectionSize="small" />

                <Footer />
            </div>
        </article>
    );
};

BubbleVideoPage.propTypes = propTypes;

BubbleVideoPage.RelayComponent = Relay.createContainer(BubbleVideoPage, {
    fragments: {
        bubble: () =>
            Relay.QL`
                fragment on BubbleVideo {
                    bubble_id
                    snippet {
                        title
                        subtitle
                        link
                        picture_facebook: picture(filter:"facebook") {
                            url
                        }
                    }
                    description
                    credits_technicians
                    credits_other
                    videos {
                        id
                        link
                        width
                        height
                        duration
                    }
                }
            `,
    },
});

export default BubbleVideoPage;
