import Immutable from 'immutable';
import {
    SECTIONS_SET,
} from '../actions/ActionTypes';

const initialState = [];

const SectionsReducer = (previousState, action) => {
    const state = previousState || new Immutable.List(initialState);

    switch (action.type) {
    case SECTIONS_SET:
        return new Immutable.List(action.payload);
    default:
        return state;
    }
};

export default SectionsReducer;
