import { Howl } from 'howler';

const defaultProps = {
    playing: false,
    volume: 0.4,
    loop: false,
    autoPlay: false,
    mute: false,
    onTick: null,
};

class Player {
    constructor(props) {
        this.onTick = defaultProps.onTick;

        this.sound = null;

        this.play = this.play.bind(this);
        this.stop = this.stop.bind(this);
        this.pause = this.pause.bind(this);
        this.seek = this.seek.bind(this);
        this.rate = this.rate.bind(this);
        this.tick = this.tick.bind(this);

        this.destroy = this.destroy.bind(this);

        this.sound = new Howl({
            ...defaultProps,
            ...props,
            onplayerror: () => {
                this.sound.once('unlock', () => {
                    this.sound.play();
                });
            },
            onend: () => {
                this.sound.stop();
            },
        });
    }

    seek(seconds) {
        this.sound.seek(seconds);
        if (!this.sound.playing()) {
            this.play();
        }
    }

    play(seconds = null) {
        if (this.sound === null) return;
        if (seconds !== null) {
            this.seek(Math.floor(seconds));
        } else if (!this.sound.playing()) {
            this.sound.play();
        }
    }

    stop() {
        if (this.sound === null) return;
        this.sound.stop();
    }

    pause() {
        if (this.sound === null) return;
        this.sound.pause();
    }

    rate(rate = 1) {
        if (this.sound === null) return;
        this.sound.rate(rate);
    }

    tick() {
        if (this.sound === null) return;
        if (this.onTick !== null && this.sound.playing()) {
            this.onTick(this.sound.seek()); // For the sound wave animation
        }
    }

    destroy() {
        if (this.sound === null) return;
        this.sound.unload();
        this.sound = null;
        Object.keys(this.tracks).forEach((trackId) => {
            this.tracks[trackId].unload();
            this.tracks[trackId] = null;
        });
    }
}

export default Player;
