import React, { Component } from 'react';
import { TweenMax, TimelineLite, Sine } from 'gsap';
import { getWidth } from '../lib/getSize';

const propTypes = {
    onReady: React.PropTypes.func,
    paused: React.PropTypes.bool,
};

const defaultProps = {
    onReady: null,
    paused: false,
};

class CssScanner extends Component {
    constructor(props) {
        super(props);
        this.timeline = null;
        this.pageTimeline = null;
        this.container = null;
    }

    componentDidMount() {
        this.createLoadTimeline();
        this.createPageTimeline();
        TweenMax.set(this.container, { x: getWidth() - 100, alpha: 0 });
    }

    getUncoverTimeline() {
        const timeline = new TimelineLite();

        if (this.container) {
            timeline.set(this.container, { x: getWidth() - 100, alpha: 0 });

            timeline.to(this.container, 2.8, {
                x: -90,
                alpha: 0.7,
                ease: Sine.easeOut,
            });

            timeline.to(this.container, 1.6, {
                alpha: 0.3,
                ease: Sine.easeOut,
            });
        }

        return timeline;
    }

    createLoadTimeline() {
        const { onReady } = this.props;

        this.timeline = new TimelineLite({
            onComplete: () => {
                if (onReady !== null) {
                    onReady();
                }
            },
        });

        this.timeline.set(this.container, { x: window.innerWidth - 100, alpha: 0 });

        return this.timeline;
    }

    createPageTimeline() {
        this.pageTimeline = new TimelineLite({
            paused: this.props.paused,
        });

        this.pageTimeline.fromTo(
            this.container,
            2.1,
            {
                alpha: 1,
                x: 0,
            },
            {
                alpha: 0.3,
                x: -90,
            },
        );

        return this.pageTimeline;
    }

    render() {
        return (
            <div
                className="scanner-container"
                ref={(el) => {
                    this.container = el;
                }}
            >
                <div className="scan-inner" />
            </div>
        );
    }
}

CssScanner.propTypes = propTypes;
CssScanner.defaultProps = defaultProps;

export default CssScanner;
