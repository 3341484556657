import Default from './Default';
import InvertLeft from './InvertLeft';
import InvertRight from './InvertRight';
import Single from './Single';

export default {
    Default,
    InvertLeft,
    InvertRight,
    Single,
};
