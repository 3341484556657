import React, { Component } from 'react';
import GridItem from '../items/Grid';

const propTypes = {
    items: React.PropTypes.array.isRequired,
    onPreviewChange: React.PropTypes.func.isRequired,
};

const defaultProps = {
};

class DefaultGrid extends Component {

    renderItem(item, size) {
        return (
            <GridItem item={item} size={size} key={`square-grid-item-${item.id}`} onPreviewChange={this.props.onPreviewChange} />
        );
    }

    render() {
        const firstRow = [];
        const secondRow = [];
        this.props.items.forEach((item, index) => {
            if (index < 2) {
                firstRow.push(this.renderItem(item, 'large'));
            } else if (index < 6) {
                secondRow.push(this.renderItem(item, 'small'));
            }
        });
        return (
            <div className="grid-menu-container">
                <div className="grid-menu-row">
                    { firstRow }
                    <div className="clear" />
                </div>
                { secondRow.length > 0 ? (
                    <div className="grid-menu-row">
                        { secondRow }
                        <div className="clear" />
                    </div>
                ) : null }
            </div>
        );
    }
}

DefaultGrid.propTypes = propTypes;
DefaultGrid.defaultProps = defaultProps;

export default DefaultGrid;
