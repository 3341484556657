import _ from 'lodash';
import React from 'react';
import UListItem from '../partials/UListItem';

const propTypes = {
    title: React.PropTypes.string,
    items: React.PropTypes.array,
    listName: React.PropTypes.string,
};

const defaultProps = {
    title: null,
    items: [],
    listName: '',
};

const UList = (props) => {
    const { title, items, listName } = props;
    const listItems = items.map((item, key) => (
        <UListItem text={item.name} url={item.link} key={`${listName}-ulist-item-${item.name}-${key}`} />
    ));
    return (
        <ul className="ulist">
            { title ? (
                <p>{title}</p>
            ) : null }
            { listItems }
        </ul>
    );
};

UList.propTypes = propTypes;
UList.defaultProps = defaultProps;

export default UList;
