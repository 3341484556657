/* global PIXI */
import { TimelineLite, Sine } from 'gsap';
import Chance from 'chance';
import filters from './filters';

const chance = new Chance();

class Scanner extends PIXI.Container {
    constructor(props) {
        super(props);

        this.glow = null;
        this.pixelFilter = null;

        this.width = props.width;
        this.height = props.height;

        this.glowTimeline = null;
        this.pageTimeline = null;

        this.play = this.play.bind(this);
        this.resize = this.resize.bind(this);

        this.build(props.width, props.height);
    }

    resize({ width, height }) {
        if (this.pageTimeline !== null) {
            this.pageTimeline.kill();
        }
        this.width = width;
        this.height = height;
        this.destroy();
        this.build(width, height);
    }

    play() {
        if (this.pageTimeline !== null) {
            this.pageTimeline.restart();
        }
    }

    build(width, height) {
        this.glow = Scanner.createRedGlow(width, height);
        this.glow.alpha = 0;
        this.addChild(this.glow);

        this.pixelFilter = new filters.AsciiFilter();
        this.pixelFilter.size = 80;

        this.glow.filters = [this.pixelFilter];
    }

    getTimeline() {
        const glow = this.glow;
        const pageTimeline = new TimelineLite({
            onComplete: () => {
                if (this.pixelFilter !== null) {
                    this.pixelFilter.size = chance.integer({ min: 40, max: 60 });
                }
            },
        });
        const distance = -(this.width / 20);

        pageTimeline.to(
            glow,
            0,
            {
                x: 0,
            },
            0,
        );

        pageTimeline.to(
            glow,
            0.5,
            {
                alpha: 1,
            },
            0,
        );

        pageTimeline.to(
            glow,
            0.16,
            {
                // prettier-ignore
                x: this.width + distance + chance.integer({ min: -6, max: 0 }),
                ease: Sine.easeIn,
            },
            0,
        );

        pageTimeline.to(
            glow,
            0.16,
            {
                x: 0,
                ease: Sine.easeOut,
            },
            0.4,
        );

        pageTimeline.to(
            this.pixelFilter,
            0.5,
            {
                // size: chance.integer({ min: 5, max: 30 }),
                size: chance.integer({ min: 4, max: 6 }),
            },
            0.4,
        );

        pageTimeline.to(
            glow,
            0.5,
            {
                alpha: chance.floating({ min: 0, max: 0.2, fixed: 1 }),
            },
            0.6,
        );

        return pageTimeline;
    }

    destroy() {
        if (this.glow !== null) {
            this.glow.destroy({ children: true });
        }
    }

    static createRedGlow(width, height) {
        const container = new PIXI.Container();
        container.x = 0;
        container.y = 0;
        // container.width = width;
        // container.height = height + 60;

        const glow = PIXI.Sprite.fromImage('/img/scanner/glow.png');
        glow.x = -(width * 0.04);
        glow.y = 0;

        glow.width = width;
        glow.height = height;

        container.addChild(glow);

        return container;
    }
}

export default Scanner;
