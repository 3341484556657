import React, { Component } from 'react';
import { RootContainer } from 'react-relay';

import BubbleComponent from '../../components/pages/Bubble';
import BubbleRoute from '../../routes/Bubble';

const propTypes = {};

class HomePageContainer extends Component {
    constructor(props) {
        super(props);
        const url = '/';
        this.state = {
            url,
        };
    }

    render() {
        return (
            <RootContainer
                Component={BubbleComponent.RelayComponent}
                route={
                    new BubbleRoute({
                        url: this.state.url,
                    })
                }
            />
        );
    }
}

HomePageContainer.propTypes = propTypes;

export default HomePageContainer;
