import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
    loading: PropTypes.number,
    playing: PropTypes.bool.isRequired,
    hidden: PropTypes.bool.isRequired,
    centered: PropTypes.bool.isRequired,
    className: PropTypes.string,
    onPlay: PropTypes.func.isRequired,
    onPause: PropTypes.func.isRequired,
};

const defaultProps = {
    loading: 0,
    className: '',
};

// eslint-disable-next-line
class Play extends PureComponent {
    render() {
        const { playing, className, onPlay, onPause, loading, hidden, centered } = this.props;

        const playClassName = classNames({
            'icon-progress': true,
            active: !playing, //  && loading === 100,
            disabled: loading < 100,
            [className]: className !== '',
            hidden: hidden === true,
            centered: centered === true,
        });

        return (
            <div className="play">
                <button
                    className={playClassName}
                    onClick={(e) => {
                        if (playing) {
                            onPause(e);
                        } else {
                            onPlay(e);
                        }
                    }}
                />
            </div>
        );
    }
}

Play.propTypes = propTypes;
Play.defaultProps = defaultProps;

export default Play;
