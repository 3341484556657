import { TimelineLite, TweenMax } from 'gsap';
import * as PIXI from 'pixi.js';
import merge from 'lodash/merge';
import { getSizeWithinMax } from '../../utils/size';
import getCvData from '../../utils/getCvData';
import filters from '../filters/index';

// You always have a renderer and container for your shit

class GifComponent {
    constructor(options = { scene: null, width: 0, height: 0 }) {
        this.setOptions(options);

        this.image = {};
        this.sprite = null;
        this.border = null;
        this.coords = null;

        this.timeline = null;
        this.graphics = [];
        this.label = {};
        this.labels = [];
        this.labelBackgrounds = [];

        this.container = new PIXI.Container();
        this.topContainer = new PIXI.Container();
        this.textContainer = new PIXI.Container();
        this.textBackgroundContainer = new PIXI.Container();

        this.containers = [
            this.container,
            this.topContainer,
            this.textContainer,
            this.textBackgroundContainer,
        ];

        this.dynamicTargets = [];
        this.dynamicGraphics = [];
        this.dynamicLabels = [];

        this.init = this.init.bind(this);
        this.create = this.create.bind(this);
        this.resize = this.resize.bind(this);

        return this;
    }

    setOptions(options = {}) {
        const targets = PIXI.loader.resources[getCvData(options.src)];

        if (targets) {
            options.targets = merge(targets.data, options.targets); // eslint-disable-line
        }

        const opts = merge(
            {
                toggleOverlay: () => {},
                time: 1,
                transitionTime: 1.4,
                wait: 2,
                texture: {
                    src: '/img/trips/portraits/mao2.jpg',
                },
                label: null,
                targets: [],
                labels: [],
                textStyle: {
                    fontFamily: 'Roboto',
                    fontSize: 18,
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fill: '#000000',
                    wordWrap: true,
                    wordWrapWidth: 440,
                },
            },
            options,
        );

        this.options = opts;
    }

    init() {
        this.create();
    }

    create() {
        if (this.sprite !== null) {
            this.sprite.destroy();
        }
        const frameData = PIXI.loader.resources[this.options.src].data.frames;

        // create an array of textures from an image path
        this.frames = [];
        Object.keys(frameData).forEach((name) => {
            this.frames.push(PIXI.Texture.fromFrame(name));
        });

        this.sprite = new PIXI.extras.AnimatedSprite(this.frames);
        this.sprite.anchor.set(0, 0);
        this.sprite.animationSpeed = 0.1;
        this.sprite.gotoAndPlay(0);

        this.coords = this.getCoords();

        this.pixelFilter = new filters.AsciiFilter(20);
        this.pixelFilter.size = 20;

        this.sprite.width = this.coords.width;
        this.sprite.height = this.coords.height;

        TweenMax.set(this.sprite, {
            alpha: 0,
            x: (this.options.width - this.sprite.width) / 2,
            y: (this.options.height - this.sprite.height) / 2,
        });

        // Add it
        this.container.addChild(this.sprite);
    }

    createLabel() {
        const height = this.options.height;
        const label = this.options.label;

        const style = new PIXI.TextStyle(this.options.descriptionStyle);
        const text = new PIXI.Text(label || '', style);
        text.x = 10;
        text.y = height - 25; // height - 20;
        text.alpha = 0;

        this.container.addChild(text);
        this.label = text;
    }

    resize() {
        if (this.sprite !== null && this.container !== null) {
            this.coords = this.getCoords();

            this.sprite.width = this.coords.width;
            this.sprite.height = this.coords.height;

            this.image.width = this.coords.width;
            this.image.height = this.coords.height;

            TweenMax.set([this.sprite, this.image], {
                x: (this.options.width - this.sprite.width) / 2,
                y: (this.options.height - this.sprite.height) / 2,
            });
        }

        if (this.graphics.length > 0) {
            for (let i = 0; i < this.graphics.length; i += 1) {
                for (let j = 0; j < this.graphics[i]; j += 1) {
                    this.graphics[i].destroy();
                }
            }
        }

        if (this.labels.length > 0) {
            for (let i = 0; i < this.labels.length; i += 1) {
                this.labels[i] = null;
            }
        }

        this.createRectangles(this.options.targets);
    }

    getTimeline(start = 0, onComplete = null) {
        let time = start;

        this.containers.forEach((container) => {
            container.visible = false; // eslint-disable-line
        });

        const timeline = new TimelineLite({
            onComplete: () => {
                this.containers.forEach((container) => {
                    container.visible = false; // eslint-disable-line
                });
                if (onComplete !== null) {
                    onComplete();
                }
            },
        });
        timeline.set(this.containers, { visible: true });
        timeline.set(this.sprite, { alpha: 0 });

        timeline.to(
            this.sprite,
            this.options.transitionTime,
            {
                alpha: 1,
            },
            time,
        );

        time += this.options.transitionTime;
        time += this.options.wait;

        timeline.to(
            this.sprite,
            this.options.transitionTime,
            {
                alpha: 0,
            },
            time,
        );

        return timeline;
    }

    destroy() {
        this.containers.forEach((container) => {
            container.destroy({
                children: true,
            });
        });
    }

    getCoords() {
        return getSizeWithinMax(
            this.sprite.width,
            this.sprite.height,
            this.options.width,
            this.options.height,
            { cover: false },
        );
    }
}

export default GifComponent;
