import { TimelineLite, TweenMax } from 'gsap';
import * as PIXI from 'pixi.js';
import merge from 'lodash/merge';
import { getSizeWithinMax } from '../../utils/size';

// import * as pixiFilters from 'pixi-filters';
// import getCvData from '../../utils/getCvData';
// import filters from '../filters/index';

class Generic {
    constructor(options = { scene: null, width: 0, height: 0 }) {
        this.setOptions(options);

        this.image = {};
        this.sprite = null;
        this.border = null;
        this.coords = null;

        this.timeline = null;
        this.graphics = [];
        this.label = {};
        this.labels = [];
        this.labelBackgrounds = [];

        this.container = new PIXI.Container();
        this.topContainer = new PIXI.Container();
        this.textContainer = new PIXI.Container();
        this.textBackgroundContainer = new PIXI.Container();

        this.containers = [
            this.container,
            this.topContainer,
            this.textContainer,
            this.textBackgroundContainer,
        ];

        this.dynamicTargets = [];
        this.dynamicGraphics = [];
        this.dynamicLabels = [];

        this.init = this.init.bind(this);
        this.resize = this.resize.bind(this);

        this.init();

        return this;
    }

    setOptions(options = {}) {
        const opts = merge(
            {
                time: 1,
                transitionTime: 2,
                playTime: 5,
                src: '/img/textures/tele/tele.json',
                toggleOverlay: () => {},
            },
            options,
        );

        this.options = opts;
    }

    init() {
        if (this.sprite !== null) {
            this.sprite.destroy();
        }

        const frameData = PIXI.loader.resources[this.options.src].data.frames;

        // create an array of textures from an image path
        this.frames = [];
        Object.keys(frameData).forEach((name) => {
            this.frames.push(PIXI.Texture.fromFrame(name));
        });

        this.sprite = new PIXI.extras.AnimatedSprite(this.frames);
        this.sprite.loop = false;
        this.sprite.anchor.set(0.5, 1);
        this.sprite.animationSpeed = 0.1;

        this.coords = this.getCoords();

        // this.pixelFilter = new filters.AsciiFilter(20);
        // this.pixelFilter.size = 20;
        // console.log(this.coords.width, this.coords.height);

        this.sprite.width = this.coords.width;
        this.sprite.height = this.coords.width * 0.6666;

        // this.bloomFilter = new pixiFilters.BloomFilter(3);
        // this.sprite.filters = [this.bloomFilter];

        TweenMax.set(this.sprite, {
            alpha: 0,
            x: this.options.width / 2,
            y: this.options.height,
        });

        this.container.addChild(this.sprite);
    }

    resize() {
        if (this.sprite !== null && this.container !== null) {
            this.coords = this.getCoords();

            this.sprite.width = this.coords.width;
            this.sprite.height = this.coords.height;

            TweenMax.set(this.sprite, {
                x: this.options.width / 2,
                y: this.options.height,
            });
        }
    }

    getTimeline(start = 0, onComplete = null) {
        let time = start;

        const timeline = new TimelineLite({
            onComplete: () => {
                if (onComplete !== null) {
                    onComplete();
                }
            },
        });
        timeline.set(this.sprite, { alpha: 0 });

        timeline.to(
            this.sprite,
            this.options.transitionTime,
            {
                alpha: 1,
            },
            time,
        );

        time += this.options.transitionTime;

        timeline.add(() => {
            this.sprite.gotoAndPlay(0);
        }, time);

        time += this.options.playTime;

        timeline.to(
            this.sprite,
            this.options.transitionTime,
            {
                alpha: 0,
            },
            time,
        );

        return timeline;
    }

    destroy() {
        this.containers.forEach((container) => {
            container.destroy({
                children: true,
            });
        });
    }

    getCoords() {
        return getSizeWithinMax(
            this.sprite.width,
            this.sprite.height,
            this.options.width,
            this.options.height,
            { cover: false },
        );
    }
}

export default Generic;
