import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Play from '../layout/Play';

const propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    content: PropTypes.object,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

const defaultProps = {
    content: {},
};

class VideoClips extends Component {
    constructor(props) {
        super(props);

        this.refVideoClips = null;
        this.refVideo = null;
        this.onClick = this.onClick.bind(this);
        this.onTogglePlay = this.onTogglePlay.bind(this);

        this.state = {
            index: 0,
            playing: true,
        };
    }

    componentDidMount() {
        this.play();
        this.refVideo.addEventListener('ended', this.props.onClose);
    }

    componentDidUpdate(prevProps) {
        const itemsChanged = prevProps.content !== this.props.content;
        const openChanged = prevProps.open !== this.props.open;

        if (itemsChanged) {
            this.reset();
        }

        if (openChanged) {
            if (this.props.open) {
                this.play();
            } else {
                this.pause();
            }
        }
    }

    componentWillUnmount() {
        this.pause();
        this.refVideo.removeEventListener('ended', this.props.onClose);
    }

    onClick() {
        const { index } = this.state;
        const { content, onClose } = this.props;
        const [item] = content.items;

        if (this.refVideo === null) {
            return;
        }

        const nextIndex = index + 1;

        const ct = this.refVideo.currentTime || 0;
        const c = item.cues.findIndex(cue => parseInt(cue, 10) > parseInt(ct, 10)) || 0;
        const next = c > nextIndex ? c : nextIndex;

        if (item.cues && item.cues[next] && this.refVideo !== null) {
            this.setState(
                {
                    index: next,
                },
                () => {
                    if (this.refVideo !== null) {
                        this.refVideo.currentTime = item.cues[this.state.index];
                        this.refVideo.play();
                    }
                },
            );
        } else {
            onClose();
        }
    }

    onTogglePlay(e) {
        e.preventDefault();
        e.stopPropagation();

        if (this.refVideo !== null) {
            if (this.state.playing) {
                this.refVideo.pause();
            } else {
                this.refVideo.play();
            }
            this.setState({
                playing: !this.state.playing,
            });
        }
    }

    play() {
        if (this.refVideo !== null) {
            this.refVideo.play();
        }
    }

    pause() {
        if (this.refVideo !== null) {
            this.refVideo.pause();
        }
    }

    reset() {
        this.pause();
        this.setState({
            index: 0,
        });
    }

    render() {
        const { width, height, content } = this.props;
        const [item] = content.items;
        const size = width * 0.5625;

        console.log(item);

        return (
            <div
                ref={(ref) => {
                    this.refVideoClips = ref;
                }}
                className="videoclips-container"
            >
                <button onClick={this.onClick}>
                    <video
                        key={item.rawSrc}
                        ref={(ref) => {
                            this.refVideo = ref;
                        }}
                        style={{
                            height: size,
                            top: `${height / 2}px`,
                            marginTop: `-${size / 2}px`,
                        }}
                        playsInline
                    >
                        <source src={item.rawSrc} type="video/mp4" />
                    </video>
                    {item && item.credits ? (
                        <p className="videoclips-credits">{item.credits}</p>
                    ) : null}
                </button>
                <Play
                    playing={this.state.playing}
                    onPlay={this.onTogglePlay}
                    onPause={this.onTogglePlay}
                    loading={100}
                    hidden={false}
                    className="video"
                />
            </div>
        );
    }
}

VideoClips.propTypes = propTypes;
VideoClips.defaultProps = defaultProps;

export default VideoClips;
