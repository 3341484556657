import React from 'react';
import Title from '../partials/Title';
import Row from '../partials/Row';

const propTypes = {
    title: React.PropTypes.string,
    performances: React.PropTypes.array,
};

const defaultProps = {
    title: null,
    performances: null,
};

const CreditsSection = (props) => {
    const { performances, title } = props;

    return performances && performances.length ? (
        <section className="page-section page-section-performances page-section-rows">
            <Title text={title} className="section-title" subtitle />
            { performances.map(item => (
                <Row label={item.location ? item.location.snippet.title : '(Inconnu)'} value={item.date} key={`performance-${item.id}`} />
            )) }
        </section>
    ) : null;
};

CreditsSection.propTypes = propTypes;
CreditsSection.defaultProps = defaultProps;

export default CreditsSection;
