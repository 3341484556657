import React from 'react';
import Relay from 'react-relay';
import { pascal as pascalCase } from 'change-case';
import { connect } from 'react-redux';

import BubbleHome from './BubbleHome';
import BubbleArtist from './BubbleArtist';
import BubbleAudio from './BubbleAudio';
import BubbleDocument from './BubbleDocument';
import BubblePublication from './BubblePublication';
import BubbleShow from './BubbleShow';
import BubbleVideo from './BubbleVideo';
import BubbleGallery from './BubbleGallery';

const BubblesComponents = {
    BubbleHome,
    BubbleArtist,
    BubbleAudio,
    BubbleDocument,
    BubblePublication,
    BubbleShow,
    BubbleVideo,
    BubbleGallery,
};

const propTypes = {
    bubble: React.PropTypes.shape({
        type: React.PropTypes.string,
        snippet: React.PropTypes.shape({
            title: React.PropTypes.string,
        }),
    }).isRequired,
    relay: React.PropTypes.shape({}),
    layout: React.PropTypes.shape({}),
};

const defaultProps = {
    relay: null,
    layout: null,
};

const BubblePage = (props) => {
    const { bubble, relay, layout } = props;
    const { newsletter = null } = layout || {};

    const componentName = pascalCase(`bubble-${bubble.type}`);
    if (!BubblesComponents[componentName]) {
        return null;
    }
    let BubbleComponent = BubblesComponents[componentName];
    if (relay) {
        BubbleComponent = BubbleComponent.RelayComponent;
    }

    return (
        <div>
            <BubbleComponent {...props} newsletter={newsletter} />
        </div>
    );
};

BubblePage.propTypes = propTypes;
BubblePage.defaultProps = defaultProps;

// Get fragments from bubbles components
const bubblesFragments = [];
Object.keys(BubblesComponents).forEach((key) => {
    const BubbleComponent = BubblesComponents[key];
    if (BubbleComponent.RelayComponent) {
        bubblesFragments.push(BubbleComponent.RelayComponent.getFragment('bubble'));
    }
});

BubblePage.RelayComponent = Relay.createContainer(BubblePage, {
    fragments: {
        bubble: () =>
            Relay.QL`
                fragment on Bubble {
                    type
                    snippet {
                        title
                    }
                    ${bubblesFragments}
                }
            `,
    },
});

BubblePage.RelayComponent = connect((state) => ({
    layout: state.layout.toJS(),
}))(BubblePage.RelayComponent);

const BubblePageWithConnect = connect((state) => ({
    layout: state.layout.toJS(),
}))(BubblePage);

export default BubblePageWithConnect;
