import React from 'react';
import { Route } from 'react-router';
import { getRouteComponentProps } from './lib/utils';
import URL from './lib/url';
import MainLayoutContainer from './containers/layouts/Main';
import WithSidebarContentContainer from './containers/layouts/WithSidebar';
import FullContentContainer from './containers/layouts/Full';
// import TripLayout from './trips/Layout';

export default function (routes) {
    const url = new URL(routes);
    // console.log('route', url.route('voyage.segment'), url.route('voyage.section'));
    return (
        <Route>
            <Route path={url.route('home')} {...getRouteComponentProps('Home')} />
            <Route path={url.route('voyage.segment')} {...getRouteComponentProps('Voyages')} />
            <Route path={url.route('voyage.section')} {...getRouteComponentProps('Voyages')} />
            <Route path={url.route('voyage')} {...getRouteComponentProps('Voyages')} />
            <Route component={MainLayoutContainer}>
                <Route component={WithSidebarContentContainer}>
                    <Route path={url.route('about')} {...getRouteComponentProps('About')} />
                    <Route
                        path={url.route('catalog.index')}
                        {...getRouteComponentProps('Catalog')}
                    />
                    <Route
                        path={url.route('catalog.search.index')}
                        {...getRouteComponentProps('Catalog')}
                    />
                    <Route
                        path={url.route('catalog.productions.index')}
                        {...getRouteComponentProps('Catalog')}
                    />
                    <Route
                        path={url.route('catalog.artists.index')}
                        {...getRouteComponentProps('Catalog')}
                    />
                    <Route
                        path={url.route('catalog.publications.index')}
                        {...getRouteComponentProps('Catalog')}
                    />
                    <Route
                        path={url.route('catalog.medias.index')}
                        {...getRouteComponentProps('Catalog')}
                    />
                    <Route
                        path={url.route('catalog.medias.videos.index')}
                        {...getRouteComponentProps('Catalog')}
                    />
                    <Route
                        path={url.route('catalog.medias.audios.index')}
                        {...getRouteComponentProps('Catalog')}
                    />
                    <Route
                        path={url.route('catalog.medias.galleries.index')}
                        {...getRouteComponentProps('Catalog')}
                    />
                    <Route
                        path={url.route('catalog.medias.documents.index')}
                        {...getRouteComponentProps('Catalog')}
                    />
                </Route>
                <Route component={FullContentContainer}>
                    <Route path={url.route('catalog.show')} {...getRouteComponentProps('Bubble')} />
                    <Route
                        path={url.route('catalog.show.subsection')}
                        {...getRouteComponentProps('Bubble')}
                    />
                </Route>
            </Route>
        </Route>
    );
}
