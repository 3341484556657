import Immutable from 'immutable';
import {
    ROUTES_SET,
} from '../actions/ActionTypes';

const initialState = {};

const RoutesReducer = (previousState, action) => {
    const state = previousState || new Immutable.Map(initialState);

    switch (action.type) {
    case ROUTES_SET:
        return new Immutable.Map(action.payload);
    default:
        return state;
    }
};

export default RoutesReducer;
