import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RootContainer } from 'react-relay';

import { updateSetting } from '../actions/ListsActions';
// import { getCurrentSectionFromLocation } from '../lib/utils';
import withUrl from '../lib/withUrl';
import BubblesListComponent from '../components/BubblesList';
import BubblesListRoute from '../routes/BubblesList';

import LoadingSection from '../components/partials/LoadingSection';

const propTypes = {
    // The main params
    name: React.PropTypes.string,
    date: React.PropTypes.string,

    itemSize: React.PropTypes.string,
    sectionSize: React.PropTypes.string,
    isSingle: React.PropTypes.bool,
    lists: React.PropTypes.shape({
        get: React.PropTypes.func,
    }).isRequired,
    dispatch: React.PropTypes.func.isRequired,
    pageSize: React.PropTypes.number,
    loadingSize: React.PropTypes.number,
    filters: React.PropTypes.shape({}),
    decorator: React.PropTypes.func,
    loadingDecorator: React.PropTypes.func,
    letter: React.PropTypes.string,

    sections: React.PropTypes.shape({
        filter: React.PropTypes.func,
        toJS: React.PropTypes.func,
    }).isRequired,
    url: React.PropTypes.shape({
        route: React.PropTypes.func,
    }).isRequired,
    location: React.PropTypes.shape({
        pathname: React.PropTypes.string,
    }).isRequired,
    scrollY: React.PropTypes.number,
};

const defaultProps = {
    name: null,
    date: null,
    itemSize: 'large',
    sectionSize: 'large',
    isSingle: true,
    pageSize: 6,
    loadingSize: null,
    filters: {},
    decorator: null,
    loadingDecorator: null,
    letter: '',
    scrollY: 0,
};

const BubblesList = (props) => {
    const {
        name,
        itemSize,
        sectionSize,
        isSingle,
        filters,
        pageSize,
        loadingSize,
        lists,
        dispatch,
        loadingDecorator,
        letter,
        sections,
        url,
        location,
        ...listProps
    } = props;

    const routeParams = {};
    const validFilters = {};

    if (name) {
        routeParams.name = name;
        listProps.name = name;
    }

    if (pageSize) {
        routeParams.pageSize = pageSize;
        listProps.pageSize = pageSize;
    }

    if (filters) {
        const sectionName = name.indexOf(':') > -1 ? name.split(':')[1] : '';
        Object.keys(filters).forEach((filterName) => {
            if (typeof filters[filterName] !== 'undefined' && filters[filterName].length > 0) {
                const filtersSection = filterName.indexOf('_') > -1 ? filterName.split('_')[0] : '';
                if (filtersSection === sectionName || filtersSection === 'search') {
                    validFilters[filterName] = filters[filterName];
                }
            }
        });
        routeParams.filters = filters;
        listProps.filters = filters;
    }

    if (letter) {
        routeParams.letter = letter;
        listProps.letter = letter;
        routeParams.order = 'name';
        listProps.order = 'name';
    } else {
        routeParams.letter = null;
        listProps.letter = null;
        routeParams.order = 'date';
        listProps.order = 'date';
    }

    if (itemSize) {
        listProps.itemSize = itemSize;
    }

    listProps.isSingle = isSingle;

    const settings = lists.get(name);
    const settingsProps = settings ? settings.toJS() : {};
    const actionsProps = {
        setView: value => dispatch(updateSetting(name, 'view', value)),
    };

    return (
        <RootContainer
            Component={BubblesListComponent.RelayComponent}
            route={new BubblesListRoute(routeParams)}
            renderFetched={(data) => {
                const allProps = Object.assign(
                    { sectionSize },
                    data,
                    listProps,
                    settingsProps,
                    actionsProps,
                );
                return <BubblesListComponent.RelayComponent {...allProps} />;
            }}
            renderLoading={() => {
                const loading = (
                    <LoadingSection
                        name={name}
                        itemSize={itemSize}
                        pageSize={loadingSize !== null ? loadingSize : pageSize}
                        sectionSize={sectionSize}
                        letter={letter}
                        view={settingsProps.view ? settingsProps.view : 'grid'}
                    />
                );
                return loadingDecorator ? loadingDecorator(loading) : loading;
            }}
        />
    );
};

BubblesList.propTypes = propTypes;
BubblesList.defaultProps = defaultProps;

export default withUrl(
    withRouter(
        connect(state => ({
            lists: state.lists,
            sections: state.sections,
        }))(BubblesList),
    ),
);
