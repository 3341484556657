import { routerReducer } from 'react-router-redux';
import RoutesReducer from './RoutesReducer';
import SectionsReducer from './SectionsReducer';
import SubsectionsReducer from './SubsectionsReducer';
import FiltersReducer from './FiltersReducer';
import BubblesReducer from './BubblesReducer';
import LayoutReducer from './LayoutReducer';
import ListsReducer from './ListsReducer';
import HomeReducer from './HomeReducer';

export default {
    routes: RoutesReducer,
    sections: SectionsReducer,
    subsections: SubsectionsReducer,
    filters: FiltersReducer,
    bubbles: BubblesReducer,
    layout: LayoutReducer,
    lists: ListsReducer,
    routing: routerReducer,
    home: HomeReducer,
};
