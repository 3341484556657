import { TimelineLite, TweenMax, RoughEase, Power0, Sine, Bounce } from 'gsap';
import * as PIXI from 'pixi.js';
import merge from 'lodash/merge';
import GlowFilter from '../filters/glow/glow';
import BeepSound from '../../sounds/beep';
import { getSizeWithinMax } from '../../utils/size';

class Box {
    constructor(box, container, index, options = { scene: null, width: 0, height: 0 }, parents) {
        this.setOptions(options);

        this.index = index;
        this.params = box;
        this.parents = parents;
        this.siblings = [];
        this.siblingBoxes = [];

        this.sound = new BeepSound('/audio/general/beepbeep21.mp3', 'lowPassFilter', 0.2);

        this.label = null;
        this.sprite = null;
        this.cover = null;
        this.beam = null;
        this.name = null;
        this.animation = null;
        this.hoverTimeline = null;
        this.loopTimeline = null;

        this.openTimeline = null;
        this.closeTimeline = null;

        this.hoverInTimeline = null;
        this.hoverOutTimeline = null;

        this.destroyed = false;
        this.filter = null;

        this.ease = RoughEase.ease.config({
            template: Power0.easeNone,
            strength: 1,
            points: 40,
            taper: 'none',
            randomize: true,
            clamp: false,
        });

        this.filter = new GlowFilter(2, 1, 1, 0xff0000, 0.1); // PIXI.filters.BloomFilter();

        this.animationContainer = new PIXI.Container();
        this.container = new PIXI.Container();
        this.container.alpha = 0;
        this.container.interactive = true;
        this.container.buttonMode = true;

        this.toggle = this.toggle.bind(this);
        this.resize = this.resize.bind(this);
        this.destroy = this.destroy.bind(this);
        // this.onClick = this.onClick.bind(this);
        this.addSiblings = this.addSiblings.bind(this);

        return this;
    }

    addSiblings(boxes) {
        this.siblings = boxes.map(b => b.animationContainer);
        this.siblingBoxes = boxes.map(b => b.container);
    }

    addBackground() {
        this.createName();
        this.createAnimation();
    }

    addForeground() {
        const { label } = this.params;

        this.createBeam();
        this.createBox();
        this.createLabel();
        this.createCover();

        this.openTimeline = this.getBoxOpenTimeline();
        this.closeTimeline = this.getBoxCloseTimeline();
        this.hoverTimeline = this.getHoverTimeline();

        // Give it 2 seconds to appear completely
        setTimeout(() => {
            // console.log('hello events');
            this.addHoverIn(this.container);
            this.addHoverOut(this.container);
            this.addClick(this.container, label);
        }, 2000);
    }

    setOptions(options = {}) {
        const opts = merge(
            {
                mobile: false,
                coverName: 'couvercle',
                boxName: 'boite',
                beamName: 'beam',
                textures: {
                    boite: '/img/trips/boxes/boite.png',
                    couvercle: '/img/trips/boxes/couvercle.png',
                    beam: '/img/trips/boxes/beam.png',
                    histoire: '/img/trips/boxes/histoire.png',
                    nte: '/img/trips/boxes/nte.png',
                    reflexions: '/img/trips/boxes/reflexions.png',
                    rencontres: '/img/trips/boxes/rencontres.png',
                },
                animations: {
                    nte: '/img/textures/boite_1_chars/boite_1_chars.json',
                    histoire: '/img/textures/boite_2_chars/boite_2_chars.json',
                    reflexions: '/img/textures/boite_3_chars/boite_3_chars.json',
                    rencontres: '/img/textures/boite_4_chars/boite_4_chars.json',
                },
                names: {
                    nte: '/img/textures/nte.png',
                    histoire: '/img/textures/histoire.png',
                    reflexions: '/img/textures/reflexions.png',
                    rencontres: '/img/textures/rencontres.png',
                },
                toggle: null,
                time: 1,
                transitionTime: 2,
                wait: 5,
                overlayLabel: '',
                x: 0,
                y: 0,
                onClick: () => {},
                onResume: () => {},
                onHover: () => {},
            },
            options,
        );

        this.options = opts;
    }

    getBoxOpenTimeline(start = 0) {
        let time = start;
        const timeline = new TimelineLite({
            paused: true,
            onComplete: () => {
                // console.log('complete open', this.index);
                // if (onComplete !== null) {
                // onComplete();
                // }
            },
        });

        timeline.to(
            this.sprite,
            this.options.transitionTime,
            {
                alpha: 1,
                ease: Sine.easeOut,
            },
            time,
        );

        time += 0.3;
        const ease = Bounce.easeOut;
        const tt = this.options.transitionTime * 1.4;
        const destination = Math.floor(1100 * this.options.ratio);

        timeline.to(
            this.cover,
            tt,
            {
                y: this.params.y - destination,
                ease,
            },
            time,
        );

        timeline.to(
            this.beam.scale,
            tt,
            {
                y: this.options.ratio,
                ease,
            },
            time,
        );

        timeline.to(
            this.beam,
            tt,
            {
                alpha: 0.3,
                ease,
            },
            time,
        );

        timeline.to(
            this.name,
            tt,
            {
                alpha: 1,
                ease,
            },
            time,
        );

        // timeline.add(() => {
        //     this.playBeamLoopTimeline();
        // }, time + tt);

        return timeline;
    }

    getBoxCloseTimeline(start = 0, onComplete = null) {
        let time = start;

        // if (this.destroyed === true) {
        //     return new TimelineLite();
        // }

        const timeline = new TimelineLite({
            paused: true,
            onStart: () => {
                // console.log(
                //     'start close box',
                //     this.index,
                //     this.cover,
                //     Math.floor(100 * this.options.ratio),
                // );

                if (this.openTimeline !== null) {
                    this.openTimeline.stop();
                }

                if (this.destroyed === true && timeline) {
                    timeline.kill();
                }
            },
            onComplete: () => {
                // console.log('end close box', this.index);
                if (this.beam) {
                    this.beam.alpha = 0;
                    this.beam.scale.y = 0;
                }
                if (this.name) {
                    this.name.alpha = 0;
                }
                if (onComplete !== null) {
                    // onComplete();
                }
            },
        });

        timeline.to(
            this.beam.scale,
            0.1,
            {
                y: 0,
            },
            time,
        );

        timeline.to(
            this.beam,
            0.1,
            {
                alpha: 0,
            },
            time,
        );

        time += 0.3;
        const tt = 0.2;
        const destination = Math.floor(100 * this.options.ratio);

        timeline.to(
            this.cover,
            tt * 2,
            {
                y: this.params.y - destination,
                // ease,
            },
            time,
        );

        timeline.to(
            this.name,
            tt * 2,
            {
                alpha: 0,
                // ease,
            },
            time,
        );

        timeline.to(
            this.sprite,
            tt,
            {
                alpha: 1,
                // ease: Sine.easeOut,
            },
            time,
        );

        return timeline;
    }

    // This makes the character switch & play happen
    getHoverTimeline() {
        const timeline = new TimelineLite({
            paused: true,
            onStart: () => {
                // console.log('hover start', this.animation);
                this.animation.gotoAndStop(0);
                this.animation.play(0);
            },
            onComplete: () => {
                // console.log('hover komplete');
            },
        });

        timeline.to(
            this.siblings,
            0.2,
            {
                alpha: 0,
            },
            0,
        );

        timeline.to(
            this.animation,
            0.2,
            {
                alpha: 1,
            },
            0,
        );

        return timeline;
    }

    addClick(element, label) {
        element.on('pointerdown', () => {
            element.removeAllListeners();
            this.options.onClickParent(this.index);

            if (this.hoverTimeline !== null) {
                this.hoverTimeline.kill();
                this.hoverTimeline = null;
            }

            if (this.openTimeline !== null) {
                this.openTimeline.kill();
                this.openTimeline = null;
            }

            if (this.closeTimeline !== null) {
                this.closeTimeline.kill();
                this.closeTimeline = null;
            }

            // if (this.siblingBoxes && this.siblingBoxes.length > 0) {
            //     this.siblingBoxes.map((sb) => {
            //         console.log(sb);
            //         if (sb && sb.closeTimeline !== null) {
            //             // sb.closeTimeline.kill();
            //             // sb.closeTimeline = null;
            //         }
            //     });
            // }

            const timeline = new TimelineLite({
                delay: 0.7,
                onComplete: () => {
                    this.options.onClick(`${label}/0`);
                },
            });

            timeline.to(
                [this.container].concat(this.siblingBoxes),
                1.2,
                {
                    alpha: 0,
                },
                0.1,
            );

            timeline.to(
                [this.animationContainer].concat(this.siblings).concat(this.parents),
                0.9,
                {
                    alpha: 0,
                },
                0.8,
            );
        });
    }

    addHoverIn(element) {
        element.on('mouseover', () => {
            // console.log('bro');
            if (this.hoverOutTimeline !== null) {
                this.hoverOutTimeline.kill();
                this.hoverOutTimeline = null;
            }

            if (this.hoverInTimeline !== null) {
                this.hoverInTimeline.kill();
                this.hoverInTimeline = null;
            }

            const hoverInTimeline = new TimelineLite({
                onStart: () => {
                    // console.log('hover in man', this.index);
                    this.sound.play();
                    this.animation.gotoAndStop(0);
                    this.options.onHoverInParent(this.index);
                },
            });

            hoverInTimeline.to(this.label, 1, { alpha: 0 }, 0);

            hoverInTimeline.to(
                this.parents,
                1,
                {
                    alpha: 0,
                },
                0,
            );

            hoverInTimeline.to(
                this.siblings,
                1,
                {
                    alpha: 0,
                },
                0,
            );

            hoverInTimeline.to(
                this.animationContainer,
                1,
                {
                    alpha: 1,
                },
                0,
            );

            hoverInTimeline.to(
                [this.sprite, this.cover, this.label],
                0.3,
                {
                    alpha: 1,
                },
                0,
            );

            hoverInTimeline.add(() => {
                this.hoverTimeline.play(0);
            }, 0);

            hoverInTimeline.add(() => {
                if (!this.destroyed) {
                    if (this.closeTimeline !== null) {
                        // console.log('hiiah');
                        this.closeTimeline.pause();
                    }
                    if (this.openTimeline !== null) {
                        // console.log('heh');
                        this.openTimeline.play(0);
                    }
                }
            }, 2);

            this.hoverInTimeline = hoverInTimeline;
        });
    }

    addHoverOut(element) {
        element.on('mouseout', () => {
            // console.log('bro out');
            // console.log('out');
            if (this.hoverOutTimeline !== null) {
                this.hoverOutTimeline.kill();
                this.hoverOutTimeline = null;
            }

            if (this.hoverInTimeline !== null) {
                this.hoverInTimeline.kill();
                this.hoverInTimeline = null;
            }

            const hoverOutTimeline = new TimelineLite({
                onStart: () => {
                    // console.log('hover out', this.index);
                    this.animation.stop();
                    if (this.openTimeline !== null) {
                        this.openTimeline.pause();
                    }
                    this.options.onHoverOutParent(this.index);
                },
                onComplete: () => {
                    this.animation.gotoAndStop(0);
                },
            });

            // hoverOutTimeline.add(() => {
            // if (this.closeTimeline !== null && !this.closeTimeline.isActive()) {
            //     this.closeTimeline.play(0);
            // }
            // }, 0.3);

            hoverOutTimeline.to(
                this.animationContainer,
                1,
                {
                    alpha: 0,
                },
                0,
            );

            hoverOutTimeline.to(
                this.parents,
                1,
                {
                    alpha: 1,
                },
                0,
            );

            this.hoverOutTimeline = hoverOutTimeline;
        });
    }

    createBox() {
        const { ratio, boxName, textures } = this.options;
        const resource = textures[boxName];

        const sprite = new PIXI.Sprite(PIXI.loader.resources[resource].texture);
        sprite.filters = [this.filter];
        sprite.anchor.set(1, 0.5);

        sprite.x = this.params.x;
        sprite.y = this.params.y;

        sprite.width = 416;
        sprite.height = 300;
        sprite.scale.x = ratio;
        sprite.scale.y = ratio;
        sprite.interactive = true;
        sprite.buttonMode = true;

        this.container.addChild(sprite);
        this.sprite = sprite;
    }

    createLabel() {
        const { ratio, textures } = this.options;
        const { label } = this.params;
        const resource = textures[label];

        const sprite = new PIXI.Sprite(PIXI.loader.resources[resource].texture);
        sprite.filters = [this.filter];
        sprite.anchor.set(1, 0.5);

        sprite.x = this.params.x - ratio * 20; // eslint-disable-line
        sprite.y = this.params.y + ratio * 20; // eslint-disable-line

        sprite.width = 200;
        sprite.height = 200;
        sprite.scale.x = ratio;
        sprite.scale.y = ratio;
        sprite.interactive = true;
        sprite.buttonMode = true;

        this.container.addChild(sprite);
        this.label = sprite;
    }

    createCover() {
        const { ratio, coverName, textures } = this.options;
        const resource = textures[coverName];

        const sprite = new PIXI.Sprite(PIXI.loader.resources[resource].texture);
        sprite.filters = [this.filter];
        sprite.anchor.set(1, 0.5);

        sprite.x = this.params.x + ratio * 10; // eslint-disable-line
        // prettier-ignore
        sprite.y = this.params.y - (100 * ratio); // eslint-disable-line

        sprite.width = 431;
        sprite.height = 109;
        sprite.scale.x = ratio;
        sprite.scale.y = ratio;
        sprite.interactive = true;
        sprite.buttonMode = true;

        this.container.addChild(sprite);
        this.cover = sprite;
    }

    createBeam() {
        const { ratio, beamName, textures } = this.options;
        const resource = textures[beamName];

        const sprite = new PIXI.Sprite(PIXI.loader.resources[resource].texture);
        sprite.filters = [this.filter];
        sprite.anchor.set(1, 1);

        // prettier-ignore
        sprite.x = this.params.x - (ratio * 20);
        // prettier-ignore
        sprite.y = this.params.y - (60 * ratio); // eslint-disable-line
        sprite.width = 375;
        sprite.height = 1093;
        sprite.scale.x = ratio;
        sprite.scale.y = 0; // ratio;
        sprite.interactive = false;
        sprite.buttonMode = false;
        sprite.hitArea = new PIXI.Rectangle(0, 0, 0, 0);

        sprite.alpha = 0.3;

        this.container.addChild(sprite);
        this.beam = sprite;
    }

    createName() {
        const { names, ratio, width, height } = this.options;
        const { label } = this.params;
        const resource = names[label];
        const frameSize = getSizeWithinMax(2448, 1632, width, height, {
            cover: false,
        });

        const sprite = new PIXI.Sprite(PIXI.loader.resources[resource].texture);
        sprite.filters = [this.filter];
        sprite.anchor.set(0.5, 1);
        sprite.width = 618;
        sprite.height = 84;
        sprite.scale.x = ratio * 2;
        sprite.scale.y = ratio * 2;
        sprite.interactive = false;
        sprite.buttonMode = false;
        sprite.hitArea = new PIXI.Rectangle(0, 0, 0, 0);
        sprite.alpha = 0;

        sprite.x = frameSize.width / 2;
        sprite.y = frameSize.height / 3;

        this.animationContainer.addChild(sprite);
        this.name = sprite;
    }

    createAnimation() {
        const { animations } = this.options;
        const { label } = this.params;

        const frameData = PIXI.loader.resources[animations[label]].data.frames;
        const frames = [];
        Object.keys(frameData).forEach((frameName) => {
            frames.push(PIXI.Texture.fromFrame(frameName));
        });

        const sprite = new PIXI.extras.AnimatedSprite(frames);
        sprite.loop = false;
        sprite.anchor.set(0.5, 1);
        sprite.animationSpeed = 0.1;
        sprite.interactive = false;
        sprite.buttonMode = false;
        sprite.hitArea = new PIXI.Rectangle(0, 0, 0, 0);

        const coords = this.getCoords(sprite);
        sprite.scale = new PIXI.ObservablePoint(null, null, coords.scale, coords.scale);

        const half = this.options.width / 2;
        TweenMax.set(sprite, {
            alpha: 0,
            x: this.options.mobile ? half - 20 : half - 100,
            y: this.options.mobile ? this.options.height - 20 : this.options.height - 100,
        });

        this.animationContainer.addChild(sprite);
        this.animation = sprite;
    }

    toggle() {
        this.options.toggle();
    }

    resize() {
        // if (this.sprite !== null && this.container !== null) {
        //     const half = this.options.width / 2;
        //     TweenMax.set(this.animationsprite, {
        //         alpha: 0,
        //         x: this.options.mobile ? half - 20 : half - 100,
        //         y: this.options.mobile ? this.options.height - 20 : this.options.height - 100,
        //     });
        // }
    }

    getCoords(sprite) {
        return getSizeWithinMax(
            sprite.width,
            sprite.height,
            this.options.width,
            this.options.height,
            { cover: false },
        );
    }

    destroy() {
        this.destroyed = true;
        // console.log('destroy box', this.index);

        if (this.container !== null) {
            this.container.removeAllListeners();
        }

        if (this.hoverTimeline !== null) {
            this.hoverTimeline.kill();
            this.hoverTimeline = null;
        }

        if (this.openTimeline !== null) {
            this.openTimeline.kill();
            this.openTimeline = null;
        }

        if (this.closeTimeline !== null) {
            this.closeTimeline.kill();
            this.closeTimeline = null;
        }

        this.container.destroy({
            children: true,
        });

        this.animationContainer.destroy({
            children: true,
        });

        if (this.sound) {
            this.sound.destroy();
        }
    }
}

export default Box;
