import React from 'react';
import Relay from 'react-relay';

import Title from '../partials/Title';
import Meta from '../partials/Meta';
import Footer from '../../containers/partials/Footer';
import Gallery from '../Gallery';

import CategoriesSection from '../../containers/sections/Categories';

import CreditsTextSection from '../sections/CreditsText';
import DescriptionSection from '../sections/Description';
import BubblesListSection from '../sections/BubblesList';

const propTypes = {
    bubble: React.PropTypes.shape({
        bubble_id: React.PropTypes.string,
        snippet: React.PropTypes.shape({
            link: React.PropTypes.string,
            title: React.PropTypes.string,
            subtitle: React.PropTypes.string,
        }),
        related: React.PropTypes.array,
        gallery: React.PropTypes.object,
        description: React.PropTypes.string,
        credits_technicians: React.PropTypes.string,
        credits_other: React.PropTypes.string,
    }).isRequired,
    newsletter: React.PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

const BubblePublicationPage = (props) => {
    const id = props.bubble.bubble_id;
    const { title, subtitle } = props.bubble.snippet;
    const { description, gallery, related, categories } = props.bubble;

    const creditsTechnicians = props.bubble.credits_technicians;
    const creditsOther = props.bubble.credits_other;
    const { newsletter = null } = props || {};

    return (
        <article className="container-page container-document">
            <Meta bubble={props.bubble} section="Publication" />

            {title ? (
                <div className="container">
                    <Title text={title} newsletter={newsletter} />
                </div>
            ) : null}

            {gallery ? <Gallery gallery={gallery} /> : null}

            <div className="container">
                <CategoriesSection categories={categories} section="publications" />

                <DescriptionSection
                    title={subtitle !== 'Publication' ? subtitle : null}
                    description={description}
                    separator={subtitle === 'Publication'}
                />

                <CreditsTextSection title="Techniciens" credits={creditsTechnicians} />

                <CreditsTextSection title="Autres crédits" credits={creditsOther} />

                <BubblesListSection name={`shows:${id}`} title="Productions" />

                {related && related.length > 0
                    ? related.map((section) => (
                          <BubblesListSection
                              key={`related-${section.id}`}
                              name={section.id}
                              title={section.title}
                              itemSize="small"
                          />
                      ))
                    : null}

                <BubblesListSection
                    name={`documents:${id}`}
                    title="Documents"
                    sectionSize="small"
                />

                <Footer />
            </div>
        </article>
    );
};

BubblePublicationPage.propTypes = propTypes;

BubblePublicationPage.RelayComponent = Relay.createContainer(BubblePublicationPage, {
    fragments: {
        bubble: () =>
            Relay.QL`
                fragment on BubblePublication {
                    bubble_id
                    snippet {
                        title
                        subtitle
                        link
                        picture_facebook: picture(filter:"facebook") {
                            url
                        }
                    }
                    related {
                        id
                        title
                    }
                    description
                    gallery {
                        title
                        slides {
                            title
                            subtitle
                            description
                            picture_full: picture(filter:"big"){
                                url
                            }
                            picture_mini: picture(filter:"mini"){
                                url
                            }
                            picture_thumbnail: picture(filter:"thumbnail"){
                                url
                            }
                        }
                    }
                    categories {
                        id
                        name
                    }
                    credits {
                        id
                        position
                        bubble {
                            id
                            snippet {
                                link
                                title
                            }
                        }
                    }
                    credits_technicians
                    credits_other
                }
            `,
    },
});

export default BubblePublicationPage;
