function getRouteComponentProps(PageComponent) {
    if (
        (typeof module === 'undefined' || !module.exports) &&
        typeof require.ensure !== 'undefined'
    ) {
        return {
            getComponent: (nextState, cb) => {
                switch (PageComponent) {
                case 'Home':
                    require.ensure([], (require) => {
                        cb(null, require('../containers/pages/Home').default);
                    });
                    break;
                case 'About':
                    require.ensure([], (require) => {
                        cb(null, require('../containers/pages/About').default);
                    });
                    break;
                case 'Catalog':
                    require.ensure([], (require) => {
                        cb(null, require('../containers/pages/Catalog').default);
                    });
                    break;
                case 'Bubble':
                    require.ensure([], (require) => {
                        cb(null, require('../containers/pages/Bubble').default);
                    });
                    break;
                case 'Voyages':
                    require.ensure([], (require) => {
                        cb(null, require('../containers/pages/Voyages').default);
                    });
                    break;
                default:
                    break;
                }
            },
        };
        /* eslint no-else-return: 0, global-require: 0 */
    } else {
        let Component;
        switch (PageComponent) {
        case 'Home':
            Component = require('../containers/pages/Home').default;
            break;
        case 'About':
            Component = require('../containers/pages/About').default;
            break;
        case 'Catalog':
            Component = require('../containers/pages/Catalog').default;
            break;
        case 'Bubble':
            Component = require('../containers/pages/Bubble').default;
            break;
        case 'Voyages':
            Component = require('../containers/pages/Voyages').default;
            break;
        default:
            break;
        }
        return {
            component: Component,
        };
    }
}

const getCurrentSectionFromLocation = (sections, url, location) => {
    const locationParts = location.pathname.replace(/^\//, '').split('/');
    const isSubsection = locationParts.length > 1;
    let currentSection = sections.find((section) => {
        const sectionUrl = url.route(
            section.key === 'catalog' ? 'catalog.index' : `catalog.${section.key}.index`,
        );
        if (isSubsection) {
            return locationParts[0] === sectionUrl.replace(/^\//, '');
        }
        return location.pathname === sectionUrl;
    });
    if (isSubsection && currentSection.sections) {
        currentSection = sections.find((section) => {
            const sectionUrl = url.route(
                section.key === 'catalog' ? 'catalog.index' : `catalog.${section.key}.index`,
            );
            return locationParts[1] === sectionUrl.replace(/^\//, '');
        });
    }
    if (!currentSection && locationParts[0]) {
        currentSection = sections.find(section => section.url === locationParts[0]);
    }
    return currentSection;
};

// Gaussian random function with the given mean and stddeviation
const gaussian = (mean, stdev) => {
    let y2;
    let useLast = false;
    return () => {
        let y1;
        if (useLast) {
            y1 = y2;
            useLast = false;
        } else {
            let x1;
            let x2;
            let w;
            do {
                x1 = 2.0 * Math.random() - 1.0;
                x2 = 2.0 * Math.random() - 1.0;
                w = x1 * x1 + x2 * x2;
            } while (w >= 1.0);
            w = Math.sqrt((-2.0 * Math.log(w)) / w);
            y1 = x1 * w;
            y2 = x2 * w;
            useLast = true;
        }
        const retval = mean + stdev * y1;
        if (retval > 0) {
            return retval;
        }
        return -retval;
    };
};

const utils = {
    getRouteComponentProps,
    getCurrentSectionFromLocation,
    gaussian,
};

export { getRouteComponentProps, getCurrentSectionFromLocation, gaussian };
export default utils;
