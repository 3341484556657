import React from 'react';

const propTypes = {
    html: React.PropTypes.string,
    text: React.PropTypes.string,
};

const defaultProps = {
    html: null,
    text: null,
};

const Text = (props) => {
    const { html, text } = props;
    return (
        <div className="text">
            { html ? (
                <div
                    className="text-html"
                    dangerouslySetInnerHTML={{
                        __html: html,
                    }}
                />
            ) : null }
            { text ? (
                <div className="text-plain">
                    <p>{text}</p>
                </div>
            ) : null }
        </div>
    );
};

Text.propTypes = propTypes;
Text.defaultProps = defaultProps;

export default Text;
