import Immutable from 'immutable';
import {
    FILTERS_UPDATE,
    FILTERS_UPDATE_FILTER,
    FILTERS_CLEAR,
    FILTERS_CLEAR_FILTER,
} from '../actions/FiltersActions';

const initialState = {};

const FiltersReducer = (previousState, action) => {
    const state = previousState || new Immutable.Map(initialState);

    switch (action.type) {
    case FILTERS_UPDATE:
        return new Immutable.Map(action.payload);

    case FILTERS_UPDATE_FILTER:
        if (action.payload.value.length > 0 && action.payload.value[0]) {
            const newState = { [action.payload.name]: action.payload.value };
            return new Immutable.Map(newState);
        }
        return new Immutable.Map(initialState);

    case FILTERS_CLEAR:
            // case '@@router/LOCATION_CHANGE':
        return new Immutable.Map(initialState);

    case FILTERS_CLEAR_FILTER:
        return state.delete(action.payload.name);

    default:
        return state;
    }
};

export default FiltersReducer;
