import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    content: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

const defaultProps = {};

class ZoomGallery extends Component {
    constructor(props) {
        super(props);

        this.imageRefs = {};
        this.refZoomGallery = null;

        this.onZoomIn = this.onZoomIn.bind(this);
        this.onZoomOut = this.onZoomOut.bind(this);
        this.onZoomNext = this.onZoomNext.bind(this);

        this.renderItem = this.renderItem.bind(this);
        this.renderOpen = this.renderOpen.bind(this);

        this.state = {
            zoomedIndex: null,
        };
    }

    onZoomIn(zoomedIndex) {
        if (this.state.zoomedIndex === null && zoomedIndex === null) {
            this.props.onClose();
        }
        this.setState({
            zoomedIndex,
        });
    }

    onZoomOut() {
        this.setState({
            zoomedIndex: null,
        });
    }

    onZoomNext() {
        const { content } = this.props;
        const { zoomedIndex } = this.state;
        const nextIndex = zoomedIndex + 1;
        this.setState({
            zoomedIndex: nextIndex >= content.items.length ? 0 : nextIndex,
        });
    }

    renderItem(item, idx) {
        const { zoomedIndex } = this.state;
        const active = idx === zoomedIndex;

        return (
            <button
                key={`${item.src}-${idx}`}
                className={classNames({
                    'zoom-button': true,
                    selected: active,
                })}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (active) {
                        this.onZoomIn(null);
                    } else {
                        this.onZoomIn(idx);
                    }
                }}
            >
                <img
                    src={item.src}
                    alt={item.src}
                    ref={(ref) => {
                        this.imageRefs[item.src] = ref;
                    }}
                />
            </button>
        );
    }

    renderOpen(item) {
        const { width, height } = this.props;
        const ref = this.imageRefs[item.src];
        const box = ref.getBoundingClientRect();

        let imageWidth = width / 1.2;
        let imageHeight = imageWidth * (box.height / box.width);

        if (imageHeight > height) {
            imageHeight = height / 1.2;
            imageWidth = imageHeight * (box.width / box.height);
        }

        const imageStyle = { width: imageWidth, height: imageHeight };
        const buttonStyle = {
            marginTop: -imageHeight / 2,
            marginLeft: -imageWidth / 2,
        };

        return (
            <button
                key={`${item.src}-open`}
                className={classNames({
                    'zoom-button': true,
                    active: true,
                })}
                onClick={() => {
                    this.onZoomNext();
                }}
                style={buttonStyle}
            >
                <img src={item.src} alt={item.src} style={imageStyle} />
            </button>
        );
    }

    render() {
        const { content } = this.props;
        const { zoomedIndex } = this.state;
        const { items } = content;

        return (
            <div
                ref={(ref) => {
                    this.refZoomGallery = ref;
                }}
                className="zoomgallery-container"
            >
                <button className="zoom-background" onClick={this.onZoomOut} />
                <div className="zoomgallery-inner">
                    <div className="zoomgallery-table">{items.map(this.renderItem)}</div>
                </div>
                {zoomedIndex !== null && items[zoomedIndex]
                    ? this.renderOpen(items[zoomedIndex])
                    : null}
                <button
                    className="zoomgallery-closeall"
                    onClick={() => {
                        this.onZoomIn(null);
                    }}
                />
            </div>
        );
    }
}

ZoomGallery.propTypes = propTypes;
ZoomGallery.defaultProps = defaultProps;

export default ZoomGallery;
