import React from 'react';
import Title from '../partials/Title';
import Text from '../partials/Text';

const propTypes = {
    title: React.PropTypes.string,
    description: React.PropTypes.string,
    separator: React.PropTypes.bool,
};

const defaultProps = {
    title: null,
    description: null,
    separator: false,
};

const DescriptionSection = (props) => {
    const { title, description, separator } = props;
    const titleDisplayed = title ? <Title text={title} className="section-title" subtitle /> : null;

    return description ? (
        <section className="page-section page-section-text">
            {titleDisplayed}
            {titleDisplayed !== null && separator ? <hr className="separator" /> : null}
            <Text html={description} />
        </section>
    ) : null;
};

DescriptionSection.propTypes = propTypes;
DescriptionSection.defaultProps = defaultProps;

export default DescriptionSection;
