import All from './all.json';
import Intro from './intro.json';
import Menu from './menu.json';
import HistoireNTE from './nte.json';
import Histoire from './histoire.json';
import Rencontres from './rencontres.json';
import Reflexions from './reflexions.json';

export default {
    all: All,
    intro: Intro,
    menu: Menu,
    nte: HistoireNTE,
    histoire: Histoire,
    rencontres: Rencontres,
    reflexions: Reflexions,
};
