import Pizzicato from 'pizzicato';

class BeepSound {
    constructor(
        src = '/audio/general/beepbeep2_edit.mp3',
        effect = 'lowPassFilter',
        volume = 0.33,
    ) {
        this.loaded = false;

        this.play = this.play.bind(this);
        this.destroy = this.destroy.bind(this);

        const rack = {
            distortion: new Pizzicato.Effects.Distortion({
                gain: 0.4,
            }),
            quadrafuzz: new Pizzicato.Effects.Quadrafuzz({
                lowGain: 0.6,
                midLowGain: 0.8,
                midHighGain: 0.5,
                highGain: 0.6,
                mix: 1.0,
            }),
            lowPassFilter: new Pizzicato.Effects.LowPassFilter({
                frequency: 400,
                peak: 3,
            }),
        };

        this.sound = new Pizzicato.Sound(
            {
                source: 'file',
                options: { path: src },
            },
            () => {
                this.loaded = true;
            },
        );

        this.sound.volume = volume;
        this.sound.addEffect(rack[effect]);
    }

    play() {
        if (this.loaded) {
            this.sound.play();
        }
    }

    stop() {
        if (this.loaded) {
            this.sound.stop();
        }
    }

    destroy() {
        if (this.sound) {
            this.sound.disconnect();
        }
    }
}

export default BeepSound;
