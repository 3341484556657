import React, { Component } from 'react';
import classNames from 'classnames';
import Link from '../partials/Link';

const propTypes = {
    item: React.PropTypes.shape({
        id: React.PropTypes.number,
        title: React.PropTypes.string.isRequired,
        subtitle: React.PropTypes.string.isRequired,
        link: React.PropTypes.string,
        preview: React.PropTypes.bool,
    }).isRequired,
    size: React.PropTypes.string.isRequired,
    onPreviewChange: React.PropTypes.func.isRequired,
};

class GridMenuItem extends Component {
    static renderItem(item) {
        return (
            <div className="grid-menu-table-container">
                <div className="code">{item.subtitle}</div>
                <div className="title-container">
                    <h3 className="title">{item.title}</h3>
                </div>
            </div>
        );
    }

    constructor(props) {
        super(props);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }

    onMouseEnter(id) {
        this.props.onPreviewChange(id);
    }

    onMouseLeave() {
        this.props.onPreviewChange(null);
    }

    render() {
        const { item, size } = this.props;
        const gridItem = GridMenuItem.renderItem(item);
        const preview = item.preview || false;
        const internal = item.internal;
        // No more guessing
        // ? item.internal
        // : item.link &&
        //   (item.link.indexOf('archives.nte.qc.ca', item.link) > -1 ||
        //       item.link.indexOf('http', item.link) !== 0);

        const link = (item.link || '').replace(/https?:\/\/[^/]+/, '');
        const itemClassNames = classNames({
            'grid-menu-item': true,
            'grid-menu-item-large': size !== 'small',
            'grid-menu-item-small': size === 'small',
        });

        if (preview) {
            return <div className={itemClassNames}>{gridItem}</div>;
        }

        return (
            <div className={itemClassNames}>
                {internal ? (
                    <Link
                        to={link}
                        onMouseEnter={() => {
                            this.onMouseEnter(item.id);
                        }}
                        onMouseLeave={this.onMouseLeave}
                    >
                        {gridItem}
                    </Link>
                ) : (
                    <a href={item.link}>{gridItem}</a>
                )}
            </div>
        );
    }
}

GridMenuItem.propTypes = propTypes;

export default GridMenuItem;
