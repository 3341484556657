import React from 'react';
import { connect } from 'react-redux';
import FullContent from '../../components/layouts/Full';

const propTypes = {
    dispatch: React.PropTypes.func.isRequired,
    layout: React.PropTypes.shape({
        toJS: React.PropTypes.func,
    }).isRequired,
};

const FullContentContainer = (props) => {
    const { layout, ...otherProps } = props;
    const layoutProps = layout.toJS();
    return <FullContent {...otherProps} {...layoutProps} />;
};

FullContentContainer.propTypes = propTypes;

export default connect((state) => ({
    layout: state.layout,
}))(FullContentContainer);
