import * as PIXI from 'pixi.js';
import vertex from '../default.vert';
// import fragment from './ikeda.frag';
import fragment from './thingy.frag';

/**
 * An Ikeda filter, kinda cool really
 *
 * @class
 */

export default class IkedaFilter extends PIXI.Filter {

    constructor() {
        super(vertex, fragment);

        this.uniforms.u_mouse = new Uint8Array(2);
        this.uniforms.u_resolution = new Uint8Array(2); // [0, 0]; // new Float32Array(2);

        // this.time = 1.0;

        this.mouse = [50, 50];
        this.filterResolution = [100, 100];
    }

    /**
     * The pixel size used by the filter.
     *
     * @member {number}
     */
    get time() {
        return this.uniforms.u_time;
    }

    set time(value) {
        this.uniforms.u_time = value;
    }

    get filterResolution() {
        return this.uniforms.u_resolution;
    }
    set filterResolution(value) {
        // console.log(value);
        // this.uniforms.u_resolution = value;
        this.uniforms.u_resolution[0] = value[0];
        this.uniforms.u_resolution[1] = value[1];

        // if (Array.isArray(value)) {
        //
        // }
    }

    get mouse() {
        return this.uniforms.u_mouse;
    }
    set mouse(value) {
        this.uniforms.u_mouse[0] = value[0];
        this.uniforms.u_mouse[1] = value[1];
    }
}
