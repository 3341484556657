import React, { Component } from 'react';
import isArray from 'lodash/isArray';
import classNames from 'classnames';
import CatalogSection from '../partials/CatalogSection';
import Title from '../partials/Title';
import Meta from '../partials/Meta';
import Footer from '../../containers/partials/Footer';
import AZList from '../partials/AZList';

const propTypes = {
    filters: React.PropTypes.shape({}).isRequired,
    sections: React.PropTypes.arrayOf(
        React.PropTypes.shape({
            key: React.PropTypes.string,
        }),
    ).isRequired,
    allSections: React.PropTypes.arrayOf(
        React.PropTypes.shape({
            key: React.PropTypes.string,
        }),
    ).isRequired,
    searchTitle: React.PropTypes.string.isRequired,
};

class CatalogPage extends Component {
    constructor(props) {
        super(props);
        this.renderSection = this.renderSection.bind(this);
        this.onLetterChange = this.onLetterChange.bind(this);
        this.onDirectionChange = this.onDirectionChange.bind(this);

        this.state = {
            letter: '',
            direction: 'asc',
        };
    }

    componentWillUnmount() {
        // console.log('umount');
        // TweenMax.to(this.refCatalog, 1, {
        //     opacity: 0,
        // });
    }

    onLetterChange(letter) {
        this.setState({
            letter,
        });
    }

    onDirectionChange(direction) {
        this.setState({
            direction: direction === 'desc' ? 'desc' : 'asc',
        });
    }

    renderSection(section) {
        const { filters, sections, allSections } = this.props;
        const { direction } = this.state;

        const key = `section-${section.key}`;
        const filterValues = {};
        const singleSection = sections.length === 1;
        const sectionFilters = section.filters || [];
        const parentFilters = section.parent ? section.parent.filters : [];

        []
            .concat(sectionFilters)
            .concat(parentFilters)
            .forEach((filter) => {
                if (filters && filters[filter.name]) {
                    filterValues[filter.name] = filters[filter.name];
                }
            });

        allSections.forEach((sect) => {
            sect.filters.forEach((filter) => {
                if (filter.global === true) {
                    filterValues[filter.name] = filters[filter.name];
                }
            });
        });

        const variablePageSize = window.innerWidth > 1500 ? 6 : 6;

        const pageSizeFull =
            section.page_size && section.page_size.full ? section.page_size.full : variablePageSize;
        const pageSizeSmall =
            section.page_size && section.page_size.small ? section.page_size.small : 4;
        const itemSize = section.item_size ? section.item_size : 'large';

        let hideSection = false;

        if (Object.getOwnPropertyNames(filters).length) {
            const types = isArray(section.bubble_type)
                ? section.bubble_type
                : [section.bubble_type];
            Object.keys(filters).forEach((k) => {
                const filterLabel = k.split('_');
                const filterName = filterLabel[1];
                if (filterName === 'types') {
                    let allNotFound = true;
                    filters[k].forEach((item) => {
                        if (types.indexOf(item) !== -1) {
                            allNotFound = false;
                        }
                    });
                    if (allNotFound) {
                        hideSection = true;
                    }
                }
            });
        }

        if (hideSection) {
            return null;
        }

        return (
            <CatalogSection
                key={key}
                preview={!singleSection}
                filters={filterValues}
                direction={direction}
                section={section}
                letter={singleSection ? this.state.letter : ''}
                isSingle={singleSection}
                loadOnScroll={singleSection}
                pageSize={singleSection ? pageSizeFull : pageSizeSmall}
                itemSize={itemSize}
            />
        );
    }

    render() {
        const { sections, searchTitle } = this.props;

        let title = sections.length === 1 ? sections[0].label : null;
        if (title === null) {
            title =
                sections.length === 4 && sections[0].label === 'Photos' ? 'Supports' : searchTitle;
        }

        const pageClassNames = classNames({
            container: true,
            'container-list': true,
            'container-list-with-sections': sections.length > 1,
        });

        const meta = {
            snippet: {
                title,
                subtitle: '',
            },
        };

        return (
            <section
                className={pageClassNames}
                ref={(ref) => {
                    this.refCatalog = ref;
                }}
            >
                <Meta bubble={meta} />
                <Title text={title} className="title-page" />
                <AZList
                    hidden={sections.length > 1}
                    letter={this.state.letter}
                    onLetterChange={this.onLetterChange}
                    onDirectionChange={this.onDirectionChange}
                />
                {sections.map(this.renderSection)}
                <Footer />
            </section>
        );
    }
}

CatalogPage.propTypes = propTypes;

export default CatalogPage;
