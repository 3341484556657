import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
    stroke: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    width: 30,
    height: 24,
    color: '#fff',
    stroke: 'none',
    className: null,
};

const GridNormalIcon = ({
    width,
    height,
    color,
    stroke,
    className,
    ...props
}) => (
    <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        x="0px"
        y="0px"
        width={`${width}px`}
        height={`${height}px`}
        viewBox="0 0 30 24"
        enableBackground="new 0 0 30 24"
    >
        <path
            {...props}
            fill={color}
            className={className}
            stroke={stroke}
            d="M0,0v24h30V0H0z M25,19H5v-4h20V19z M25,14H5v-4h20V14z M25,9H5V5h20V9z"
        />
    </svg>
);

GridNormalIcon.propTypes = propTypes;
GridNormalIcon.defaultProps = defaultProps;

export default GridNormalIcon;
