import React, { Component } from 'react';
import Relay from 'react-relay';
import 'gsap';
import Vimeo from '@vimeo/player';

const propTypes = {
    link: React.PropTypes.string,
    width: React.PropTypes.number,
    height: React.PropTypes.number,
};

const defaultProps = {
    link: '',
    width: 1920,
    height: 1080,
};

class Video extends Component {
    constructor(props) {
        super(props);

        this.player = null;
        this.refPlayer = null;

        this.onResize = this.onResize.bind(this);
        this.createPlayer = this.createPlayer.bind(this);

        this.state = {
            ...this.getSize(),
        };
    }

    componentDidMount() {
        this.createPlayer();
        window.addEventListener('resize', this.onResize);
    }

    componentDidUpdate(prevProps) {
        const { link } = this.props;
        const linkChanged = link !== prevProps.link;
        if (linkChanged) {
            this.removePlayer();
            this.createPlayer();
        }
    }

    componentWillUnmount() {
        this.removePlayer();
        window.removeEventListener('resize', this.onResize);
    }

    onResize() {
        const size = this.getSize();
        this.setState({
            realWidth: size.realWidth,
            realHeight: size.realHeight,
        });
    }

    getSize() {
        const { width, height } = this.props;
        const containerWidth = Math.min(window.innerWidth, 1080);
        const containerHeight = window.innerHeight * 0.66;
        const ratio = Math.round((height / width) * 10000) / 10000;
        const reverseRatio = Math.round((width / height) * 10000) / 10000;

        const realWidth = Math.round(Math.min(containerHeight * reverseRatio, containerWidth));
        const realHeight = Math.round(realWidth * ratio);

        return {
            realWidth,
            realHeight,
        };
    }

    createPlayer() {
        if (this.player === null && this.refPlayer !== null) {
            this.player = new Vimeo(this.refPlayer, {
                portrait: false,
                byline: false,
                title: false,
                color: '7070db',
            });
            // this.player.setColor('3333cc');
            this.player.setColor('7070db');
            this.onResize();
        }
    }

    removePlayer() {
        if (this.player !== null) {
            this.player.pause();
            this.player.unload();
            this.player = null;
        }
    }

    render() {
        const { link } = this.props;
        const { realWidth, realHeight } = this.state;

        if (!link) return null;

        const style = {
            width: realWidth,
            height: realHeight,
        };
        const viewLink = `${link.replace('http://', 'https://')}?title=0&portrait=0&byline=0`;

        return (
            <div className="container-video">
                <div className="video-player" style={style}>
                    <div className="video-player-inner">
                        <iframe
                            src={viewLink}
                            frameBorder="0"
                            ref={(ref) => {
                                this.refPlayer = ref;
                            }}
                            allowFullScreen
                            width={realWidth}
                            height={realHeight}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

Video.propTypes = propTypes;
Video.defaultProps = defaultProps;

const VideoContainer = (props) => {
    const { video } = props;

    if (!video) {
        return null;
    }
    const { link, width, height, duration } = video;

    return (
        <div className="container-video-relay">
            <Video link={link} width={width} height={height} duration={duration} />
        </div>
    );
};

VideoContainer.propTypes = {
    video: React.PropTypes.shape({
        link: React.PropTypes.string,
        width: React.PropTypes.number,
        height: React.PropTypes.number,
        duration: React.PropTypes.number,
    }).isRequired,
};

Video.RelayComponent = Relay.createContainer(VideoContainer, {
    fragments: {
        video: () =>
            Relay.QL`
                fragment on Video {
                    id
                    link
                    width
                    height
                    duration
                }
            `,
    },
});

export default VideoContainer;
