import * as PIXI from 'pixi.js';
import SayneteComponents from './saynetes';

class Saynete {
    constructor(props = { width: 0, height: 0 }) {
        this.props = {
            name: 'Generic',
            segments: {},
            width: 200,
            height: 100,
            options: {},
            router: null,
            ...props,
        };
        this.saynete = null;
        this.container = new PIXI.Container();
        this.topContainer = new PIXI.Container();
        this.create();
        return this;
    }

    getTimeline(onComplete = null, options = {}) {
        return this.saynete.getTimeline(0, onComplete, options);
    }

    resize(props) {
        this.props = { ...this.props, ...props };
        const { width, height, mobile, options, router } = this.props;
        this.saynete.resize({ width, height, mobile, router, ...options });
    }

    create() {
        const { width, height, mobile, options, name, router } = this.props;

        this.saynete = new SayneteComponents[name]({ width, height, mobile, router, ...options });
        if (this.saynete.container) {
            this.container = this.saynete.container;
        }
        if (this.saynete.topContainer) {
            this.topContainer = this.saynete.topContainer;
        }
    }

    onTick() {}

    destroy() {
        this.saynete.destroy();
        this.container.destroy({
            children: true,
        });
    }
}

export default Saynete;
