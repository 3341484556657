import React, { Component } from 'react';
import { connect } from 'react-redux';
import withUrl from '../lib/withUrl';
import GridMenu from '../components/GridMenu';

const propTypes = {
    url: React.PropTypes.shape({
        route: React.PropTypes.func,
    }).isRequired,
    sections: React.PropTypes.shape({
        filter: React.PropTypes.func,
        toJS: React.PropTypes.func,
    }).isRequired,
    layout: React.PropTypes.string.isRequired,
    items: React.PropTypes.array.isRequired,
    onPreviewChange: React.PropTypes.func.isRequired,
};

const GridMenuContainer = (props) => {
    const { sections, url, layout, items } = props;
    return (
        <GridMenu
            url={url}
            sections={sections.toJS()}
            layout={layout}
            items={items}
            onPreviewChange={props.onPreviewChange}
        />
    );
};

GridMenuContainer.propTypes = propTypes;

export default withUrl(connect(state => ({
    sections: state.sections,
}))(GridMenuContainer));
