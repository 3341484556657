import React, { Component } from 'react';
import classNames from 'classnames';

const propTypes = {
    items: React.PropTypes.arrayOf(
        React.PropTypes.shape({
            id: React.PropTypes.string,
            title: React.PropTypes.string,
            subtitle: React.PropTypes.string,
            image: React.PropTypes.string,
            url: React.PropTypes.string,
        }),
    ),
    autoPlay: React.PropTypes.bool,
    duration: React.PropTypes.number,
    onChange: React.PropTypes.func,
    // onScan: React.PropTypes.func.isRequired,
    // changeLogoColor: React.PropTypes.func,
    // previewIdx: React.PropTypes.number,
    // previewItems: React.PropTypes.arrayOf(
    //     React.PropTypes.shape({
    //         id: React.PropTypes.string,
    //         title: React.PropTypes.string,
    //         subtitle: React.PropTypes.string,
    //         link: React.PropTypes.string,
    //         bubble: React.PropTypes.shape({
    //             snippet: React.PropTypes.shape({
    //                 picture_splash: React.PropTypes.shape({
    //                     url: React.PropTypes.string,
    //                 }),
    //             }),
    //         }),
    //     }),
    // ),
};

const defaultProps = {
    items: [],
    previewItems: [],
    previewIdx: null,
    autoPlay: true,
    duration: 7,
    onChange: null,
    changeLogoColor: null,
};

class Slideshow extends Component {
    constructor(props) {
        super(props);

        this.timeout = null;
        this.mounted = false;

        this.onClickPrevious = this.onClickPrevious.bind(this);
        this.onClickNext = this.onClickNext.bind(this);
        this.onAutoNext = this.onAutoNext.bind(this);

        this.cancelAutoPlay = this.cancelAutoPlay.bind(this);
        this.renderSlide = this.renderSlide.bind(this);

        this.state = {
            index: 0,
            playing: true,
        };
    }

    componentDidMount() {
        this.mounted = true;
        const { autoPlay, duration } = this.props;
        if (autoPlay) {
            setTimeout(() => {
                this.timeout = setInterval(this.onAutoNext, duration * 1000);
            }, duration * 1000);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
        this.cancelAutoPlay();
    }

    onAutoNext() {
        if (this.mounted) {
            this.onNext();
        }
    }

    onClickPrevious(e) {
        e.preventDefault();
        this.onPrevious();
        this.cancelAutoPlay();
    }

    onClickNext(e) {
        e.preventDefault();
        this.onNext();
        this.cancelAutoPlay();
    }

    onPrevious() {
        const { items, onChange } = this.props;
        const { index } = this.state;
        this.setState(
            {
                index: index <= 0 ? items.length - 1 : index - 1,
            },
            () => {
                if (onChange !== null) {
                    onChange(this.state.index);
                }
            },
        );
    }

    onNext() {
        const { items, onChange } = this.props;
        const { index } = this.state;
        this.setState(
            {
                index: index >= items.length - 1 ? 0 : index + 1,
            },
            () => {
                if (onChange !== null) {
                    onChange(this.state.index);
                }
            },
        );
    }

    cancelAutoPlay() {
        if (this.timeout !== null) {
            clearInterval(this.timeout);
        }
    }

    renderSlide(item, idx) {
        const { index } = this.state;
        const key = `background-${item.__dataID__}`; // eslint-disable-line
        const style = item.picture_splash
            ? { backgroundImage: `url('${item.picture_splash.url}'` }
            : { backgroundImage: "url('/img/backgrounds/dude.jpg')" };
        const { title, subtitle, description } = item;

        return (
            <div
                key={key}
                className={classNames({ 'slideshow-item': true, current: index === idx })}
            >
                <div className="slideshow-item-background" style={style} />
                {title || subtitle || description ? (
                    <a className="slideshow-item-caption" href={item.url} rel={item.title}>
                        <p>
                            {title}
                            {subtitle ? <span> / {subtitle}</span> : null}
                            {description ? <span> / {description}</span> : null}
                        </p>
                    </a>
                ) : null}
            </div>
        );
    }

    render() {
        const { items } = this.props;

        // Feature disabled
        // const parsedItems = previewItems.map((item) => {
        //     if (item.bubble !== null && item.bubble.snippet) {
        //         return item.bubble.snippet;
        //     }
        //     return null;
        // });

        // const previewExists =
        //     previewIdx !== null &&
        //     parsedItems[previewIdx] !== null &&
        //     parsedItems[previewIdx].picture_splash !== null;
        //
        // const renderItems = previewIdx !== null && previewExists ? parsedItems : items;
        // const renderIdx = previewIdx !== null && previewExists ? previewIdx : index;

        return (
            <div className="slideshow">
                <section className="slideshow-controls">
                    <div className="btn-group">
                        <button
                            className="btn btn-arrow btn-arrow-prev"
                            onClick={this.onClickPrevious}
                        />
                        <button
                            className="btn btn-arrow btn-arrow-next"
                            onClick={this.onClickNext}
                        />
                    </div>
                </section>
                <div className="slideshow-items">{items.map(this.renderSlide)}</div>
            </div>
        );
    }
}

Slideshow.propTypes = propTypes;
Slideshow.defaultProps = defaultProps;

export default Slideshow;
