import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { TimelineMax, Sine } from 'gsap';
import createDebug from 'debug';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import * as PIXI from 'pixi.js';
import MobileDetect from 'mobile-detect';

import { getWidth, getHeight } from '../lib/getSize';
import Overlay from './Overlay';
import Scene from './Scene';
import Meta from '../components/partials/Meta';
import Logo from '../components/partials/Logo';
import Player from './utils/player';

import {
    Next,
    Play,
    Previous,
    Progress,
    SceneMenu,
    NavMenu,
    BottomMenu,
    Title,
} from './layout/index';

import getCvData from './utils/getCvData';

const debug = createDebug('Layout');

const propTypes = {
    content: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    // slug: PropTypes.string,
    router: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
    sectionSlug: PropTypes.string,
    segmentSlug: PropTypes.string,
};

const defaultProps = {
    // slug: null,
    sectionSlug: null,
    segmentSlug: null,
};

let assetCount = 0;
let loadedCount = 0;

PIXI.loaders.Resource.setExtensionLoadType('gif', PIXI.loaders.Resource.LOAD_TYPE.XHR);
PIXI.loaders.Resource.setExtensionXhrType('gif', PIXI.loaders.Resource.XHR_RESPONSE_TYPE.BUFFER);

class Layout extends Component {
    constructor(props) {
        super(props);
        debug(props);

        this.timeline = null;
        this.toggleTimeline = null;
        this.tracks = {};
        this.md = new MobileDetect(window.navigator.userAgent);

        this.onClickNext = this.onClickNext.bind(this);
        this.onClickPrevious = this.onClickPrevious.bind(this);

        this.onResize = this.onResize.bind(this);
        this.onResize = debounce(this.onResize, 500, {
            leading: false,
            trailing: true,
        });

        this.onPlay = this.onPlay.bind(this);
        this.onPause = this.onPause.bind(this);
        this.onPauseWithoutMenu = this.onPauseWithoutMenu.bind(this);
        this.onSeek = this.onSeek.bind(this);

        this.onOpenMenu = this.onOpenMenu.bind(this);
        this.onToggleMenu = this.onToggleMenu.bind(this);
        this.onCloseMenu = this.onCloseMenu.bind(this);

        this.onOpenOverlay = this.onOpenOverlay.bind(this);
        this.onCloseOverlay = this.onCloseOverlay.bind(this);

        this.onSceneChange = this.onSceneChange.bind(this);
        this.setSceneTimeline = this.setSceneTimeline.bind(this);

        this.whenClearScene = this.whenClearScene.bind(this);

        this.onAssetsLoaded = this.onAssetsLoaded.bind(this);

        // PIXI.loaders.Resource.setExtensionLoadType('mp4', PIXI.loaders.Resource.LOAD_TYPE.VIDEO);

        this.state = {
            width: getWidth(),
            height: getHeight(),
            mobile: this.getMobile(),
            playing: false,
            showMenu: false,
            seek: 0,
            loading: 0,
            progress: 0,
            isTransitioning: false,

            hasPlayed: false,
            firstPlay: false,
            firstLoad: true,
            ready: true,
            finished: [],
            sceneContent: null,
            sceneTimeline: null,

            overlayOpen: false,
            overlayContent: null,
        };
    }

    componentDidMount() {
        this.timeline = new TimelineMax();
        this.onSceneChange();
        this.onResize();
        window.addEventListener('resize', this.onResize);
    }

    componentDidUpdate(prevProps, prevState) {
        const { sectionSlug, segmentSlug, location } = this.props;
        const { mobile, playing, width, height, hasPlayed } = this.state;
        const sizeChanged = prevState.width !== width || prevState.height !== height;

        const playingChanged = playing !== prevState.playing;
        const seekChanged = this.state.seek !== null && prevState.seek !== this.state.seek;

        const sectionChanged = sectionSlug !== prevProps.sectionSlug;
        const segmentChanged = segmentSlug !== prevProps.segmentSlug;

        // Toggle play / pause everywhere
        if (playingChanged) {
            const content = this.getContent();
            if (playing) {
                this.whenPlay(content.track);
                if (!hasPlayed) {
                    // TODO: change this
                    this.setState({
                        hasPlayed: true,
                    });
                }
            } else {
                this.whenPause(content.track);
            }
        }

        if (this.timeline !== null) {
            if (seekChanged) {
                const content = this.getContent();
                const seconds = this.getBaseDuration(this.state.seek);

                if (this.timeline !== null) {
                    this.timeline.seek(this.state.seek * this.timeline.duration());
                }

                if (this.tracks[content.track]) {
                    this.tracks[content.track].seek(seconds);
                }

                this.closeOverlay();
            }
        }

        if (sizeChanged) {
            // Do some resizes if needed
        }

        if (sectionChanged || segmentChanged) {
            // Toggle the big transitons
            console.log('SCENE CHANGED', mobile, location.pathname); // eslint-disable-line

            if (prevProps.location.pathname === '/voyages') {
                this.pauseTrack('/audio/intro-gap.mp3');
            }

            if (mobile) {
                if (location.pathname === '/voyages/menu') {
                    console.log('show menu'); // eslint-disable-line
                    // eslint-disable-next-line
                    this.setState({
                        paused: true,
                    });
                } else if (
                    prevProps.location.pathname === '/voyages/menu' &&
                    location.pathname !== '/voyages/menu'
                ) {
                    // eslint-disable-next-line
                    this.setState({
                        paused: false,
                    });
                }
            }

            if (mobile && prevProps.location.pathname === '/voyages/menu') {
                this.pauseTrack('/audio/intro-gap.mp3');
            }

            this.onSceneChange(prevProps.sectionSlug, prevProps.segmentSlug);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
        if (this.timeline !== null) {
            this.timeline.kill();
            this.timeline = null;
        }
    }

    onAssetsLoaded(resolve) {
        resolve();
        console.log('yeah loaded');
    }

    onClickNext(reverse = false) {
        const { sectionSlug, segmentSlug } = this.props;
        const { finished } = this.state;

        const nextSlug =
            reverse === true ? parseInt(segmentSlug, 10) - 1 : parseInt(segmentSlug, 10) + 1; // eslint-disable-line
        const content = this.getContent(sectionSlug, nextSlug);
        const next = nextSlug < 0 ? 0 : nextSlug;

        if (sectionSlug && Object.getOwnPropertyNames(content).length !== 0) {
            this.props.router.push(`/voyages/${sectionSlug}/${next}`);
        } else if (sectionSlug) {
            // const menu = this.props.content.intro.scene.menu.items;
            // let found = 0;
            // menu.forEach((m, i) => {
            //     if (m.slug === sectionSlug) {
            //         found = reverse ? i + 1 : i - 1;
            //     }
            // });
            // if (found > menu.length - 1) {
            //     found = 0;
            // }
            // if (found < 0) {
            //     found = menu.length - 1;
            // }
            // const item = menu[found] || {};
            this.props.router.push('/voyages/menu');
        } else if (sectionSlug === null) {
            // Only for the intro
            this.props.router.push('/voyages/menu');
        } else {
            this.props.router.push('/voyages/menu');
        }

        const segment = `${sectionSlug}-${segmentSlug}`;
        this.setState((prevState) => ({
            finished:
                finished.indexOf(segment) === -1 && segmentSlug !== null && sectionSlug != null
                    ? [...prevState.finished, segment]
                    : prevState.finished,
        }));
    }

    onClickPrevious() {
        this.onClickNext(true);
    }

    onResize() {
        this.setState({
            width: getWidth(),
            height: getHeight(),
            mobile: this.getMobile(),
        });
    }

    onPlay() {
        const { isTransitioning } = this.state;
        if (this.timeline !== null && !isTransitioning) {
            this.setState({
                playing: true,
                showMenu: false,
                skipTapeSound: false,
            });
        }
    }

    onPause() {
        const { isTransitioning } = this.state;
        debug('onPause');
        if (this.timeline !== null && !isTransitioning) {
            this.setState({
                playing: false,
                showMenu: true,
                skipTapeSound: false,
            });
        }
    }

    onPauseWithoutMenu() {
        const { isTransitioning } = this.state;
        if (this.timeline !== null && !isTransitioning) {
            this.setState({
                playing: false,
                skipTapeSound: false,
            });
        }
    }

    onToggleMenu() {
        debug('onToggle');
        const nextState = { showMenu: !this.state.showMenu };
        if (this.props.sectionSlug === null) {
            nextState.playing = false;
        }
        this.setState(nextState, () => {
            if (this.state.showMenu === false) {
                this.onPlay();
            }
        });
    }

    onOpenMenu() {
        debug('onOpen');
        this.setState({
            showMenu: true,
        });
    }

    onCloseMenu() {
        debug('onClose');
        this.setState(
            {
                showMenu: false,
            },
            this.onPlay,
        );
    }

    onSeek(pct) {
        if (this.timeline !== null) {
            this.setState(
                {
                    seek: pct,
                },
                () => {
                    this.setState({
                        playing: true,
                        showMenu: false,
                    });
                },
            );
        }
    }

    onOpenOverlay(key) {
        const section = this.getSection();
        this.setState(
            {
                overlayOpen: true,
                overlayContent: section.overlays[key],
            },
            () => {
                this.onPauseWithoutMenu();
            },
        );
    }

    onCloseOverlay() {
        this.setState(
            {
                overlayOpen: false,
            },
            () => {
                this.onPlay();
            },
        );
    }

    onSceneChange(oldSection, oldSegment) {
        const { isTransitioning } = this.state;
        if (isTransitioning) return; // No change for you

        const content = this.getContent();
        const oldContent = this.getContent(oldSection, oldSegment);

        this.setState(
            {
                isTransitioning: true,
                loading: 0,
                progress: 0,
                ready: false,
                playing: false,
                showMenu: false,
            },
            () => {
                this.onStart(content, oldContent);
            },
        );
    }

    onStart(content, oldContent) {
        const { mobile, firstPlay } = this.state;
        return this.whenAnimateOut(oldContent)
            .then(() => this.whenClearScene())
            .then(() =>
                Promise.all([
                    this.whenLoadAssets(content),
                    this.whenLoadTrack(content, oldContent),
                ]),
            )
            .then(() => {
                debug('Starting new scene baby');
                // Add animate in here
                this.setState({
                    ready: true,
                    sceneContent: content,
                });
                return this.whenAnimateIn(content);
            })
            .then(() => {
                debug('Done animating in baby');
                if (this.timeline !== null) {
                    this.timeline.kill();
                }
                this.timeline = this.getTimeline();
                this.timeline.add(this.state.sceneTimeline, 0);
                debug('Done transitioning baby');

                this.setState({
                    isTransitioning: false,
                    playing: firstPlay || !mobile, // Will now start playing again
                    firstPlay: true,
                });
            })
            .catch((err) => {
                debug('Failed to change scene baby', err);
                this.setState({
                    track: null,
                    isTransitioning: false,
                    loading: 0,
                    ready: false,
                    error: err,
                });
            });
    }

    getMobile() {
        return (
            !!this.md.phone() ||
            !!this.md.tablet() ||
            window.innerHeight < 650 ||
            window.innerWidth < 768
        );
    }

    getContent(oldSection = null, oldSegment = null) {
        const { content, sectionSlug, segmentSlug } = this.props;
        if (oldSection !== null && oldSegment !== null) {
            debug('GET OLD CONTENT SEGMENT', get(content, `${oldSection}.items.${oldSegment}`, {}));
            return get(content, `${oldSection}.items.${oldSegment}`, {});
        }
        if (oldSection !== null) {
            debug('GET OLD CONTENT SCENE', get(content, `${oldSection}.scene`, {}));
            return get(content, `${oldSection}.scene`, {});
        }
        if (sectionSlug !== null && segmentSlug !== null) {
            debug('GET CONTENT SEGMENT', get(content, `${sectionSlug}.items.${segmentSlug}`, {}));
            return get(content, `${sectionSlug}.items.${segmentSlug}`, {});
        }
        if (sectionSlug !== null) {
            debug('GET CONTENT SCENE', get(content, `${sectionSlug}.scene`, {}));
            return get(content, `${sectionSlug}.scene`, {});
        }
        debug('GET CONTENT INTRO', get(content, 'intro.scene', {}));
        return get(content, 'intro.scene', {});
    }

    getSection(oldSection = null) {
        const { content, sectionSlug } = this.props;

        if (oldSection !== null) {
            return get(content, `${oldSection}`, {});
        }
        if (sectionSlug !== null) {
            return get(content, `${sectionSlug}`, {});
        }
        return get(content, 'intro', {});
    }

    getBaseDuration(pct) {
        return this.timeline.duration() * pct;
    }

    getTimeline() {
        const timeline = new TimelineMax({
            paused: true,
            onUpdateParams: ['{self}'],
            onStart: () => {},
            onUpdate: (tl) => {
                if (tl.progress().toFixed(2) !== this.state.progress) {
                    this.setState({
                        progress: tl.progress().toFixed(2),
                    });
                }
            },
            onComplete: () => {
                const { sectionSlug, segmentSlug } = this.props;
                const { finished } = this.state;
                const segment = `${sectionSlug}-${segmentSlug}`;

                this.setState(
                    (prevState) => ({
                        finished:
                            finished.indexOf(segment) === -1 &&
                            segmentSlug !== null &&
                            sectionSlug != null
                                ? [...prevState.finished, segment]
                                : prevState.finished,
                        progress: 0,
                        playing: false,
                        skipTapeSound: true,
                    }),
                    () => {
                        const nextSlug = parseInt(segmentSlug, 10) + 1;
                        const content = this.getContent(sectionSlug, nextSlug);
                        if (
                            sectionSlug &&
                            nextSlug &&
                            Object.getOwnPropertyNames(content).length !== 0
                        ) {
                            this.props.router.push(`/voyages/${sectionSlug}/${nextSlug}`);
                        } else if (sectionSlug) {
                            this.props.router.push('/voyages/menu'); // always go to menu
                        } else {
                            this.props.router.push('/voyages/menu'); // end of intro
                        }
                    },
                );
            },
        });

        return timeline;
    }

    setSceneTimeline(sceneTimeline) {
        this.setState({
            sceneTimeline,
        });
    }

    closeOverlay() {
        this.setState({
            overlayOpen: false,
        });
    }

    whenLoadAssets(content) {
        return new Promise((resolve) => {
            const generic = get(this.props.content, 'all.generic', {}); // all the basic content so yr sure you can animate
            const sceneAssets = Object.keys(content.assets || {}).map((key) => content.assets[key]);
            const rawAssets = Object.keys(content.rawAssets || {}).map(
                (key) => content.rawAssets[key],
            );
            const cueAssets = Object.keys(content.cues || {}).map((key) => content.cues[key]);

            const globalAssets = [];
            Object.keys(generic || {}).forEach((key) =>
                generic[key].items.forEach((it) => {
                    globalAssets.push(it);
                }),
            );

            const chosen = sceneAssets.concat(cueAssets).concat(globalAssets).concat(rawAssets);
            // .filter(({ type = null } = {}) => type !== 'video');

            const assets = [];

            chosen.forEach((item) => {
                if (typeof PIXI.loader.resources[item.src] === 'undefined') {
                    // No double loading ever
                    const found = assets.filter((name) => name === item.src);
                    if (found.length === 0) {
                        if (item.type === 'image') {
                            assets.push(getCvData(item.src)); // Added data from AI
                        }
                        assets.push(item.src);
                    }
                    const altFound = assets.filter((name) => name === item.altSrc);
                    if (altFound.length === 0 && item.altSrc) {
                        assets.push(item.altSrc);
                    }
                }
            });

            // assets = assets.filter(({ type = null } = {}) => type !== 'video');

            console.log('hummm', assets, chosen); // eslint-disable-line

            if (assets.length > 0) {
                PIXI.loader.add(assets).load(() => resolve());

                loadedCount = 0;
                assetCount = assets.length;
                if (this.state.firstLoad) {
                    this.setState(
                        {
                            firstLoad: false,
                        },
                        () => {
                            PIXI.loader.onProgress.add(() => {
                                loadedCount += 1;
                                const x = loadedCount / assetCount;
                                const rounded = Math.ceil(x * 100);
                                if (this.state.loading !== rounded) {
                                    this.setState({
                                        loading: rounded,
                                    });
                                }
                            });
                        },
                    );
                }

                PIXI.loader.onComplete.add(() => {
                    console.log('yo it is done');
                    debug('assets loaded!', PIXI.loader.resources);
                    resolve();
                });
            } else {
                this.setState(
                    {
                        loading: 100,
                    },
                    () => {
                        resolve();
                    },
                );
            }
        });
    }

    whenLoadTrack(content, oldContent) {
        return new Promise((resolve) => {
            if (oldContent.track && this.tracks[oldContent.track]) {
                this.tracks[oldContent.track].seek(0);
                this.tracks[oldContent.track].pause();
            }

            if (content.track && !this.tracks[content.track]) {
                this.tracks[content.track] = new Player({
                    src: content.track,
                    onload: () => {
                        resolve();
                    },
                    onloaderror: () => {
                        debug('failed loading track', content.track);
                        resolve();
                    },
                });
            } else {
                resolve();
            }
        });
    }

    // eslint-disable-next-line
    whenAnimateOut(content) {
        return new Promise((resolve, reject) => {
            Promise.all([])
                .then(() => {
                    debug('Done animating out');
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    whenClearScene() {
        return new Promise((resolve) => {
            if (this.refScene !== null) {
                this.refScene.onClearScene(resolve);
            } else {
                resolve();
            }
        });
    }

    // eslint-disable-next-line
    whenAnimateIn(content) {
        return new Promise((resolve, reject) => {
            Promise.all([])
                .then(() => {
                    debug('Done animating in', this);
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    whenPlay(trackName) {
        return new Promise((resolve) => {
            const { skipTapeSound } = this.state;
            const timeline = this.timeline || null;
            const player = this.tracks[trackName] || null;

            debug('toggleTimeline starts', trackName, timeline.duration(), timeline.time());

            if (this.toggleTimeline !== null) {
                this.toggleTimeline.kill();
                this.toggleTimeline = null;
            }

            if (timeline.duration() === timeline.time() || timeline.time() === 0) {
                timeline.seek(0);
            }

            // Base rate
            const fade = { rate: 0 };

            if (player !== null) {
                player.rate(skipTapeSound ? 1 : 0);
                player.play(this.timeline.time() || 0);
            }

            if (timeline !== null) {
                timeline.timeScale(skipTapeSound ? 1 : 0);
                debug('Timeline play', this.timeline);
                timeline.play();
            }

            this.toggleTimeline = new TimelineMax({
                paused: true,
                onComplete: () => {
                    if (player !== null) {
                        player.rate(1);
                    }
                    if (timeline !== null) {
                        timeline.timeScale(1);
                    }
                    debug('toggleTimeline ends, now playing');
                    return resolve();
                },
            });

            if (!skipTapeSound) {
                this.toggleTimeline.to(fade, 3, {
                    rate: 1,
                    ease: Sine.easeOut,
                    onUpdate: () => {
                        if (player !== null) {
                            player.rate(fade.rate);
                        }
                        if (timeline !== null) {
                            timeline.timeScale(fade.rate);
                        }
                    },
                });
            }

            this.toggleTimeline.play();
        });
    }

    whenPause(trackName) {
        return new Promise((resolve) => {
            const timeline = this.timeline || null;
            const player = this.tracks[trackName] || null;

            if (this.toggleTimeline !== null) {
                this.toggleTimeline.kill();
                this.toggleTimeline = null;
            }
            // Base rate
            const fade = { rate: 1 };

            if (player !== null) {
                player.rate(1);
            }

            if (timeline !== null) {
                timeline.timeScale(1);
            }

            this.toggleTimeline = new TimelineMax({
                paused: true,
                onComplete: () => {
                    if (player !== null) {
                        player.rate(0);
                        player.pause();
                    }
                    if (timeline !== null) {
                        timeline.timeScale(0);
                        timeline.pause();
                    }
                    resolve();
                },
            });

            this.toggleTimeline.to(fade, 3, {
                rate: 0,
                ease: Sine.easeOut,
                onUpdate: () => {
                    if (player !== null) {
                        player.rate(fade.rate);
                    }
                    if (timeline !== null) {
                        timeline.timeScale(fade.rate);
                    }
                },
            });

            this.toggleTimeline.play();
        });
    }

    pauseTrack(trackName) {
        const player = this.tracks[trackName] || null;
        if (player) {
            player.pause();
        }
    }

    renderMenu() {
        const { content, sectionSlug, segmentSlug } = this.props;
        const { width, height, mobile, showMenu, finished } = this.state;

        // if (sectionSlug === 'menu') {
        //     mobileMenuHidden = false;
        // const mobileMenuHidden = sectionSlug === 'menu'
        // console.log('hidden', sectionSlug === 'menu' ? false : !showMenu);

        // eslint-disable-next-line
        return mobile ? (
            <NavMenu
                width={width}
                height={height}
                mobile={mobile}
                label={content[sectionSlug] ? content[sectionSlug].label : 'Voyages'}
                segmentSlug={segmentSlug}
                sectionSlug={sectionSlug}
                group={content[sectionSlug]}
                finished={finished}
                hidden={sectionSlug === 'menu' ? false : !showMenu}
                onToggleMenu={this.onCloseMenu}
            />
        ) : sectionSlug !== 'menu' ? (
            <SceneMenu
                width={width}
                height={height}
                mobile={mobile}
                label={content[sectionSlug] ? content[sectionSlug].label : null}
                segmentSlug={segmentSlug}
                sectionSlug={sectionSlug}
                group={content[sectionSlug]}
                finished={finished}
                hidden={!showMenu}
                onToggleMenu={this.onToggleMenu}
            />
        ) : null;
    }

    render() {
        const { content, sectionSlug, segmentSlug, router } = this.props;
        const {
            ready,
            isTransitioning,
            width,
            height,
            mobile,
            loading,
            progress,
            playing,
            hasPlayed,
            showMenu,
            duration,
            durations,
            sceneContent,
            overlayOpen,
            overlayContent,
        } = this.state;

        const style = {
            width,
            height,
        };

        const sceneTitle =
            segmentSlug !== null && ready && sceneContent ? sceneContent.label : null;

        console.log('playing', playing, 'mobile', mobile, 'hasPlayed', hasPlayed);
        console.log('progress vs loading', progress, loading);

        // const sectionTitle =
        // segmentSlug !== null && content[sectionSlug] ? content[sectionSlug].label : null;
        // debug(sceneContent, sectionSlug, segmentSlug, playing, !isTransitioning, ready);

        return (
            <section className="layout" style={style}>
                <Meta
                    bubble={{
                        snippet: {
                            title: 'Voyages',
                        },
                    }}
                />
                <Logo
                    home
                    subtitleFirstLetter="V"
                    subtitleRemainder="oyages"
                    redArrow
                    color="#F00"
                    visible={showMenu}
                    href="/"
                />
                <Title
                    sectionSlug={sectionSlug}
                    label={!mobile || playing === true ? sceneTitle : ''}
                    height={height}
                    mobile={mobile}
                    delay={1}
                />
                {content[sectionSlug] ? this.renderMenu() : null}
                <BottomMenu
                    slug={sectionSlug}
                    items={content}
                    hidden={!showMenu}
                    invisible={
                        !sectionSlug ||
                        (content[sectionSlug] && content[sectionSlug].showBottomMenu === false)
                    }
                    mobile={mobile}
                    onPause={this.onPause}
                    onToggleMenu={this.onToggleMenu}
                />
                <Play
                    loading={loading}
                    playing={playing}
                    hidden={sectionSlug === 'menu'}
                    onPlay={this.onPlay}
                    onPause={this.onPause}
                    centered={mobile && !hasPlayed && loading > 99}
                />
                <Next
                    onClick={this.onClickNext}
                    visible={
                        (sectionSlug !== null &&
                            segmentSlug !== null &&
                            playing === true &&
                            isTransitioning === false &&
                            ready === true) ||
                        sectionSlug === null
                    }
                />
                <Previous
                    onClick={this.onClickPrevious}
                    visible={
                        sectionSlug !== null &&
                        segmentSlug !== null &&
                        playing === true &&
                        isTransitioning === false &&
                        ready === true
                    }
                />
                <Progress
                    size={width + height}
                    width={width}
                    height={8}
                    loading={loading}
                    progress={progress}
                    duration={duration}
                    durations={durations}
                    playing={playing}
                    seek={this.onSeek}
                />
                <Scene
                    ref={(ref) => {
                        this.refScene = ref;
                    }}
                    router={router}
                    ready={ready}
                    loading={loading < 100}
                    width={width}
                    height={height - 6}
                    mobile={mobile}
                    scene={sceneContent}
                    segments={sceneContent ? sceneContent.cues : null}
                    zoomedIn={segmentSlug !== null}
                    onTimelineReady={this.setSceneTimeline}
                    onOpenOverlay={this.onOpenOverlay}
                    onOpenMenu={this.onOpenMenu}
                />
                <Overlay
                    width={width}
                    height={height}
                    open={overlayOpen}
                    content={overlayContent}
                    onClose={this.onCloseOverlay}
                />
            </section>
        );
    }
}

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

export default withRouter(Layout);
