import React from 'react';
import classNames from 'classnames';
import Title from '../partials/Title';
import BubblesList from '../../containers/BubblesList';

const propTypes = {
    name: React.PropTypes.string.isRequired,
    title: React.PropTypes.string,
    itemSize: React.PropTypes.string,
    sectionSize: React.PropTypes.string,
    pageSize: React.PropTypes.number,
    loadingSize: React.PropTypes.number,
};

const defaultProps = {
    title: null,
    itemSize: 'large',
    sectionSize: 'large',
    pageSize: 12,
    loadingSize: 2,
};

const BubblesListSection = (props) => {
    const { name, title, itemSize, pageSize, sectionSize, loadingSize } = props;

    const sectionClassNames = classNames({
        'page-section': true,
        'page-section-list': true,
        'list-items-small': sectionSize === 'small',
    });

    return (
        <BubblesList
            {...props}
            name={name}
            itemSize={itemSize}
            pageSize={pageSize}
            loadingSize={loadingSize}
            sectionSize={sectionSize}
            decorator={(list, items) => {
                if (!items || items.length < 1) {
                    return null;
                }
                return (
                    <section className={sectionClassNames}>
                        {title ? <Title text={title} className="section-title" subtitle /> : null}
                        {list}
                    </section>
                );
            }}
        />
    );
};

BubblesListSection.propTypes = propTypes;
BubblesListSection.defaultProps = defaultProps;

export default BubblesListSection;
