import React from 'react';
import UList from '../partials/UList';

const propTypes = {
    type: React.PropTypes.string,
    items: React.PropTypes.array,
    colName: React.PropTypes.string,
};

const defaultProps = {
    type: 'default',
    items: [],
    colName: 'credits',
};

const Column = (props) => {
    const { colName } = props;
    const ulists = props.items.map((item, k) => {
        const kid = k + 1;
        const key = `ulist-${props.type}-${kid}-${colName}`;
        return <UList title={item.title} items={item.items} key={key} listName={key} />;
    });
    return (
        <div className="col">
            {ulists}
        </div>
    );
};

Column.propTypes = propTypes;
Column.defaultProps = defaultProps;

export default Column;
