import React, { Component } from 'react';
import { Router, browserHistory, createMemoryHistory, applyRouterMiddleware } from 'react-router';
import { useScroll } from 'react-router-scroll';
import { Provider } from 'react-redux';
import Immutable from 'immutable';
import { syncHistoryWithStore } from 'react-router-redux';
import Cookies from 'js-cookie';

import configureStore from '../store/configureStore';
import DevTools from './DevTools';

import '../../scss/main.scss';

const propTypes = {
    bubble: React.PropTypes.object, // eslint-disable-line react/forbid-prop-types
    url: React.PropTypes.string.isRequired,
    routes: React.PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    routerRoutes: React.PropTypes.object, // eslint-disable-line react/forbid-prop-types
    sections: React.PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    subsections: React.PropTypes.array, // eslint-disable-line react/forbid-prop-types
    renderProps: React.PropTypes.object, // eslint-disable-line react/forbid-prop-types

    news: React.PropTypes.array, // eslint-disable-line react/forbid-prop-types
    menu: React.PropTypes.object, // eslint-disable-line react/forbid-prop-types
    backgrounds: React.PropTypes.array, // eslint-disable-line react/forbid-prop-types
    isPrerender: React.PropTypes.bool,
    popup: React.PropTypes.object, // eslint-disable-line react/forbid-prop-types
    popupCookies: React.PropTypes.bool,
    newsletter: React.PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

const defaultProps = {
    bubble: null,
    renderProps: null,
    routerRoutes: null,
    news: [],
    menu: {},
    backgrounds: [],
    subsections: [],
    isPrerender: false,
    popup: null,
    popupCookies: null,
    newsletter: null,
};

class Root extends Component {
    constructor(props) {
        super(props);

        const routerHistory =
            typeof window === 'undefined'
                ? createMemoryHistory({
                      initialEntries: [props.url],
                  })
                : browserHistory;

        const bubbles = {};
        if (props.bubble) {
            const url = props.bubble.snippet.link
                .replace(/https?:\/\/[^/]+\//, '')
                .replace(/^react\//, '');
            bubbles[url] = props.bubble;
        }

        const store = configureStore({
            routes: new Immutable.Map(props.routes),
            sections: new Immutable.List(props.sections),
            subsections: new Immutable.List(props.subsections),
            bubbles: new Immutable.Map(bubbles),
            home: new Immutable.Map({
                news: props.news || [],
                menu: props.menu || {},
                backgrounds: props.backgrounds || [],
            }),
            layout: new Immutable.Map({
                sidebarClosed: false,
                headerCollapsed: false,
                scrollY: 0,
                isPrerender: props.isPrerender,
                popup: props.popup || null,
                popupCookies:
                    props.popupCookies ||
                    (Cookies.get('popup_cookies') || null) === 'true' ||
                    (Cookies.get('popup_cookies') || null) === null,
                newsletter: props.newsletter || null,
            }),
        });

        const history = syncHistoryWithStore(routerHistory, store);

        this.state = {
            store,
            history,
        };
    }

    render() {
        const { store, history } = this.state;
        const { routerRoutes, renderProps } = this.props;
        const routerProps = {
            render: applyRouterMiddleware(useScroll()),
        };
        let router;

        if (typeof window === 'undefined' && renderProps) {
            router = <Router {...renderProps} {...routerProps} />;
        } else {
            router = <Router history={history} routes={routerRoutes} {...routerProps} />;
        }

        if (process.env.NODE_ENV !== 'production') {
            return (
                <Provider store={store}>
                    <div>
                        {router}
                        <DevTools />
                    </div>
                </Provider>
            );
        }

        return <Provider store={store}>{router}</Provider>;
    }
}

Root.propTypes = propTypes;
Root.defaultProps = defaultProps;

export default Root;
