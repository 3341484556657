import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RootContainer } from 'react-relay';

import BubbleComponent from '../../components/pages/Bubble';
import BubbleRoute from '../../routes/Bubble';

const propTypes = {
    bubbles: React.PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    routeParams: React.PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

const defaultProps = {};

class BubblePageContainer extends Component {
    constructor(props) {
        super(props);
        const { routeParams, bubbles } = this.props;

        const urlParts = [routeParams.section_slug];
        if (routeParams.subsection_slug) {
            urlParts.push(routeParams.subsection_slug);
        }
        urlParts.push(routeParams.content_slug);
        const url = urlParts.join('/');
        const bubble = bubbles.get(url);
        this.state = {
            bubble,
            url,
        };
    }

    render() {
        const { bubble } = this.state;
        if (bubble) {
            return <BubbleComponent bubble={this.state.bubble} />;
        }
        return (
            <RootContainer
                Component={BubbleComponent.RelayComponent}
                route={
                    new BubbleRoute({
                        url: this.state.url,
                    })
                }
            />
        );
    }
}

BubblePageContainer.propTypes = propTypes;
BubblePageContainer.defaultProps = defaultProps;

export default connect((state) => ({
    bubbles: state.bubbles,
}))(BubblePageContainer);
