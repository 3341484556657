import React, { Component } from 'react';
import Relay from 'react-relay';
import { TimelineMax, TweenMax } from 'gsap';
import classNames from 'classnames';

import Link from '../partials/Link';

const propTypes = {
    bubble: React.PropTypes.shape({
        type: React.PropTypes.string,
        snippet: React.PropTypes.shape({
            link: React.PropTypes.string,
            title: React.PropTypes.string,
            subtitle: React.PropTypes.string,
            picture: React.PropTypes.shape({
                url: React.PropTypes.string,
            }),
        }),
    }).isRequired,
    view: React.PropTypes.string,
    itemSize: React.PropTypes.string.isRequired,
};

const defaultProps = {
    view: 'grid',
};

class BubbleItem extends Component {
    renderGrid() {
        const { bubble, itemSize } = this.props;

        const { snippet } = bubble;

        const title = snippet.title || 'Titre';
        const subtitle = snippet.datation
            ? `${snippet.subtitle} - ${snippet.datation}`
            : snippet.subtitle || 'Date ou type';

        const code = snippet.code || 'NTE';
        const hasImage = itemSize !== 'small';

        const imageStyle = snippet.picture
            ? {
                backgroundImage: `url("${snippet.picture.url}")`,
            }
            : { backgroundImage: "url('/img/backgrounds/whitenoise.jpg')" };

        const image = hasImage ? (
            <div className="image-container" style={imageStyle}>
                <div className="overlay-play-button" />
            </div>
        ) : null;

        return (
            <div>
                <div className="caption-container">
                    <div className="caption">
                        <span className="title">
                            <strong className="overflow">{title}</strong>
                            <strong className="small-subtitle">{subtitle}</strong>
                        </span>
                        <strong className="code">{code}</strong>
                    </div>
                </div>
                {image}
                {hasImage ? (
                    <div className="subtitle-container">
                        <div className="subtitle">{subtitle}</div>
                    </div>
                ) : null}
            </div>
        );
    }

    renderListWithoutPhoto() {
        const { bubble } = this.props;
        const { snippet } = bubble;
        const subtitle = snippet.subtitle || 'Rôle inconnu';

        return (
            <div className="caption-container">
                <div className="artist">
                    <span className="title">
                        <strong className="overflow">{snippet.title}</strong>
                        <strong className="small-subtitle">{subtitle}</strong>
                    </span>
                </div>
            </div>
        );
    }

    renderListWithPhoto() {
        const { bubble } = this.props;
        const { snippet } = bubble;

        const foregroundStyle = snippet.pictureBig
            ? {
                backgroundImage: `url("${snippet.pictureBig.url}")`,
                backgroundSize: 'contain',
            }
            : {};

        const backgroundStyle = snippet.pictureBig
            ? {
                backgroundImage: `url("${snippet.pictureBig.url}")`,
                backgroundSize: 'contain',
            }
            : {};

        const image = (
            <div className="container-picture">
                <div
                    className="image-container container-picture-background"
                    style={backgroundStyle}
                />
                <div
                    className="image-container container-picture-foreground"
                    style={foregroundStyle}
                />
                <div className="overlay-play-button" />
            </div>
        );

        return (
            <div className="caption-container">
                <div className="left">
                    {image}
                    <div className="inside">
                        <span className="title">
                            <strong className="overflow">{snippet.title}</strong>
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { bubble, view, itemSize } = this.props;
        const { type, snippet } = bubble;
        const hasImage = itemSize !== 'small';

        const itemClassNames = classNames({
            'list-item': true,
            [`list-item-${type}`]: true,
            'list-item-vertical': view === 'list',
            'list-item-grid': view === 'grid',
            'list-item-small': !hasImage,
            'list-item-large': hasImage,
        });

        const link = (snippet.link || '').replace(/https?:\/\/[^/]+/, '');
        const list = hasImage ? this.renderListWithPhoto() : this.renderListWithoutPhoto();

        return (
            <div
                className={itemClassNames}
                ref={(ref) => {
                    this.refItem = ref;
                }}
            >
                <div className="shadow">
                    <Link to={link}>{view === 'grid' ? this.renderGrid() : list}</Link>
                </div>
            </div>
        );
    }
}

BubbleItem.propTypes = propTypes;
BubbleItem.defaultProps = defaultProps;

BubbleItem.RelayComponent = Relay.createContainer(BubbleItem, {
    initialVariables: {
        pictureSize: 'thumb',
        pictureSizeBig: 'big',
    },

    fragments: {
        bubble: () => Relay.QL`
            fragment on Bubble {
                id
                type
                snippet {
                    title
                    subtitle
                    datation
                    code
                    link
                    picture: picture(filter: $pictureSize) {
                        url
                    }
                    pictureBig: picture(filter: $pictureSizeBig) {
                        url
                    }
                }
            }
        `,
    },
});

export default BubbleItem;
