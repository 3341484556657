import { TimelineLite, TweenMax, RoughEase, Power0, Sine } from 'gsap';
import * as PIXI from 'pixi.js';
import merge from 'lodash/merge';
// import { Howl } from 'howler';
// import Pizzicato from 'pizzicato';
import BeepSound from '../../sounds/beep';
import GlowFilter from '../filters/glow/glow';
import { getSizeWithinMax } from '../../utils/size';

class ToggleHand {
    constructor(options = { scene: null, width: 0, height: 0 }) {
        this.setOptions(options);

        this.hand = null;
        this.sprite = null;
        this.timeline = null;
        this.filter = null;

        this.sound = new BeepSound();

        this.ease = RoughEase.ease.config({
            template: Power0.easeNone,
            strength: 1,
            points: 40,
            taper: 'none',
            randomize: true,
            clamp: false,
        });

        this.filter = new GlowFilter(10, 2, 1, 0xff0000, 0.1);
        this.container = new PIXI.Container();

        this.toggle = this.toggle.bind(this);
        this.resize = this.resize.bind(this);
        this.onHoverIn = this.onHoverIn.bind(this);
        this.onHoverOut = this.onHoverOut.bind(this);

        this.create();
        this.createHand();

        console.log(this.options);

        return this;
    }

    setOptions(options = {}) {
        const opts = merge(
            {
                overlayName: 'tv',
                textures: {
                    carre: '/img/textures/main.png',
                    tv: '/img/textures/main.png',
                    photos: '/img/textures/main.png',
                    mainVerticale: '/img/textures/main-verticale.png',
                    mainHorizontale: '/img/textures/main-horizontale.png',
                    mainPhoto: '/img/textures/main-photo.png',
                },
                toggle: null,
                time: 1,
                transitionTime: 2,
                wait: 5,
                overlayLabel: '',
                style: {
                    fontFamily: 'VT323',
                    fontSize: 48,
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fill: '#000000',
                    wordWrap: true,
                    wordWrapWidth: 260,
                    stroke: '#ffcc33',
                    strokeThickness: 12,
                },
            },
            options,
        );
        this.options = opts;
    }

    onHoverIn() {
        TweenMax.to(this.filter.uniforms, 0.3, {
            outerStrength: 10,
            innerStrength: 2,
        });
    }

    onHoverOut() {
        TweenMax.to(this.filter.uniforms, 0.3, {
            outerStrength: 0,
            innerStrength: 0,
        });
    }

    create() {
        const { width, height, overlayName, mobile, textures } = this.options;
        const distanceX = mobile ? 20 : 200;
        const distanceY = mobile ? 14 : 140;

        this.randomDistanceW = Math.floor(Math.random() * distanceX);
        this.randomDistanceH = Math.floor(Math.random() * distanceY);
        this.randomSignW = Math.random() > 0.5 ? -1 : 1;
        this.randomSignH = Math.random() > 0.5 ? -1 : 1;

        const resource = textures[overlayName];
        const sprite = new PIXI.Sprite(PIXI.loader.resources[resource].texture);
        sprite.anchor.set(0.5, 0.5);

        // prettier-ignore
        sprite.x = (width / 2) + (this.randomDistanceW * this.randomSignW);

        // prettier-ignore
        sprite.y = (height / 2) + (this.randomDistanceH * this.randomSignH);
        sprite.width = 300;
        sprite.height = 300;
        sprite.alpha = 0;

        // prettier-ignore
        const ratio = (this.randomSignW * (Math.random() / 10));
        sprite.scale.x = mobile ? 0.2 : 0.4 + ratio;
        sprite.scale.y = mobile ? 0.2 : 0.4 + ratio;

        sprite.interactive = false;
        sprite.buttonMode = false;

        this.container.addChild(sprite);
        this.container.visible = false;
        this.sprite = sprite;

        const style = new PIXI.TextStyle(this.options.style);
        const text = new PIXI.Text(this.options.overlayLabel, style);
        text.x = sprite.x - 130;
        text.y = sprite.y - 130;
        text.alpha = 0;

        this.text = text;
    }

    createHand() {
        const { width, height, textures, overlayName, mobile } = this.options;

        this.randomSign = Math.random() > 0.5;

        const resource =
            overlayName === 'tv' // eslint-disable-line
                ? this.randomSign
                    ? textures.mainVerticale
                    : textures.mainHorizontale
                : textures.mainPhoto;
        const hand = new PIXI.Sprite(PIXI.loader.resources[resource].texture);
        hand.anchor.set(1, 1);

        // prettier-ignore
        // hand.x = (width / 2) + (this.randomDistanceW * this.randomSignW);

        // prettier-ignore
        // hand.y = (height / 2) + (this.randomDistanceH * this.randomSignH);

        // hand.width = 300;
        // hand.height = 300;

        hand.alpha = 0;
        hand.x =
            resource === textures.mainHorizontale // eslint-disable-line
                ? width
                : resource === textures.mainVerticale
                ? width / 2
                : width / 3;
        hand.y = height;
        hand.scale.x = mobile ? 0.2 : 0.35;
        hand.scale.y = mobile ? 0.2 : 0.35;

        hand.interactive = false;
        hand.buttonMode = false;
        hand.filters = [this.filter];

        this.container.addChild(hand);
        this.container.visible = false;
        this.hand = hand;
    }

    toggle() {
        this.options.toggle();
    }

    resize() {
        if (this.sprite !== null && this.container !== null) {
            // this.coords = this.getCoords();

            TweenMax.set(this.sprite, {
                // prettier-ignore
                x: (this.options.width / 2) + (this.randomDistanceW * this.randomSignW),
                // prettier-ignore
                y: (this.options.height / 2) + (this.randomDistanceH * this.randomSignH),
            });
        }
    }

    getTimeline(start = 0, onComplete = null) {
        let time = start;

        const timeline = new TimelineLite({
            onStart: () => {
                this.container.visible = true;
                if (this.options.overlay) {
                    this.sprite.interactive = true;
                    this.sprite.buttonMode = true;
                    this.sprite.on('pointerdown', this.toggle);
                    // this.sprite.on('mouseover', this.onHoverIn);
                    // this.sprite.on('mouseout', this.onHoverOut);

                    this.hand.interactive = true;
                    this.hand.buttonMode = true;
                    this.hand.on('pointerdown', this.toggle);
                    this.hand.on('mouseover', this.onHoverIn);
                    this.hand.on('mouseout', this.onHoverOut);
                }
            },
            onComplete: () => {
                if (onComplete !== null) {
                    onComplete();
                }
                this.container.visible = false;
                if (this.options.overlay) {
                    this.sprite.interactive = false;
                    this.sprite.buttonMode = false;
                    this.sprite.off('pointerdown', this.toggle);
                    // this.sprite.off('mouseover', this.onHoverIn);
                    // this.sprite.off('mouseout', this.onHoverOut);

                    this.hand.interactive = false;
                    this.hand.buttonMode = false;
                    this.hand.off('pointerdown', this.toggle);
                    this.hand.off('mouseover', this.onHoverIn);
                    this.hand.off('mouseout', this.onHoverOut);
                }
            },
        });
        timeline.set(this.sprite, { alpha: 0 });
        timeline.set(this.hand, { alpha: 0 });

        timeline.to(
            this.sprite,
            this.options.transitionTime + 1,
            {
                alpha: 1,
                ease: Sine.easeOut,
            },
            time,
        );

        time += 0.5;

        timeline.to(
            this.sprite.scale,
            this.options.transitionTime / 3,
            {
                x: 0.7,
                y: 0.7,
                ease: Sine.easeOut,
            },
            time,
        );

        time += this.options.transitionTime;

        timeline.to(
            this.hand,
            this.options.transitionTime / 1.4,
            {
                alpha: 1,
                ease: this.ease,
                // onComplete: () => {
                //     this.filter.uniforms.outerStrength = 0;
                //     this.filter.uniforms.innerStrength = 0;
                // },
            },
            time,
        );

        timeline.add(() => {
            this.sound.play();
        }, time);

        time += this.options.transitionTime / 1.4;

        timeline.to(
            this.filter.uniforms,
            0.8,
            {
                outerStrength: 0,
                innerStrength: 0,
            },
            time,
        );

        time += this.options.wait + 1 + 0.7;

        timeline.to(
            [this.hand],
            this.options.transitionTime / 2,
            {
                alpha: 0,
                ease: this.ease,
                onStart: () => {
                    this.filter.uniforms.outerStrength = 2;
                    this.filter.uniforms.innerStrength = 1;
                },
            },
            time,
        );

        time += 0.4;

        timeline.to(
            [this.sprite, this.text],
            this.options.transitionTime / 2,
            {
                alpha: 0,
                ease: this.ease,
            },
            time,
        );

        return timeline;
    }

    destroy() {
        this.container.destroy({
            children: true,
        });
        if (this.sound) {
            this.sound.destroy();
        }
    }

    getCoords() {
        return getSizeWithinMax(
            this.sprite.width,
            this.sprite.height,
            this.options.width,
            this.options.height,
            { cover: false },
        );
    }
}

export default ToggleHand;
