/**
 * Constants
 */
const FILTERS_UPDATE = 'FILTERS_UPDATE';
const FILTERS_UPDATE_FILTER = 'FILTERS_UPDATE_FILTER';
const FILTERS_CLEAR = 'FILTERS_CLEAR';
const FILTERS_CLEAR_FILTER = 'FILTERS_CLEAR_FILTER';

/**
 * Actions creator
 */
const updateFilters = values => ({
    type: FILTERS_UPDATE,
    payload: values,
});

const updateFilter = (name, value) => ({
    type: FILTERS_UPDATE_FILTER,
    payload: {
        name,
        value,
    },
});

const clearFilters = () => ({
    type: FILTERS_CLEAR,
});

const clearFilter = name => ({
    type: FILTERS_CLEAR_FILTER,
    payload: {
        name,
    },
});

/**
 * Exports
 */
const FiltersActions = {
    updateFilters,
    updateFilter,
    clearFilters,
    clearFilter,
};

export default FiltersActions;
export {
    FILTERS_UPDATE,
    FILTERS_UPDATE_FILTER,
    FILTERS_CLEAR,
    FILTERS_CLEAR_FILTER,
    updateFilters,
    updateFilter,
    clearFilters,
    clearFilter,
};
