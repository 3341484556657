import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TweenMax, TimelineMax, Sine } from 'gsap';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';

import Link from '../../components/partials/Link';

const propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    mobile: PropTypes.bool.isRequired,
    label: PropTypes.string,
    finished: PropTypes.array.isRequired,
    segmentSlug: PropTypes.string,
    sectionSlug: PropTypes.string,
    hidden: PropTypes.bool,
    group: PropTypes.shape({
        track: PropTypes.string,
        items: PropTypes.array,
    }),
    onToggleMenu: PropTypes.func.isRequired,
};

const defaultProps = {
    mobile: true,
    label: "L'histoire du NTE",
    segmentSlug: null,
    sectionSlug: null,
    hidden: false,
    group: {
        items: [],
    },
};

function createMarkup(html) {
    return { __html: html };
}

class SceneMenu extends Component {
    constructor(props) {
        super(props);

        this.renderMenuItem = this.renderMenuItem.bind(this);
        this.timelineComplete = this.timelineComplete.bind(this);

        this.itemRefs = [];
        this.timeline = null;
        this.shadow = null;
        this.menu = null;

        this.state = {
            title: props.label,
            items: props.group.items,
            positions: this.getPositions(),
        };
    }

    componentDidMount() {
        this.refreshTimeline();
        TweenMax.set(this.menu, {
            autoAlpha: 0,
        });
    }

    componentWillReceiveProps(nextProps) {
        const sizeChanged =
            nextProps.width !== this.props.width || nextProps.height !== this.props.height;
        if (sizeChanged) {
            this.setState({
                positions: this.getPositions(),
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const {
            group,
            finished,
            hidden,
            segmentSlug,
            sectionSlug,
            label,
            mobile,
            width,
            height,
        } = this.props;

        const { items, positions } = this.state;

        if (
            !isEqual(items, nextState.items) ||
            !isEqual(positions, nextState.positions) ||
            !isEqual(group, nextProps.group) ||
            !isEqual(finished, nextProps.finished) ||
            hidden !== nextProps.hidden ||
            segmentSlug !== nextProps.segmentSlug ||
            sectionSlug !== nextProps.sectionSlug ||
            label !== nextProps.label ||
            mobile !== nextProps.mobile ||
            width !== nextProps.width ||
            height !== nextProps.height
        ) {
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProps) {
        const sizeChanged =
            prevProps.width !== this.props.width || prevProps.height !== this.props.height;

        const groupChanged = prevProps.group !== this.props.group;
        const hiddenChanged = prevProps.hidden !== this.props.hidden;

        if (sizeChanged) {
            this.refreshTimeline();
            if (this.props.hidden === false) {
                this.toggleHidden(this.props.hidden);
            }
        }

        if (hiddenChanged) {
            // console.log('hc');
            this.toggleHidden(this.props.hidden);
        }

        if (groupChanged) {
            // console.log('gc');
            this.toggleHidden(this.props.hidden);
        }
    }

    getPositions() {
        const width = this.props.mobile ? this.props.width : this.props.width - 200;
        const height = this.props.mobile ? this.props.height : this.props.height - 250;

        const verticalHalf = height / 2;
        const horizontalGap = width / 5;
        const verticalGap = height / 5;
        return [
            { top: verticalHalf + verticalGap, left: 0 }, // eslint-disable-line
            { top: verticalHalf - verticalGap, left: 30 }, // eslint-disable-line
            { top: verticalHalf - verticalGap * 2, left: horizontalGap }, // eslint-disable-line
            { top: verticalHalf - verticalGap, left: horizontalGap * 2 }, // eslint-disable-line
            { top: verticalHalf - verticalGap * 2.5, left: horizontalGap * 3 }, // eslint-disable-line
            { top: verticalHalf + verticalGap, left: horizontalGap * 3 }, // eslint-disable-line
            { top: verticalHalf + verticalGap * 2, left: horizontalGap * 2 }, // eslint-disable-line
            { top: verticalHalf + verticalGap * 3, left: horizontalGap * 3 }, // eslint-disable-line
            { top: verticalHalf - verticalGap, left: horizontalGap * 4 }, // eslint-disable-line
            { top: verticalHalf + verticalGap * 2, left: 0 }, // eslint-disable-line
            { top: verticalHalf - verticalGap * 3, left: horizontalGap * 3 }, // eslint-disable-line
            { top: verticalHalf + verticalGap * 3, left: horizontalGap * 4 }, // eslint-disable-line
            { top: verticalHalf + verticalGap * 1.5, left: horizontalGap * 1.5 }, // eslint-disable-line
        ];
    }

    setPositions() {
        const { width, group } = this.props;
        const gap = width / group.items.length;

        const positions = group.items.map((item, idx) => ({
            top: Math.random() * idx * gap,
            left: Math.random() * idx * gap,
        }));

        this.setState({
            positions,
        });
    }

    toggleHidden(hidden) {
        const { title } = this.state;
        if (!title) return;

        if (hidden === false) {
            this.timeline.play(0);
        } else {
            this.timeline.reverse(0);
        }
    }

    refreshTimeline(time = 1) {
        const { title } = this.state;
        if (!title) return;

        if (this.timeline !== null) {
            this.timeline.kill();
            this.timeline = null;
        }

        this.timeline = new TimelineMax({
            paused: true,
            onReverseComplete: () => {
                this.timelineComplete();
            },
        });

        this.timeline.fromTo(
            this.menu,
            time,
            {
                autoAlpha: 0,
            },
            {
                autoAlpha: 1,
            },
            0,
        );

        this.timeline.fromTo(
            this.shadow,
            time,
            {
                autoAlpha: 0,
            },
            {
                autoAlpha: 1,
            },
            0,
        );

        if (this.itemRefs[title]) {
            this.timeline.staggerFromTo(
                this.itemRefs.filter(i => i !== null),
                time / 2,
                {
                    ease: Sine.easeOut,
                    alpha: 0,
                },
                {
                    ease: Sine.easeOut,
                    alpha: 1,
                },
                0.1,
                0,
            );
        }
    }

    timelineComplete() {
        const { group, label } = this.props;
        this.setState(
            {
                items: group.items,
                title: label,
            },
            () => {
                this.refreshTimeline();
            },
        );
    }

    renderMenuItem(item, idx) {
        const { segmentSlug, sectionSlug, finished, onToggleMenu, mobile } = this.props;
        const { positions } = this.state;
        const itemLabel = item.label;
        const key = `item-${itemLabel}-${idx}`;
        const active = parseInt(segmentSlug, 10) === idx;

        const linkClassName = classNames({
            'scene-menu-link': true,
            active,
            desktop: !mobile,
            mobile,
            finished: finished.indexOf(`${sectionSlug}-${idx}`) > -1,
        });

        const style = positions[idx] && !mobile ? { ...positions[idx] } : {};
        const link = item.link ? item.link : `/voyages/${sectionSlug}/${idx}`;
        const content = (
            <div
                className={'scene-menu-item'}
                ref={(ref) => {
                    this.itemRefs[idx] = ref;
                }}
                style={style}
            >
                {itemLabel ? (
                    <p>
                        <span
                            className="foreground"
                            dangerouslySetInnerHTML={createMarkup(itemLabel)}
                        />
                        <span
                            className="background"
                            dangerouslySetInnerHTML={createMarkup(itemLabel)}
                        />
                    </p>
                ) : (
                    <span>{`Segment #${idx + 1}`}</span>
                )}
            </div>
        );

        return active ? (
            <button key={key} className={linkClassName} onClick={onToggleMenu}>
                {content}
            </button>
        ) : (
            <Link key={key} to={link} className={linkClassName}>
                {content}
            </Link>
        );
    }

    render() {
        const { sectionSlug, mobile } = this.props;
        const { items, title } = this.state;
        const sceneMenuClassName = `scene-menu ${mobile ? 'mobile' : 'desktop'} full`;
        if (!title) return null;

        return (
            <div>
                <div
                    className="scene-menu-shadow"
                    ref={(ref) => {
                        this.shadow = ref;
                    }}
                />
                <div
                    className={sceneMenuClassName}
                    ref={(ref) => {
                        this.menu = ref;
                    }}
                >
                    {items && items.length > 0 ? (
                        <div className="inner-scene-menu">
                            <div className="title">
                                <h2>
                                    <Link to={`/voyages/${sectionSlug}/0`}>{title}</Link>
                                </h2>
                            </div>
                            <div className="scene-menu-items">{items.map(this.renderMenuItem)}</div>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

SceneMenu.propTypes = propTypes;
SceneMenu.defaultProps = defaultProps;

export default SceneMenu;
