import React, { Component } from 'react';
import { TimelineMax, Power0 } from 'gsap';
import PropTypes from 'prop-types';

const propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
    stroke: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    width: 16,
    height: 18,
    color: '#000000',
    stroke: 'none',
    className: null,
};

class BackUpIcon extends Component {

    constructor(props) {
        super(props);
        this.refSvg = null;
    }

    componentDidMount() {
        // this.timeline = new TimelineMax();
        //
        // this.timeline.to(this.refSvg, 2, {
        //     rotation: -360,
        //     repeat: -1,
        //     ease: Power0.easeNone,
        // });
    }

    render() {
        const {
            width,
            height,
            color,
            stroke,
            className,
        } = this.props;

        return (
            <svg
                ref={(ref) => { this.refSvg = ref; }}
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
                x="0px"
                y="0px"
                width={`${width}px`}
                height={`${height}px`}
                viewBox={`0 0 ${width} ${height}`}
                enableBackground={`new 0 0 ${width} ${height}`}
            >
                <rect fill={color} className={className} stroke={stroke} x="5" y="8.3" width="6" height="9.5" />
                <polygon fill={color} className={className} stroke={stroke} points="0,8.2 8,0.2 16,8.2" />
            </svg>
        );
    }
}

BackUpIcon.propTypes = propTypes;
BackUpIcon.defaultProps = defaultProps;

export default BackUpIcon;
