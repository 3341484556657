import React, { Component } from 'react';
import GridItem from '../items/Grid';

const propTypes = {
    items: React.PropTypes.array.isRequired,
    onPreviewChange: React.PropTypes.func.isRequired,
};

const defaultProps = {
};

class SingleGrid extends Component {

    static renderItem(item, size) {
        return (
            <GridItem
                item={item}
                size={size}
                key={`square-grid-item-${item.id}`}
                onPreviewChange={this.props.onPreviewChange}
            />
        );
    }

    render() {
        const firstColumn = [];
        const secondColumn = [];
        this.props.items.forEach((item, index) => {
            if (index < 3) {
                if (index === 0) {
                    firstColumn.push(this.renderItem(item, 'large'));
                } else {
                    firstColumn.push(this.renderItem(item, 'small'));
                }
            } else if (index < 9) {
                secondColumn.push(this.renderItem(item, 'small'));
            }
        });
        return (
            <div className="grid-menu-container">
                <div className="grid-menu-row">
                    <div className="grid-menu-column grid-menu-column-normal">
                        { firstColumn }
                        <div className="clear" />
                    </div>
                    { secondColumn.length > 0 ? (
                        <div className="grid-menu-column grid-menu-column-small">
                            { secondColumn }
                            <div className="clear" />
                        </div>
                    ) : null }
                </div>
            </div>
        );
    }
}

SingleGrid.propTypes = propTypes;
SingleGrid.defaultProps = defaultProps;

export default SingleGrid;
