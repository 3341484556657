const getWidth = () =>
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

const getHeight = () =>
    window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

const getMobile = () =>
    window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth < 768;

export default getWidth;

export { getWidth, getHeight, getMobile };
