import React, { Component } from 'react';
import { TimelineMax, Power0 } from 'gsap';
import PropTypes from 'prop-types';

const propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
    stroke: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    width: 28,
    height: 24,
    color: '#000000',
    stroke: '#000000',
    className: null,
};

class LoadingIcon extends Component {

    constructor(props) {
        super(props);
        this.refSvg = null;
    }

    componentDidMount() {
        this.timeline = new TimelineMax();

        this.timeline.to(this.refSvg, 2, {
            rotation: -360,
            repeat: -1,
            ease: Power0.easeNone,
        });
    }

    render() {
        const {
            width,
            height,
            color,
            stroke,
            className,
        } = this.props;

        return (
            <svg
                ref={(ref) => { this.refSvg = ref; }}
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
                x="0px"
                y="0px"
                width={`${width}px`}
                height={`${height}px`}
                viewBox={`0 0 ${width} ${height}`}
                enableBackground={`new 0 0 ${width} ${height}`}
            >
                <polygon fill={color} fillOpacity={1} className={className} stroke={stroke} points="21.6,4.4 16.6,10.7 26.7,10.7" />
                <path fill="none" strokeWidth="2" strokeMiterlimit="10" fillOpacity="0" className={className} stroke={stroke} d="M12.1,2.5c-5.2,0-9.5,4.3-9.5,9.5c0,5.2,4.2,9.5,9.5,9.5c5.2,0,9.5-4.3,9.5-9.5V8.2" />
            </svg>
        );
    }
}

LoadingIcon.propTypes = propTypes;
LoadingIcon.defaultProps = defaultProps;

export default LoadingIcon;

/*

    <!-- Generator: Adobe Illustrator 22.0.0, SVG Export Plug-In  -->
    <svg version="1.1"
     xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
     x="0px" y="0px" width="20.3px" height="17.7px" viewBox="0 0 20.3 17.7" style="enable-background:new 0 0 20.3 17.7;"
     xml:space="preserve">
    <style type="text/css">
    .st0{fill:none;stroke:#000000;stroke-width:2.6667;stroke-miterlimit:10;}
    </style>
    <defs>
    </defs>
    <g>
    <polygon points="16.3,2.8 12.3,7.8 20.3,7.8 	"/>
    <path class="st0" d="M8.8,1.3c-4.1,0-7.5,3.4-7.5,7.5c0,4.1,3.4,7.5,7.5,7.5c4.1,0,7.5-3.4,7.5-7.5v-3"/>
    </g>
    </svg>
 

 */
