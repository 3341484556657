import Generic from './generic';
import Intro from './intro';
import Menu from './menu';
import MainMenu from './main-menu';

export default {
    Generic,
    Intro,
    Menu,
    MainMenu,
};
