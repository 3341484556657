import React from 'react';
import classNames from 'classnames';

import Link from '../partials/Link';
import withUrl from '../../lib/withUrl';

import Title from '../partials/Title';
import Meta from '../partials/Meta';
import Footer from '../../containers/partials/Footer';

const propTypes = {
    url: React.PropTypes.object.isRequired,
};

const AboutPage = ({ url }) => {
    const pageClassNames = classNames({
        container: true,
        'container-list': true,
        'container-page-text': true,
    });

    const meta = {
        snippet: {
            title: 'À propos',
            subtitle: 'À propos des Archives du Nouveau Théâtre Expérimental',
        },
    };

    return (
        <section className={pageClassNames}>
            <Meta bubble={meta} />
            <Title text={meta.snippet.title} className="title-page" />
            <div className="text-section">
                <p>
                    Ce site est consacré aux archives du Nouveau Théâtre Expérimental (NTE) et de
                    son ancêtre, le Théâtre Expérimental de Montréal (TEM).
                </p>
            </div>

            <div className="text-section">
                <p>
                    Le{' '}
                    <a href="http://www.nte.qc.ca/" rel="noopener noreferrer" target="_blank">
                        NTE
                    </a>{' '}
                    a été fondé à Montréal en 1979, par Robert Claing, Robert Gravel, Anne-Marie
                    Provencher et Jean-Pierre Ronfard. Installée au théâtre Espace Libre, la
                    compagnie est dirigée, depuis 2003, par Daniel Brière et Alexis Martin.
                </p>
            </div>

            <div className="text-section">
                <p>
                    Pour un résumé de l’historique du NTE et du TEM, et pour en savoir davantage sur
                    leurs fondateurs et directeurs respectifs, consultez les fiches qui leurs sont
                    dédiées dans la rubrique{' '}
                    <Link to={url.route('catalog.artists.index')}>ARTISANS</Link> du site. Plusieurs
                    entretiens filmés sont aussi disponibles parmi notre collection de{' '}
                    <Link to={url.route('catalog.medias.videos.index')}>VIDÉOS</Link> d’archives.
                </p>
            </div>

            <div className="text-section">
                <p>
                    Le NTE est toujours très actif ! Pour suivre ses actualités, assister à ses
                    spectacles, rendez-vous sur le site courant de la compagnie :{' '}
                    <a href="http://www.nte.qc.ca/" rel="noopener noreferrer" target="_blank">
                        nte.qc.ca
                    </a>
                </p>
            </div>
            <div className="text-section">
                <h4>Comment naviguer dans les archives ?</h4>
                <p>Le site propose deux approches :</p>
                <br />
                <ul>
                    <li>
                        La section «{' '}
                        <Link to={url.route('catalog.index')}>Explorez les archives</Link> » permet
                        d’accéder à notre catalogue d’items. La collection est répertoriée sous
                        différentes rubriques et catégories. Un outil de recherche permet aussi de
                        retrouver un contenu précis.
                    </li>
                    <li>
                        La section « <a href="/voyages">Voyages initiatiques</a> » constitue une
                        manière plus ludique et scénarisée de parcourir notre histoire. Quatre
                        voyages, élaborés à partir de quatre thématiques distinctes, sont proposés.
                        Pendant le visionnement des voyages, du contenu complémentaire vous est
                        suggéré (vidéos, documents promotionnels, maquettes, etc.).
                    </li>
                </ul>
            </div>

            <div className="text-section">
                <h4>Puis-je copier ou télécharger les documents d’archives de ce site ?</h4>
                <p>
                    Les documents d’archives de ce site sont la propriété du NTE (ou prêtés au NTE
                    par des tierces-parties selon une entente). Vous ne pouvez pas télécharger ces
                    documents, ni reproduire les différents contenus textuels, audios et vidéos sur
                    votre site internet. Il est permis de lire, visionner ou écouter ces documents,
                    seul ou en groupe, à des fins éducatives, récréatives et non commerciales.
                </p>
            </div>

            <div className="text-section">
                <h4>Je ne retrouve pas les textes de vos pièces de théâtre sur ce site ?</h4>
                <p>
                    Ce site n’inclut pas les textes des œuvres théâtrales du NTE et du TEM car nous
                    n’en détenons pas les droits d’auteurs; ceux-ci sont la propriété des auteurs
                    concernés. Au fil des ans, plusieurs pièces ont été publiées et elles sont
                    disponibles en librairie et dans les bibliothèques. Vous pouvez aussi vous
                    adresser au CEAD (Centre des auteurs dramatiques) si vous recherchez un texte
                    non publié. La bibliothèque de l’École nationale de théâtre du Canada (ÉNT)
                    possède aussi une impressionnante collection.
                </p>
            </div>

            <div className="text-section">
                <h4>Existe-t-il d’autres collections dédiées aux archives du NTE et du TEM ?</h4>
                <p>
                    Oui : même si notre collection est vaste, elle n’est pas exhaustive. D’autres
                    ressources sont disponibles, comme le fonds d’archives du Nouveau Théâtre
                    Expérimental (P971) et le fonds d’archives sur Jean-Pierre Ronfard et la
                    fondation du TEM (P863), tous deux conservés au centre BAnQ Vieux-Montréal de
                    Bibliothèque et Archives nationales du Québec.
                </p>
            </div>

            <div className="text-section">
                <h4>Vous avez des questions ? Des commentaires ?</h4>
                <p>
                    <a
                        href="https://www.nte.qc.ca/equipe-et-coordonnees"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Contactez-nous
                    </a>
                    , il nous fera plaisir de vous lire et de vous répondre.
                </p>
            </div>

            <br />

            <div className="text-section">
                <p>Une réalisation de :</p>
                <a
                    href="https://folkloreinc.ca"
                    className="text-credit"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img src="/img/deco/F-logo.png" alt="Folklore" />
                </a>
            </div>

            <Footer />
        </section>
    );
};

AboutPage.propTypes = propTypes;

export default withUrl(AboutPage);
