import React, { Component } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
};

const defaultProps = {};

class ScanGallery extends Component {
    constructor(props) {
        super(props);
        this.refScanGallery = null;
    }

    render() {
        return (
            <div
                ref={(ref) => {
                    this.refScanGallery = ref;
                }}
                className="scangallery-container"
            >
                ScanGallery
            </div>
        );
    }
}

ScanGallery.propTypes = propTypes;
ScanGallery.defaultProps = defaultProps;

export default ScanGallery;
