import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TweenMax, TimelineLite } from 'gsap';

const propTypes = {
    size: PropTypes.number,
    width: PropTypes.number.isRequired,

    loading: PropTypes.number,
    progress: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    duration: PropTypes.number,
    durations: PropTypes.array,
    speed: PropTypes.number,
    height: PropTypes.number,

    seek: PropTypes.func.isRequired,
};

const defaultProps = {
    size: 0,
    loading: 0,
    progress: 0,
    duration: 0,
    durations: [],
    speed: 0.6,
    height: 6,
};

class Progress extends Component {
    constructor(props) {
        super(props);

        this.bar = null;
        this.loaded = null;
        this.progress = null;
        this.stripes = {};
        this.loadingTimeline = null;
        this.progressTimeline = null;

        this.onClick = this.onClick.bind(this);

        this.state = {
            iconSize: 10,
        };
    }

    componentDidMount() {
        this.setStripes(this.props.durations);
    }

    componentDidUpdate(prevProps) {
        const sizeChanged = prevProps.size !== this.props.size;
        const durationsChanged = prevProps.durations !== this.props.durations;
        const loadingChanged = prevProps.loading !== this.props.loading;
        const progressChanged = prevProps.progress !== this.props.progress;

        if (loadingChanged) {
            this.setLoadingLength(this.props.loading);
        }

        if (progressChanged) {
            this.setProgressLength(this.props.progress);
        }

        if (durationsChanged || sizeChanged) {
            this.setLoadingLength(this.props.loading);
            this.setProgressLength(this.props.progress);
            this.setStripes(this.props.durations);
        }
    }

    onClick(e) {
        const bounds = this.bar.getBoundingClientRect();
        const x = e.clientX - bounds.left;
        if (this.props.loading > 99) {
            this.props.seek(x / bounds.width);
        }
    }

    setLoadingLength(pct) {
        if (this.bar !== null) {
            if (this.loadingTimeline !== null) {
                this.loadingTimeline.kill();
            }
            this.loadingTimeline = new TimelineLite().to(this.loading, 0.3, {
                // prettier-ignore
                width: (pct / 100) * this.bar.offsetWidth,
            });
        }
    }

    setProgressLength(pct) {
        if (this.bar !== null) {
            if (this.progressTimeline !== null) {
                this.progressTimeline.kill();
            }
            this.progressTimeline = new TimelineLite().to(this.progress, this.props.speed, {
                width: pct * this.bar.offsetWidth,
            });
        }
    }

    setStripes(durations) {
        if (this.bar !== null && durations.length > 0) {
            let i = 0;
            this.props.durations.forEach((time, idx) => {
                i += time;
                TweenMax.to(this.stripes[idx], this.props.speed, {
                    // prettier-ignore
                    left: (i / this.props.duration) * this.bar.offsetWidth,
                });
            });
        }
    }

    renderProgressBar(durations) {
        const { height } = this.props;

        return (
            <button
                className="progress-bar"
                onClick={this.onClick}
                style={{ height }}
                ref={(ref) => {
                    this.bar = ref;
                }}
            >
                <div
                    className="progress-bar-loading"
                    style={{ height }}
                    ref={(ref) => {
                        this.loading = ref;
                    }}
                />
                <div
                    className="progress-bar-progress"
                    style={{ height }}
                    ref={(ref) => {
                        this.progress = ref;
                    }}
                />
                {durations.map((duration, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div
                        key={`progress-stripe-${duration}-${idx + 30}`}
                        className="progress-bar-stripe"
                        ref={(ref) => {
                            this.stripes[idx] = ref;
                        }}
                    />
                ))}
            </button>
        );
    }

    render() {
        const { durations, width, height } = this.props;

        const style = {
            width,
            bottom: height,
        };

        return (
            <div className="progress" style={style}>
                <div className="progress-bar-container">{this.renderProgressBar(durations)}</div>
            </div>
        );
    }
}

Progress.propTypes = propTypes;
Progress.defaultProps = defaultProps;

export default Progress;
