import React from 'react';
import Helmet from 'react-helmet';
import striptags from 'striptags';

const propTypes = {
    bubble: React.PropTypes.object,
    title: React.PropTypes.string,
    section: React.PropTypes.string,
    description: React.PropTypes.string,
    defaultPicture: React.PropTypes.string,
    defaultDescription: React.PropTypes.string,
};

const defaultProps = {
    bubble: {
        snippet: {
            title: 'Archives du NTE',
            subtitle: '',
            link: '',
            picture_facebook: null,
        },
        description: '',
    },
    title: null,
    section: null,
    description: null,
    defaultPicture: '/img/backgrounds/facebook-archive.jpg',
    defaultDescription:
        'Les archives complètes du Nouveau Théatre Expérimental, un théâtre phare du Québec moderne.',
};

const Meta = (props) => {
    const { section, defaultPicture, defaultDescription } = props;
    const { title, subtitle, link } = props.bubble.snippet;
    const pictureFacebook = props.bubble.snippet.picture_facebook;
    const { description } = props.bubble;

    const overrideTitle = props.title;
    const overrideDescription = props.description;

    const image = pictureFacebook || defaultPicture;
    const imageUrl = image.url ? `https://${window.location.hostname}${image.url}` : null;

    const canonical = link || window.location.href;

    const metaDescription = overrideDescription || description || subtitle;
    const realDescription = metaDescription ? striptags(metaDescription) : defaultDescription;
    const strippedDescription =
        realDescription.length > 155 ? `${realDescription.substr(0, 152)}...` : realDescription;

    const fullTitle =
        section !== null
            ? `${title} - ${section} | Archives du NTE`
            : `${title} | Archives du Nouveau Théâtre Expérimental`;

    return (
        <Helmet
            htmlAttributes={{ lang: 'fr' }}
            title={overrideTitle || fullTitle}
            defaultTitle="Archives du NTE"
            meta={[
                { name: 'description', content: strippedDescription },
                { property: 'og:title', content: overrideTitle || fullTitle },
                { property: 'og:type', content: 'website' },
                { property: 'og:description', content: strippedDescription },
                { property: 'og:url', content: canonical },
                { property: 'og:image', content: imageUrl },
            ]}
            link={[{ rel: 'canonical', href: canonical }]}
        />
    );
};

Meta.propTypes = propTypes;
Meta.defaultProps = defaultProps;

export default Meta;
