import React, { Component } from 'react';
import Relay from 'react-relay';
import classNames from 'classnames';
import Immutable from 'immutable';
import indexOf from 'lodash/indexOf';
import { parse, stringify } from 'query-string';
import { withRouter } from 'react-router';
import withUrl from '../../lib/withUrl';

const propTypes = {
    value: React.PropTypes.oneOfType([
        React.PropTypes.arrayOf(React.PropTypes.string),
        React.PropTypes.arrayOf(React.PropTypes.number),
    ]),
    filter: React.PropTypes.shape({
        label: React.PropTypes.string,
        name: React.PropTypes.string,
        type: React.PropTypes.string,
        values: React.PropTypes.arrayOf(
            React.PropTypes.shape({
                label: React.PropTypes.string,
                value: React.PropTypes.string,
            }),
        ),
    }).isRequired,
    router: React.PropTypes.object.isRequired, // eslint-disable-line
    location: React.PropTypes.object.isRequired, // eslint-disable-line
    onChange: React.PropTypes.func.isRequired,
};

const defaultProps = {
    value: ['10'],
};

// In case we change our mind again...
// const collapsedCheckboxes =
// ['artists_categories', 'productions_categories', 'publications_categories'];

const getCollapsed = (props) => {
    let collapsed = false;
    if (props.filter && props.filter.name) {
        if (props.filter.name.indexOf('categories') > -1 && !props.value) {
            collapsed = false; // NOTE: Change this for default closed
        }
    }
    return collapsed;
};

class CheckboxesFilter extends Component {
    constructor(props) {
        super(props);

        this.renderValue = this.renderValue.bind(this);
        this.toggleCollapsed = this.toggleCollapsed.bind(this);
        const collapsed = getCollapsed(props);
        this.state = {
            collapsed,
        };
    }

    componentDidUpdate(prevProps) {
        const valueChanged = this.props.value !== prevProps.value;

        if (valueChanged) {
            const { filter = {}, router = null, location = null, value = null } = this.props;
            const { name = null } = filter || {};
            const { search, pathname } = location || {};

            if (router !== null) {
                const currentParams = parse(search || '', { arrayFormat: 'bracket' });
                const newParams = stringify(
                    { ...currentParams, [name]: value },
                    { arrayFormat: 'bracket' },
                );
                router.push(`${pathname}?${newParams}`);
            }
        }
    }

    onLabelClick(e, value) {
        const currentValue = new Immutable.List(this.props.value);
        let nextValue = new Immutable.List();
        const valueIndex = currentValue.indexOf(value);
        if (valueIndex === -1) {
            nextValue = new Immutable.List([value]);
        }
        if (!currentValue.equals(nextValue)) {
            if (nextValue.isEmpty()) {
                this.props.onChange(['']);
            } else {
                this.props.onChange(nextValue.toJS());
            }
        }
    }

    toggleCollapsed() {
        const { collapsed } = this.state;
        this.setState({
            collapsed: !collapsed,
        });
    }

    renderValues(values) {
        return values.map(this.renderValue);
    }

    renderValue(value, i) {
        const key = `value-${i}`;
        const name = `${this.props.filter.name}[]`;
        const val = value.value;
        const { label } = value;
        const isSelected = indexOf(this.props.value, val) !== -1;
        const size = this.props.filter.label === 'Années' ? 'large' : 'normal';

        const itemClassNames = classNames({
            'filter-item': true,
            'filter-item-checkbox': true,
            active: isSelected,
            [size]: true,
        });

        const onClick = (e) => this.onLabelClick(e, val);

        return (
            <li key={key} className={itemClassNames}>
                <button type="button" className="btn" onClick={onClick}>
                    <p className="padding">
                        <span className="label">{label}</span>
                    </p>
                </button>
                <input type="checkbox" className="checkbox" name={name} value={val} />
            </li>
        );
    }

    render() {
        const { collapsed } = this.state;
        const values = this.props.filter.values || [];
        const listValues = this.renderValues(values);

        let label;
        if (this.props.filter.label) {
            const className = classNames({
                'filter-button': true,
                active: !collapsed && this.props.value,
            });
            label = (
                <button type="button" className={className} onClick={this.toggleCollapsed}>
                    <h4 className="filter-title">{this.props.filter.label}</h4>
                </button>
            );
        }

        const className = classNames({
            filter: true,
            'filter-checkboxes': true,
            large: this.props.filter.name.split('_')[1] === 'years' ? 'large' : '',
        });

        return (
            <div className={className}>
                {label}
                {!collapsed ? (
                    <div className="filter-items">
                        <ul>{listValues}</ul>
                    </div>
                ) : null}
            </div>
        );
    }
}

CheckboxesFilter.propTypes = propTypes;
CheckboxesFilter.defaultProps = defaultProps;

const WithUrlFilters = withUrl(withRouter(CheckboxesFilter));

WithUrlFilters.RelayComponent = Relay.createContainer(WithUrlFilters, {
    fragments: {
        filter: () => Relay.QL`
            fragment on FilterCheckboxes {
                label
                name
                type
                values {
                    label
                    value
                }
            }
        `,
    },
});

export default WithUrlFilters;
