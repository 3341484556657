import { TimelineLite, TweenMax } from 'gsap';
import * as PIXI from 'pixi.js';
import merge from 'lodash/merge';
import Chance from 'chance';

import { getSizeWithinMax } from '../../utils/size';
import getCvData from '../../utils/getCvData';
import filters from '../filters/index';

const chance = new Chance();

class ImageComponent {
    constructor(options = { scene: null, width: 0, height: 0 }) {
        this.setOptions(options);

        this.sprite = null;
        this.border = null;
        this.coords = null;
        this.overlay = null;

        this.timeline = null;
        this.graphics = [];
        this.label = {};
        this.labels = [];
        this.labelBackgrounds = [];

        this.container = new PIXI.Container();
        this.topContainer = new PIXI.Container();
        this.textContainer = new PIXI.Container();
        this.textBackgroundContainer = new PIXI.Container();

        this.containers = [
            this.container,
            this.topContainer,
            this.textContainer,
            this.textBackgroundContainer,
        ];

        this.dynamicTargets = [];
        this.dynamicGraphics = [];
        this.dynamicLabels = [];

        this.init = this.init.bind(this);
        this.create = this.create.bind(this);
        this.resize = this.resize.bind(this);

        return this;
    }

    setOptions(options = {}) {
        const targets = PIXI.loader.resources[getCvData(options.src)];

        if (targets) {
            options.targets = merge(targets.data, options.targets); // eslint-disable-line
        }

        const opts = merge(
            {
                toggleOverlay: () => {},
                time: 1,
                transitionTime: 1.4,
                wait: 2,
                texture: {
                    src: '/img/trips/portraits/mao2.jpg',
                },
                label: null,
                overlay: null,
                targets: [],
                labels: [],
                textStyle: {
                    fontFamily: 'Roboto',
                    fontSize: 18,
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fill: '#000000',
                    wordWrap: true,
                    wordWrapWidth: 440,
                },
                hoverStyle: {
                    fontFamily: 'Roboto',
                    fontSize: 18,
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fill: '#ff0000',
                    wordWrap: true,
                    wordWrapWidth: 440,
                },
                descriptionStyle: {
                    fontFamily: 'Roboto',
                    fontSize: 16,
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fill: '#ffcc33',
                    wordWrap: true,
                    wordWrapWidth: 440,
                },
                overlayStyle: {
                    fontFamily: 'Roboto',
                    fontSize: 128,
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fill: '#000000',
                    wordWrap: true,
                    wordWrapWidth: 440,
                    stroke: '#ff0000',
                    strokeThickness: 12,
                },
            },
            options,
        );

        this.options = opts;
    }

    init() {
        this.create();
        this.createRectangles(this.options.targets);
    }

    create() {
        if (this.sprite !== null) {
            this.sprite.destroy();
        }

        this.sprite = new PIXI.Sprite(PIXI.loader.resources[this.options.src].texture);
        this.image = new PIXI.Sprite(PIXI.loader.resources[this.options.src].texture);

        this.coords = this.getCoords();

        const size = chance.integer({ min: 6, max: 8 });
        // size = Math.floor(this.options.height / 8);
        // console.log(size);

        this.pixelFilter = new filters.AsciiFilter(size);
        this.pixelFilter.size = size;

        // const colorMatrix = new PIXI.filters.ColorMatrixFilter();
        // colorMatrix.sepia(true);
        // colorMatrix.technicolor(true);

        this.sprite.width = this.coords.width;
        this.sprite.height = this.coords.height;

        // The real image
        this.image.width = this.coords.width;
        this.image.height = this.coords.height;
        this.image.alpha = 0;

        this.sprite.filters = [this.pixelFilter];

        TweenMax.set([this.sprite, this.image], {
            alpha: 0,
            x: (this.options.width - this.sprite.width) / 2,
            y: (this.options.height - this.sprite.height) / 2,
        });

        this.sprite.x = (this.options.width - this.sprite.width) / 2; // eslint-disable-line
        this.sprite.y = (this.options.height - this.sprite.height) / 2; // eslint-disable-line
        this.sprite.width = this.coords.width;
        this.sprite.height = this.coords.height;

        // Add it
        this.container.addChild(this.sprite);
        this.container.addChild(this.image);

        const graphics = new PIXI.Graphics();
        graphics.alpha = 0;
        this.container.addChild(graphics);

        this.border = graphics;
    }

    createOverlay() {
        const { width, height } = this.options;

        const sprite = new PIXI.Sprite(PIXI.loader.resources['/img/textures/1.png'].texture);
        sprite.anchor.set(1, 1);
        sprite.x = width;
        sprite.y = height;
        sprite.width = Math.floor(width / 2);
        sprite.height = Math.floor((width * 0.6666) / 2);
        sprite.alpha = 1;

        const style = new PIXI.TextStyle(this.options.overlayStyle);
        const text = new PIXI.Text(this.options.overlayType || '+++', style);
        text.x = width - 250;
        text.y = 0;
        text.alpha = 0;

        sprite.interactive = true;
        sprite.buttonMode = true;

        text.interactive = true;
        text.buttonMode = true;

        text.on('pointerdown', () => {
            if (this.options.url) {
                this.options.toggleOverlay(this.options.overlay, {});
            }
        });

        sprite.on('pointerdown', () => {
            if (this.options.url) {
                this.options.toggleOverlay(this.options.overlay, {});
            }
        });

        this.topContainer.addChild(sprite);
        this.topContainer.addChild(text);
        this.overlay = text;
    }

    createRectangles(targets) {
        if (targets === null) return;

        for (let i = 0; i < targets.length; i += 1) {
            const target = targets[i];
            if (target.x && target.y) {
                const x = this.sprite.x + target.x * this.sprite.width; // eslint-disable-line
                const y = this.sprite.y + target.y * this.sprite.height; // eslint-disable-line
                const width = target.width * this.sprite.width;
                const height = target.height * this.sprite.height;
                const label = this.options.labels[i] ? this.options.labels[i] : target.label;
                this.createShapes(label, x, y, width, height);
            }
        }

        this.topContainer.addChild(this.textBackgroundContainer);
        this.topContainer.addChild(this.textContainer);
    }

    createShapes(label, x, y, width, height) {
        let endX = 0;
        let endY = 0;
        let endWidth = 0;
        let endHeight = 0;
        const rectangles = [];
        for (let j = 0; j < 1; j += 1) {
            const graphics = new PIXI.Graphics();
            graphics.lineStyle(2, 0xffcc33);
            // prettier-ignore
            endX = x + 6 * j; // eslint-disable-line
            endY = y + 6 * j; // eslint-disable-line
            endWidth = width + 6 * j * 2; // eslint-disable-line
            endHeight = height + 6 * j * 2; // eslint-disable-line
            graphics.drawRect(endX, endY, endWidth, endHeight);
            graphics.alpha = 0;

            rectangles.unshift(graphics);
            this.topContainer.addChild(graphics);
        }

        const style = new PIXI.TextStyle(this.options.descriptionStyle);
        // const hoverStyle = new PIXI.TextStyle(this.options.hoverStyle);
        const descriptionStyle = new PIXI.TextStyle(this.options.descriptionStyle);

        const text = new PIXI.Text(
            label !== 'Face' ? label : '',
            this.options.overlay ? style : style,
        );
        text.x = x + (width + 20);
        // prettier-ignore
        text.y = (y + (height / 2)) - (20 / 2);
        text.alpha = 0;
        this.textContainer.addChild(text);

        this.graphics.push(rectangles);
        this.labels.push(text);

        const description = new PIXI.Text('', descriptionStyle);
        description.x = x + (width + 10);
        // prettier-ignore
        description.y = (y + (height / 2)) - ((36 / 2) - 36);
        description.alpha = 0;

        this.topContainer.addChild(description);

        if (this.options.overlay) {
            // No touch for now
            text.interactive = false;
            text.buttonMode = false;
        }
    }

    resize(props) {
        this.options = { ...this.options, ...props };

        if (this.sprite !== null && this.container !== null) {
            this.coords = this.getCoords();

            this.sprite.width = this.coords.width;
            this.sprite.height = this.coords.height;

            this.image.width = this.coords.width;
            this.image.height = this.coords.height;

            TweenMax.set([this.sprite, this.image], {
                x: (this.options.width - this.sprite.width) / 2,
                y: (this.options.height - this.sprite.height) / 2,
            });
        }

        if (this.graphics.length > 0) {
            for (let i = 0; i < this.graphics.length; i += 1) {
                for (let j = 0; j < this.graphics[i]; j += 1) {
                    this.graphics[i].destroy();
                }
            }
        }

        if (this.labels.length > 0) {
            for (let i = 0; i < this.labels.length; i += 1) {
                this.labels[i] = null;
            }
        }

        this.createRectangles(this.options.targets);
    }

    getTimeline(start = 0, onComplete = null) {
        let time = start;
        this.containers.forEach((container) => {
            container.visible = false; // eslint-disable-line
        });

        const timeline = new TimelineLite({
            onComplete: () => {
                this.containers.forEach((container) => {
                    container.visible = false; // eslint-disable-line
                });
                if (onComplete !== null) {
                    onComplete();
                }
            },
        });
        timeline.set(this.containers, { visible: true });
        timeline.set(this.sprite, { alpha: 0 });
        timeline.set(this.image, { alpha: 0 });
        timeline.set(this.label, { alpha: 0 });

        timeline.add(() => {
            if (this.label) {
                this.label.visible = true;
                this.label.interactive = true;
                this.label.buttonMode = true;
            }
        }, time);

        timeline.to(
            [this.sprite, this.border, this.overlay],
            this.options.transitionTime,
            {
                alpha: 1,
            },
            time,
        );

        time += this.options.transitionTime;

        timeline.to(
            [this.image, this.label],
            this.options.transitionTime,
            {
                alpha: 1,
            },
            time,
        );

        timeline.to(
            [this.sprite, this.border],
            this.options.transitionTime,
            {
                alpha: 0,
            },
            time,
        );

        time += this.options.transitionTime;

        for (let i = 0; i < this.graphics.length; i += 1) {
            this.createZoomIn(
                timeline,
                time,
                this.options.wait,
                this.graphics[i],
                this.labels[i],
                this.labelBackgrounds[i],
            );
            time += 0.2;
        }

        time += this.options.wait - 0.5;

        timeline.to(
            [this.image, this.label],
            this.options.transitionTime + 1,
            {
                alpha: 0,
            },
            time,
        );

        timeline.to(
            [this.sprite, this.border],
            this.options.transitionTime,
            {
                alpha: 0.5,
            },
            time - 0.5,
        );

        time += this.options.transitionTime;

        timeline.add(() => {
            if (this.label) {
                this.label.visible = false;
                this.label.interactive = false;
                this.label.buttonMode = false;
            }
        }, time);

        timeline.to(
            [this.sprite, this.border, this.overlay],
            this.options.transitionTime,
            {
                alpha: 0,
            },
            time,
        );

        return timeline;
    }

    createZoomIn(timeline, start, delay, rectangles, label, labelBackground) {
        const time = 0.3 || this.options.time;

        timeline.add(() => {
            label.interactive = true; // eslint-disable-line
            label.buttonMode = true; // eslint-disable-line
        }, start);

        timeline.staggerTo(
            rectangles,
            time,
            {
                alpha: 1,
            },
            0.1,
            start,
        );

        timeline.to(
            [label, labelBackground],
            2,
            {
                alpha: 1,
            },
            start,
        );

        const end = start + delay;

        timeline.to(
            [label, labelBackground],
            2,
            {
                alpha: 0,
            },
            end,
        );

        timeline.staggerTo(
            rectangles,
            time,
            {
                alpha: 0,
            },
            0.1,
            end,
        );

        timeline.add(() => {
            label.interactive = false; // eslint-disable-line
            label.buttonMode = false; // eslint-disable-line
        }, end + 2);

        return timeline;
    }

    destroy() {
        this.containers.forEach((container) => {
            container.destroy({
                children: true,
            });
        });
    }

    getCoords() {
        return getSizeWithinMax(
            this.sprite.texture.orig.width,
            this.sprite.texture.orig.height,
            this.options.width,
            this.options.height,
            { cover: false },
        );
    }
}

export default ImageComponent;
