import React from 'react';
import classNames from 'classnames';
import Title from '../partials/Title';
import Link from './Link';
import BubblesList from '../../containers/BubblesList';
import withUrl from '../../lib/withUrl';

const propTypes = {
    filters: React.PropTypes.shape({
        name: React.PropTypes.string,
    }).isRequired,
    section: React.PropTypes.shape({
        key: React.PropTypes.string,
        label: React.PropTypes.string,
    }).isRequired,
    url: React.PropTypes.shape({
        route: React.PropTypes.func,
    }).isRequired,
    itemSize: React.PropTypes.string,
    isSingle: React.PropTypes.bool,
    letter: React.PropTypes.string,
    direction: React.PropTypes.string,
};

const defaultProps = {
    pageSize: null,
    filters: null,
    preview: false,
    itemSize: 'large',
    isSingle: true,
    letter: '',
    direction: 'asc',
};

const CatalogSection = (props) => {
    const { section, url, itemSize, filters, direction, isSingle } = props;
    const sectionClassNames = classNames({
        'page-section': true,
        'list-section': true,
        'list-items-small': section.key === 'artists',
    });
    const sectionLink = url.route(`catalog.${section.key}.index`);
    const fullLink =
        (section.child && section.parent && section.parent.key !== 'catalog'
            ? url.route(`catalog.${section.parent.key}.index`)
            : '') + sectionLink;

    const filtersKeys = Object.keys(filters);
    const hasSearchFilters = filtersKeys.indexOf('search_search') !== -1;
    const hasSearchContent =
        hasSearchFilters && filters.search_search && filters.search_search.length > 0;

    // For display
    const letter = props.letter;
    let letters = '';
    if (letter === '0') {
        letters = '0-9';
    } else if (letter === 'Y') {
        letters = 'YZ';
    } else if (letter) {
        letters =
            letter +
            String.fromCharCode(letter.charCodeAt() + 1) +
            String.fromCharCode(letter.charCodeAt() + 2);
    }

    return (
        <BubblesList
            {...props}
            name={`section:${section.key}:${letters !== '' ? 'name' : 'date'}:${direction}`}
            itemSize={itemSize}
            linkToAll={fullLink}
            decorator={(list, items) => {
                if (hasSearchFilters && hasSearchContent && !items.length) {
                    return <h1 className="no-result">Aucun résultat disponible</h1>;
                } else if (letter !== '' && !items.length) {
                    return (
                        <section className={sectionClassNames}>
                            <Title text={letters} className="subtitle-alpha" />
                            <h1 className="no-results">Aucun résultat disponible</h1>
                        </section>
                    );
                } else if (!items.length) {
                    return null;
                }
                return (
                    <section className={sectionClassNames}>
                        {letter ? <Title text={letters} className="subtitle-alpha" /> : null}
                        {!isSingle ? (
                            <h3 className="section-title">
                                <Link to={fullLink}>{section.label}</Link>
                            </h3>
                        ) : null}
                        {list}
                    </section>
                );
            }}
            loadingDecorator={loading => (
                <section className={sectionClassNames}>
                    {letter ? <Title text={letters} className="subtitle-alpha" /> : null}
                    {!isSingle ? (
                        <h3 className="section-title">
                            <Link to={fullLink}>{section.label}</Link>
                        </h3>
                    ) : null}
                    {loading}
                </section>
            )}
        />
    );
};

CatalogSection.propTypes = propTypes;
CatalogSection.defaultProps = defaultProps;

export default withUrl(CatalogSection);
