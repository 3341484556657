import React, { Component } from 'react';
import classNames from 'classnames';
import Sidebar from '../../containers/partials/Sidebar';

const propTypes = {
    onSidebarScroll: React.PropTypes.func,
    sidebarClosed: React.PropTypes.bool.isRequired,
    headerCollapsed: React.PropTypes.bool.isRequired,
    children: React.PropTypes.oneOfType([
        React.PropTypes.arrayOf(React.PropTypes.node),
        React.PropTypes.node,
    ]).isRequired,
};

const defaultProps = {
    onSidebarScroll: null,
};

// eslint-disable-next-line
class WithSidebarContent extends Component {
    render() {
        const { headerCollapsed, sidebarClosed, children, onSidebarScroll } = this.props;

        const containerClassNames = classNames({
            'container-content': true,
            'container-content-with-sidebar': true,
        });

        return (
            <div className={containerClassNames}>
                <div className="left-side">
                    <Sidebar
                        closed={sidebarClosed}
                        collapsed={headerCollapsed}
                        onScroll={onSidebarScroll}
                    />
                </div>
                <div className="right-side">{children}</div>
            </div>
        );
    }
}

WithSidebarContent.propTypes = propTypes;
WithSidebarContent.defaultProps = defaultProps;

export default WithSidebarContent;
