import React from 'react';
import _ from 'lodash';
import Column from '../partials/Column';

const propTypes = {
    // type: React.PropTypes.string,
    items: React.PropTypes.array,
};

const defaultProps = {
    // type: 'default',
    items: [],
};

const Credits = (props) => {
    // console.log(props.items);

    const credits = {};
    for (let i = 0; i < props.items.length; i += 1) {
        const credit = props.items[i];
        if (!credits[credit.position] && credit.position) {
            credits[credit.position] = [];
        }
        if (credit.position && credit.bubble !== null) {
            credits[credit.position].push({
                id: credit.bubble.id,
                link: credit.bubble.snippet.link,
                name: credit.bubble.snippet.title,
                lastname: credit.bubble.lastname || credit.bubble.snippet.title,
            });
        }
    }

    Object.keys(credits).forEach((position) => {
        credits[position] = _.orderBy(credits[position], ['lastname'], ['asc']);
    });

    const cols = [];
    let currentCol = null;
    let currentNamesCount = 0;

    const itemsCount = props.items.length;
    const colsCount = itemsCount > 8 ? 2 : 1;
    const namesPerCols = Math.ceil(itemsCount / colsCount);
    let colUID = colsCount;

    Object.keys(credits).forEach((k) => {
        const creditUid = (itemsCount + 1) * (colsCount + 1);
        colUID += 1;
        if (!currentCol) {
            currentCol = {
                uid: colUID,
                items: [],
            };
        }
        currentCol.items.push({
            title: k,
            items: credits[k],
            uid: creditUid,
        });
        currentNamesCount += credits[k].length;
        if (currentNamesCount >= namesPerCols) {
            cols.push(currentCol);
            currentCol = null;
            currentNamesCount = 0;
        }
    });

    if (currentCol) {
        cols.push(currentCol);
    }

    const creditColumns = cols.map((column, k) => (
        <Column
            items={column.items}
            colName={`credit-column-${k + 1}-${column.uid}`}
            key={`credit-column-${k + 1}-${column.uid}`}
            type={`ul-${k + 1}-${column.uid}`}
        />
    ));

    return <div className="credits">{creditColumns}</div>;
};

Credits.propTypes = propTypes;
Credits.defaultProps = defaultProps;

export default Credits;
