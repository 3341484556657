import React from 'react';
import Title from '../partials/Title';
import Credits from '../partials/Credits';

const propTypes = {
    title: React.PropTypes.string,
    name: React.PropTypes.string,
    credits: React.PropTypes.array,
};

const defaultProps = {
    title: null,
    name: null,
    credits: null,
};

const CreditsSection = (props) => {
    const { credits, title, name } = props;

    if (!credits) {
        return null;
    }

    return (
        <section className="page-section page-section-credits">
            <Title text={title} className="section-title" subtitle />
            <Credits items={credits} type={name} />
        </section>
    );
};

CreditsSection.propTypes = propTypes;
CreditsSection.defaultProps = defaultProps;

export default CreditsSection;
