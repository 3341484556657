import { TimelineMax, TweenMax, RoughEase, Power0 } from 'gsap';
import * as PIXI from 'pixi.js';
import merge from 'lodash/merge';
import GlowFilter from '../filters/glow/glow';
import { getSizeWithinMax } from '../../utils/size';
import Boxes from '../actions/boxes';

class Menu {
    constructor(options = { scene: null, width: 0, height: 0 }) {
        this.setOptions(options);

        this.sprites = [];
        this.boxes = [];
        this.timeline = null;

        this.container = new PIXI.Container();
        this.containers = [this.container];

        this.ease = RoughEase.ease.config({
            template: Power0.easeNone,
            strength: 1,
            points: 40,
            taper: 'none',
            randomize: true,
            clamp: false,
        });

        this.filter = new GlowFilter(30, 2, 1, 0xff0000, 0.1); // PIXI.filters.BloomFilter();

        this.init = this.init.bind(this);
        this.resize = this.resize.bind(this);
        this.createSprite = this.createSprite.bind(this);
        this.createBox = this.createBox.bind(this);

        this.init();

        console.log('the menu?');

        return this;
    }

    setOptions(options = {}) {
        const opts = merge(
            {
                time: 1,
                transitionTime: 2,
                playTime: 5,
                root: '/img/textures/',
                toggleOverlay: () => {},
            },
            options,
        );
        this.options = opts;
    }

    init() {
        const names = ['boite_1', 'boite_2', 'boite_3', 'boite_4'];
        if (this.sprites.length > 0) {
            this.destroy();
        }
        names.map(this.createSprite);
        this.newBoxes = new Boxes({ container: this.container, ...this.options });
    }

    createSprite(name) {
        const frameData =
            PIXI.loader.resources[`${this.options.root}${name}_chars/${name}_chars.json`].data
                .frames;
        const frames = [];
        Object.keys(frameData).forEach((frameName) => {
            frames.push(PIXI.Texture.fromFrame(frameName));
        });

        const sprite = new PIXI.extras.AnimatedSprite(frames);
        sprite.loop = false;
        sprite.anchor.set(0.5, 1);
        sprite.animationSpeed = 0.1;

        const coords = this.getCoords(sprite);

        //  pixelFilter = new filters.AsciiFilter(20);
        //  pixelFilter.size = 20;
        //  bloomFilter = new pixiFilters.BloomFilter(1);
        //  sprite.filters = bloomFilter];
        //  sprite.width =  coords.width;
        //  sprite.height = coords.height;

        sprite.scale = new PIXI.ObservablePoint(null, null, coords.scale, coords.scale);
        sprite.filters = [this.filter];

        const half = this.options.width / 2;

        TweenMax.set(sprite, {
            alpha: 0,
            x: this.options.mobile ? half : half - 100,
            y: this.options.mobile ? this.options.height : this.options.height - 100,
        });

        this.container.addChild(sprite);
        this.sprites.push(sprite);
    }

    createBox(name) {
        const frameData =
            PIXI.loader.resources[`${this.options.root}${name}_boite/${name}_boite.json`].data
                .frames;
        const frames = [];
        Object.keys(frameData).forEach((frameName) => {
            frames.push(PIXI.Texture.fromFrame(frameName));
        });

        const sprite = new PIXI.extras.AnimatedSprite(frames);
        sprite.loop = false;
        sprite.anchor.set(0.5, 1);
        sprite.animationSpeed = 0.15;

        const coords = this.getCoords(sprite);

        //  pixelFilter = new filters.AsciiFilter(20);
        //  pixelFilter.size = 20;
        //  bloomFilter = new pixiFilters.BloomFilter(1);
        //  sprite.filters = bloomFilter];
        //  sprite.width =  coords.width;
        //  sprite.height = coords.height;

        sprite.scale = new PIXI.ObservablePoint(null, null, coords.scale, coords.scale);
        // sprite.filters = [this.filter];

        const half = this.options.width / 2;

        TweenMax.set(sprite, {
            alpha: 0,
            x: this.options.mobile ? half - 20 : half - 100,
            y: this.options.mobile ? this.options.height - 20 : this.options.height - 100,
        });

        // Opt-in to interactivity
        sprite.interactive = true;
        // Shows hand cursor
        sprite.buttonMode = true;
        // Pointers normalize touch and mouse
        sprite.on('pointerdown', () => {
            sprite.gotoAndPlay(0);
        });

        // this.container.addChild(sprite);
        // this.boxes.push(sprite);
    }

    resize() {
        if (this.sprite !== null && this.container !== null) {
            this.coords = this.getCoords();

            this.sprite.scale = new PIXI.ObservablePoint(
                null,
                null,
                this.coords.scale,
                this.coords.scale,
            );

            const half = this.options.width / 2;

            TweenMax.set(this.sprite, {
                alpha: 0,
                x: half - 100,
                y: this.options.height - 100,
            });
        }
    }

    getTimeline(start = 0, onComplete = null, options = {}) {
        let time = start;
        const sprite = this.sprites[options.index];
        // const box = this.boxes[options.index];
        sprite.gotoAndStop(0);
        // box.gotoAndStop(0);
        // console.log('hey', options.index, this.newBoxes.boxes[options.index]);

        const timeline = new TimelineMax({
            onStart: () => {
                sprite.gotoAndStop(0);
                // box.gotoAndStop(0);
            },
            onComplete: () => {
                if (onComplete !== null) {
                    onComplete();
                }
            },
        });
        timeline.set(sprite, { alpha: 0 });
        // timeline.set(this.boxes, { alpha: 0 });

        time += 1;

        const boxTimeline = this.newBoxes.getTimeline(0);

        timeline.add(boxTimeline, time);

        // prettier-disable
        time += 1.5;

        timeline.to(
            sprite,
            this.options.transitionTime + 1,
            {
                alpha: 1,
            },
            time,
        );

        timeline.to(
            this.filter.uniforms,
            this.options.transitionTime + 1,
            {
                outerStrength: 0,
                innerStrength: 0,
            },
            time,
        );

        time += 1.5;

        timeline.add(() => {
            if (sprite) {
                sprite.gotoAndPlay(0);
            }
        }, time);

        time += 1.8;

        timeline.add(this.newBoxes.boxes[options.index].getTimeline(), time);

        time += 2.4;

        timeline.add(() => {
            console.log('done menu');
        }, time);

        return timeline;
    }

    destroy() {
        this.containers.forEach((container) => {
            container.destroy({
                children: true,
            });
        });
        this.sprites = [];
        this.graphics = [];
    }

    getCoords(sprite) {
        return getSizeWithinMax(
            sprite.width,
            sprite.height,
            this.options.width,
            this.options.height,
            { cover: false },
        );
    }
}

export default Menu;
