import React from 'react';

const propTypes = {
    label: React.PropTypes.string,
    value: React.PropTypes.string,
};

const defaultProps = {
    label: '',
    value: '',
};

const Row = (props) => {
    const { label, value } = props;
    return (
        <div className="row">
            <div className="row-half">
                <p className="row-label">{label}</p>
            </div>
            <div className="row-half">
                <p className="row-value">{value}</p>
            </div>
        </div>
    );
};

Row.propTypes = propTypes;
Row.defaultProps = defaultProps;

export default Row;
