import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import withUrl from '../../lib/withUrl';
import CatalogPage from '../../components/pages/Catalog';
import { getCurrentSectionFromLocation } from '../../lib/utils';

const propTypes = {
    url: React.PropTypes.shape({
        route: React.PropTypes.func,
    }).isRequired,
    filters: React.PropTypes.shape({
        toJS: React.PropTypes.func,
    }).isRequired,
    sections: React.PropTypes.shape({
        filter: React.PropTypes.func,
        toJS: React.PropTypes.func,
    }).isRequired,
    location: React.PropTypes.shape({
        pathname: React.PropTypes.string,
    }).isRequired,
    prevLocation: React.PropTypes.shape({
        pathname: React.PropTypes.string,
    }),
};

const defaultProps = {
    prevLocation: null,
};

class CatalogPageContainer extends Component {
    constructor(props) {
        super(props);
        const { sections, filters, location, prevLocation, url, ...otherProps } = this.props;
        const realLocation = this.props.prevLocation !== null ? prevLocation : location;
        const currentSection = getCurrentSectionFromLocation(sections, url, realLocation);
        const selectedSections = currentSection.sections.length
            ? currentSection.sections.map((section) => {
                const newSection = Object.assign(
                    {
                        parent: currentSection,
                    },
                      section,
                  );
                return newSection;
            })
            : [currentSection];

        this.state = {
            selectedSections,
            otherProps,
            filters,
            sections,
        };
    }

    render() {
        const { sections, filters } = this.props;

        const allFilters = filters.toJS();

        // const cleanFilters = {};
        //
        // Object.keys(allFilters).forEach((name) => {
        //     if (isArray(allFilters[name])) {
        //         for (let i = 0; i < allFilters[name].length; i += 1) {
        //             let count = 0;
        //             if (allFilters[name][i] === '') {
        //                 count += 1;
        //             }
        //             if (count < allFilters[name].length) {
        //                 cleanFilters[name] = allFilters[name];
        //             }
        //         }
        //     } else if (allFilters[name]) {
        //         cleanFilters[name] = allFilters[name];
        //     }
        // });

        const title = allFilters.search_search ? 'Recherche' : 'Vitrine';

        return (
            <CatalogPage
                {...this.state.otherProps}
                searchTitle={title}
                filters={allFilters}
                allSections={sections.toJS()}
                sections={this.state.selectedSections}
            />
        );
    }
}

CatalogPageContainer.propTypes = propTypes;
CatalogPageContainer.defaultProps = defaultProps;

export default withUrl(
    withRouter(
        connect(state => ({
            sections: state.sections,
            filters: state.filters,
        }))(CatalogPageContainer),
    ),
);
