import { TimelineLite, TweenMax } from 'gsap';
import * as PIXI from 'pixi.js';
import { getSizeWithinMax } from '../../utils/size';

class VideoComponent {
    constructor(options = { renderer: null, scene: null, wait: 4 }) {
        this.video = null;
        this.timeline = null;
        this.texture = null;

        this.container = new PIXI.Container();
        this.topContainer = new PIXI.Container();

        this.init = this.init.bind(this);
        this.create = this.create.bind(this);
        this.resize = this.resize.bind(this);
        this.destroy = this.destroy.bind(this);

        this.getTimeline = this.getTimeline.bind(this);
        this.options = options;

        return this;
    }

    init() {
        this.create();
    }

    getCoords() {
        return getSizeWithinMax(
            this.video.width,
            this.video.height,
            this.options.width,
            this.options.height,
            { cover: false },
        );
    }

    create() {
        if (!this.options.mobile) {
            const it = PIXI.loader.resources[this.options.src];
            this.texture = PIXI.Texture.fromVideo(it.data);
            this.source = this.texture.baseTexture.source;
            this.source.loop = true;
            this.source.muted = true;
            this.source.playsInline = true;
            this.source.pause();

            this.video = new PIXI.Sprite(this.texture);
            this.video.zIndex = 1;

            this.video.interactive = false;
            this.video.buttonMode = false;
        } else {
            this.texture = PIXI.loader.resources[this.options.altSrc];
            this.video = new PIXI.Sprite(this.texture.texture);
        }

        this.coords = this.getCoords();
        this.resize();

        TweenMax.set(this.video, {
            alpha: 0,
            x: (this.options.width - this.video.width) / 2,
            y: (this.options.height - this.video.height) / 2,
        });

        this.container.addChild(this.video);
    }

    getTimeline(start = 0, onComplete = null) {
        let time = start;
        const timeline = new TimelineLite({
            onStart: () => {
                if (this.source && typeof this.source.play === 'function') {
                    this.source.play();
                }
            },
            onComplete: () => {
                if (this.source && typeof this.source.play === 'function') {
                    this.source.pause();
                }
                if (onComplete !== null) {
                    onComplete();
                }
            },
        });
        timeline.set(this.video, { alpha: 0 });

        timeline.to(
            this.video,
            2,
            {
                alpha: 1,
            },
            time,
        );

        time += this.options.wait || 4;

        timeline.to(
            this.video,
            2,
            {
                alpha: 0,
            },
            time,
        );

        return timeline;
    }

    destroy() {
        this.container.removeChild(this.video);
    }

    resize() {
        this.coords = this.getCoords();
        this.video.width = this.coords.width;
        this.video.height = this.coords.height;
    }
}

export default VideoComponent;
