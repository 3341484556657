/* global TweenMax */
import React, { Component } from 'react';
import classNames from 'classnames';
import Link from './Link';

const propTypes = {
    visible: React.PropTypes.bool,
    toggleButton: React.PropTypes.bool,
    small: React.PropTypes.bool,
    horizontal: React.PropTypes.bool,
    onToggleClick: React.PropTypes.func,
    route: React.PropTypes.string,
    href: React.PropTypes.string,
    home: React.PropTypes.bool,

    redArrow: React.PropTypes.bool,
    color: React.PropTypes.string,
    subtitleFirstLetter: React.PropTypes.string,
    subtitleRemainder: React.PropTypes.string,
};

const defaultProps = {
    visible: true,
    toggleButton: true,
    small: false,
    horizontal: false,
    onToggleClick: null,
    route: 'home',
    href: null,
    home: false,

    color: '#000000',
    redArrow: false,
    subtitleFirstLetter: 'A',
    subtitleRemainder: 'rchives',
};

class Logo extends Component {
    constructor(props) {
        super(props);

        this.logo = null;
    }

    componentDidMount() {
        this.toggleVisible();
    }

    componentDidUpdate(prevProps) {
        const visibleChanged = this.props.visible !== prevProps.visible;
        if (visibleChanged) {
            this.toggleVisible();
        }
    }

    toggleVisible() {
        TweenMax.to(this.logo, 2, {
            alpha: this.props.visible ? 1 : 0,
        });
    }

    renderContent() {
        const { color, redArrow, subtitleFirstLetter, subtitleRemainder } = this.props;

        const textStyle = {
            color,
        };

        const iconClassNames = classNames({
            icon: true,
            'icon-return-small': true,
            red: redArrow,
        });

        return (
            <div>
                <strong className="title" style={textStyle}>
                    <span className="letter show-scrolled">N</span>
                    <span className="word">ouveau</span>{' '}
                    <span className="letter show-scrolled">T</span>
                    <span className="word">héâtre</span>{' '}
                    <span className="letter show-scrolled">E</span>
                    <span>xpérimental</span>
                    <span className="icon icon-return-small" />
                    <span className="show-scrolled">A</span>
                </strong>
                <strong className="subtitle" style={textStyle}>
                    <span className={iconClassNames} />
                    <span className="letter">{subtitleFirstLetter}</span>
                    <span>{subtitleRemainder}</span>
                </strong>
            </div>
        );
    }

    render() {
        const { small, horizontal, toggleButton, onToggleClick, route, href, home } = this.props;

        const logoClassNames = classNames({
            logo: true,
            small,
            horizontal,
            vertical: !horizontal,
            homeGlow: home === true,
        });

        const content = this.renderContent();

        return (
            <div
                className={logoClassNames}
                ref={(ref) => {
                    this.logo = ref;
                }}
            >
                <h1>
                    {href !== null ? (
                        <a href={href}>{content}</a>
                    ) : (
                        <Link route={route}>{content}</Link>
                    )}
                </h1>
                {toggleButton ? (
                    <button type="button" className="menu-toggle" onClick={onToggleClick} />
                ) : null}
            </div>
        );
    }
}

Logo.propTypes = propTypes;
Logo.defaultProps = defaultProps;

export default Logo;
