import Relay, { Route } from 'react-relay';

class BubbleRoute extends Route
{
    static routeName = 'Bubble';

    static queries = {
        bubble: () => Relay.QL`query { bubble(url: $url) }`,
    };

    static paramDefinitions = {
        url: {
            required: true,
        },
    };
}

export default BubbleRoute;
