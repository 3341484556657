import React, { Component } from 'react';
import classNames from 'classnames';

const propTypes = {
    hidden: React.PropTypes.bool,
    letter: React.PropTypes.string,
    onLetterChange: React.PropTypes.func.isRequired,
    onDirectionChange: React.PropTypes.func.isRequired,
};

const defaultProps = {
    hidden: false,
    letter: '',
};

class AZList extends Component {

    constructor(props) {
        super(props);
        this.alphabet = '0-9ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

        this.onClick = this.onClick.bind(this);
        this.onUp = this.onUp.bind(this);
        this.onDown = this.onDown.bind(this);

        this.state = {
            direction: 'asc',
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.direction !== prevState.direction) {
            this.props.onDirectionChange(this.state.direction);
        }
    }

    onClick(letter) {
        if (this.props.letter === letter) {
            this.props.onLetterChange('');
        } else {
            this.props.onLetterChange(letter);
        }
    }

    onUp() {
        this.setState({
            direction: 'desc',
        });
    }

    onDown() {
        this.setState({
            direction: 'asc',
        });
    }

    render() {
        const { direction } = this.state;

        const listItems = this.alphabet.map((item, key) => {
            if (key % 3 === 0) {
                const A = this.alphabet[key] || '';
                const B = this.alphabet[key + 1] || '';
                const C = this.alphabet[key + 2] || '';

                const itemClassNames = classNames({
                    'toolbar-item': true,
                    active: A !== '' && A.toUpperCase() === this.props.letter,
                });

                return (
                    <button
                        className={itemClassNames}
                        key={A}
                        onClick={() => { this.onClick(A); }}
                    >
                        <span>{A}</span>
                        { B ? (
                            <span>{B}</span>
                        ) : null }
                        { C ? (
                            <span>{C}</span>
                        ) : null}
                    </button>
                );
            }
            return null;
        });

        const toolbarClassNames = classNames({
            toolbar: true,
            'toolbar-top': true,
            'toolbar-hidden': this.props.hidden,
        });

        const itemClass = {
            'toolbar-item': true,
            'toolbar-icon': true,
        };

        const upClassNames = classNames({
            ...itemClass,
            'icon-arrow-up': true,
            active: direction === 'desc',
        });

        const downClassNames = classNames({
            ...itemClass,
            'icon-arrow-down': true,
            active: direction === 'asc',
        });

        return (
            <div className={toolbarClassNames}>

                <div className="toolbar-group toolbar-group-left">
                    <button className={upClassNames} onClick={this.onUp} />
                    <button className={downClassNames} onClick={this.onDown} />
                </div>

                <div className="toolbar-group center">
                    {listItems}
                </div>

            </div>
        );
    }
}

AZList.propTypes = propTypes;
AZList.defaultProps = defaultProps;

export default AZList;
