import React from 'react';
import classNames from 'classnames';

const propTypes = {
    // sidebarClosed: React.PropTypes.bool.isRequired,
    // headerCollapsed: React.PropTypes.bool.isRequired,
    children: React.PropTypes.oneOfType([
        React.PropTypes.arrayOf(React.PropTypes.node),
        React.PropTypes.node,
    ]).isRequired,
};

const FullContent = (props) => {
    const { children } = props;

    const layoutClassNames = classNames({
        'container-content': true,
        'container-content-full': true,
    });

    return <div className={layoutClassNames}>{children}</div>;
};

FullContent.propTypes = propTypes;

export default FullContent;
