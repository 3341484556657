import React, { Component } from 'react';
import Relay from 'react-relay';
import { pascal as pascalCase } from 'change-case';
import classNames from 'classnames';
// import ReactList from 'react-list';
import throttle from 'lodash/throttle';
import Link from './partials/Link';
import ItemComponents from './items/index';

import { getWidth } from '../lib/getSize';
import GridNormalIcon from './partials/icons/GridNormal';
import GridVerticalIcon from './partials/icons/GridVertical';
import LoadingIcon from './partials/icons/Loading';
import BackUpIcon from './partials/icons/BackUp';

const propTypes = {
    setView: React.PropTypes.func.isRequired,
    itemSize: React.PropTypes.string,
    sectionSize: React.PropTypes.string.isRequired,
    isSingle: React.PropTypes.bool,
    view: React.PropTypes.string,
    pageSize: React.PropTypes.number,
    scrollThreshold: React.PropTypes.number,
    preview: React.PropTypes.bool,
    loadOnScroll: React.PropTypes.bool,
    decorator: React.PropTypes.func,
    relay: React.PropTypes.shape({
        setVariables: React.PropTypes.func,
        pendingVariables: React.PropTypes.shape({
            pageSize: React.PropTypes.number,
            letter: React.PropTypes.string,
        }),
        variables: React.PropTypes.shape({
            pageSize: React.PropTypes.number,
            letter: React.PropTypes.string,
        }),
    }),
    linkToAll: React.PropTypes.string,
    list: React.PropTypes.shape({
        items: React.PropTypes.shape({
            edges: React.PropTypes.arrayOf(
                React.PropTypes.shape({
                    node: React.PropTypes.shape({
                        type: React.PropTypes.string,
                    }),
                }),
            ),
            pageInfo: React.PropTypes.shape({
                hasNextPage: React.PropTypes.bool,
            }),
        }),
    }).isRequired,
    letter: React.PropTypes.string,
    // scrollY: React.PropTypes.number,
};

const defaultProps = {
    view: 'grid',
    itemSize: 'large',
    isSingle: true,
    pageSize: 6,
    decorator: null,
    renderPageSize: 10,
    scrollThreshold: 600,
    preview: false,
    loadOnScroll: false,
    linkToAll: null,
    relay: null,
    letter: '',
    // scrollY: 0,
};

class BubblesList extends Component {
    constructor(props) {
        super(props);

        this.ticking = false;

        this.onGridToggleClick = this.onGridToggleClick.bind(this);
        this.onListToggleClick = this.onListToggleClick.bind(this);

        this.renderItem = this.renderItem.bind(this);
        this.renderDirectItem = this.renderDirectItem.bind(this);

        this.onPageScroll = this.onPageScroll.bind(this);
        this.onPageScroll = throttle(this.onPageScroll, 600, {
            leading: true,
            trailing: true,
        });

        this.state = {
            finishedLoading: false,
            loadingCount: 0,
        };
    }

    componentDidMount() {
        if (this.props.loadOnScroll) {
            window.addEventListener('scroll', this.onPageScroll);
        }
        const width = getWidth();
        const hasScroll = document.body.scrollHeight > width;

        if (!hasScroll) {
            this.onPageScroll();
        }
    }

    // componentDidUpdate(prevProps) {
    // console.log('pp', prevProps);
    // if (this.props.loadOnScroll && prevProps.scrollY !== this.props.scrollY) {
    //     this.scroll(this.props.scrollY);
    // }
    // }

    componentWillUnmount() {
        if (this.props.loadOnScroll) {
            window.removeEventListener('scroll', this.onPageScroll);
        }
    }

    onPageScroll() {
        if (!this.props.loadOnScroll) {
            return;
        }

        if (!this.ticking) {
            const scrollTop =
                window.scrollY ||
                (document.documentElement && document.documentElement.scrollTop) ||
                document.body.scrollTop;
            window.requestAnimationFrame(() => {
                this.scroll(scrollTop);
                // console.log('scroll page');
                this.ticking = false;
            });
        }
        this.ticking = true;
    }

    onGridToggleClick(e) {
        e.preventDefault();
        this.props.setView('grid');
    }

    onListToggleClick(e) {
        e.preventDefault();
        this.props.setView('list');
    }

    scroll(scrollTop) {
        // console.log('hum 4 real');
        const { finishedLoading, loadingCount } = this.state;
        const { scrollThreshold, relay, list } = this.props;

        // const scrollTop = element.scrollTop || document.documentElement.scrollTop;
        const scrollHeight = document.body.scrollHeight;
        const clientHeight = window.innerHeight;
        const isLoading = relay.pendingVariables;
        const hasNextPage = list.items.pageInfo.hasNextPage;

        const reachedEnd = scrollHeight - scrollTop - scrollThreshold <= clientHeight;

        // const body = document.body;
        // const html = document.documentElement;
        // const height = Math.max(body.scrollHeight, body.offsetHeight,
        // html.clientHeight, html.scrollHeight, html.offsetHeight);
        // console.log('scroll', document.documentElement.scrollTop,
        // scrollTop, (scrollHeight - scrollTop), clientHeight, height);

        const shouldLoad = list.items.edges.length !== loadingCount;

        if (reachedEnd && !isLoading && hasNextPage && shouldLoad) {
            this.setState(
                {
                    loadingCount: list.items.edges.length,
                },
                () => {
                    relay.setVariables({
                        letter: this.props.letter,
                        pageSize: relay.variables.pageSize + this.props.pageSize,
                    });
                },
            );
        }

        if (!hasNextPage && !finishedLoading) {
            this.setState({
                finishedLoading: true,
            });
        }
    }

    renderItem(index, key) {
        const edge = this.props.list.items.edges[index];
        const componentName = pascalCase(edge.node.type);
        const ItemComponent = (ItemComponents[componentName] || ItemComponents.Bubble)
            .RelayComponent;
        return (
            <ItemComponent
                key={key}
                bubble={edge.node}
                view={this.props.view}
                itemSize={this.props.itemSize}
            />
        );
    }

    renderDirectItem(edge, key) {
        // const edge = this.props.list.items.edges[index];
        const componentName = pascalCase(edge.node.type);
        const ItemComponent = (ItemComponents[componentName] || ItemComponents.Bubble)
            .RelayComponent;
        return (
            <div className="list-table-cell" key={key}>
                <ItemComponent
                    bubble={edge.node}
                    view={this.props.view}
                    itemSize={this.props.itemSize}
                />
            </div>
        );
    }

    render() {
        const { list } = this.props;
        const { finishedLoading, loadingCount } = this.state;

        const {
            decorator,
            preview,
            view,
            // loadOnScroll,
            linkToAll,
            isSingle,
            sectionSize,
        } = this.props;

        const itemsLength = this.props.list.items.edges.length;
        const loadingAnimation =
            loadingCount === itemsLength && sectionSize === 'large' && linkToAll ? (
                <div className="inner">
                    <LoadingIcon />
                </div>
            ) : null;

        const listClassNames = classNames({
            list: true,
            'list-preview': preview,
            'list-alpha': isSingle,
            'list-with-view-more': linkToAll && preview,
        });

        const iconGridClassNames = classNames({
            btn: true,
            'btn-toolbar': true,
            'btn-svg': true,
            'icon-grid-small': true,
            active: view === 'grid',
        });

        const listItemsClassNames = classNames({
            'list-items': true,
            cards: view === 'grid',
        });

        const iconListClassNames = classNames({
            btn: true,
            'btn-toolbar': true,
            'btn-svg': true,
            'icon-list-small': true,
            active: view === 'list',
        });

        // console.log(isSingle, linkToAll);

        const contents = (
            <div className={listClassNames}>
                <div className="toolbar only-right">
                    <div className="toolbar-group toolbar-group-expand toolbar-group-right">
                        <ul>
                            {isSingle ? (
                                <div>
                                    <li className="toolbar-item">
                                        <button
                                            type="button"
                                            className={iconGridClassNames}
                                            onClick={this.onGridToggleClick}
                                        >
                                            <GridNormalIcon
                                                width={30}
                                                height={24}
                                                color={view === 'grid' ? '#3333CC' : '#000000'}
                                                stroke={view === 'grid' ? '#3333CC' : '#000000'}
                                            />
                                        </button>
                                    </li>
                                    {this.props.itemSize !== 'small' ? (
                                        <li className="toolbar-item">
                                            <button
                                                type="button"
                                                className={iconListClassNames}
                                                onClick={this.onListToggleClick}
                                            >
                                                <GridVerticalIcon
                                                    width={30}
                                                    height={24}
                                                    color={view !== 'grid' ? '#3333CC' : '#000000'}
                                                    stroke={view !== 'grid' ? '#3333CC' : '#000000'}
                                                />
                                            </button>
                                        </li>
                                    ) : null}
                                </div>
                            ) : null}
                            {linkToAll && preview ? (
                                <li className="toolbar-item">
                                    <Link
                                        to={linkToAll}
                                        className="btn btn-toolbar btn-with-icon-and-label icon-view-all"
                                    >
                                        <span>
                                            <span className="pad">Voir tout</span>
                                        </span>
                                    </Link>
                                </li>
                            ) : null}
                        </ul>
                    </div>
                </div>
                <div className={listItemsClassNames}>
                    {list.items.edges.map(this.renderDirectItem)}
                    <div className="clear" />
                </div>
                {sectionSize === 'large' && linkToAll ? (
                    <div className="list-section-loading-indicator">
                        {!finishedLoading ? (
                            loadingAnimation
                        ) : (
                            <div className="inner">
                                <a href="#">
                                    <BackUpIcon />
                                </a>
                            </div>
                        )}
                    </div>
                ) : null}
            </div>
        );

        if (decorator) {
            return decorator(contents, this.props.list.items.edges);
        }

        return contents;
    }
}

// <ReactList
//     threshold={this.props.scrollThreshold}
//     itemRenderer={this.renderItem}
//     length={itemsLength}
//     pageSize={Math.round(this.props.pageSize)}
//     letter={this.props.letter}
//     type={!loadOnScroll ? 'simple' : 'uniform'}
//     useTranslate3d
// />
// <div className="clear" />

BubblesList.propTypes = propTypes;
BubblesList.defaultProps = defaultProps;

const BubblesRelayComponents = [];
Object.keys(ItemComponents).forEach((key) => {
    const ItemComponent = ItemComponents[key];
    if (ItemComponent.RelayComponent) {
        BubblesRelayComponents.push(ItemComponent.RelayComponent);
    }
});

BubblesList.RelayComponent = Relay.createContainer(BubblesList, {
    initialVariables: {
        pageSize: 6,
        filters: {},
        letter: 'A',
    },

    fragments: {
        list: () =>
            Relay.QL`
                fragment on BubblesList {
                    items(
                        first: $pageSize,
                        filters: $filters,
                        letter: $letter,
                    ) {
                        edges {
                            cursor
                            node {
                                id
                                type
                                ${BubblesRelayComponents.map(BubbleComponent =>
                                    BubbleComponent.getFragment('bubble'),
                                )}
                            }
                        }
                        pageInfo {
                            hasNextPage
                            hasPreviousPage
                            startCursor
                            endCursor
                        }
                    }
                }
            `,
    },
});

export default BubblesList;
