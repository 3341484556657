import React from 'react';
import classNames from 'classnames';
import LoadingItem from '../items/Loading';

const propTypes = {
    name: React.PropTypes.string,
    itemSize: React.PropTypes.string,
    sectionSize: React.PropTypes.string,
    pageSize: React.PropTypes.number,
    view: React.PropTypes.string,
};

const defaultProps = {
    name: 'default-section',
    itemSize: 'large',
    sectionSize: 'large',
    pageSize: 3,
    view: 'grid',
};

const LoadingSection = (props) => {
    const { name, pageSize, itemSize, sectionSize, view } = props;
    const loadingItems = [];
    for (let i = 0; i < pageSize; i += 1) {
        loadingItems.push(
            <LoadingItem itemSize={itemSize} view={view} key={`${name}-item-${i}`} />,
        );
    }

    const listClassNames = classNames({
        'list-section-loading': true,
        'list-items-small': sectionSize === 'small',
    });

    return (
        <div className={listClassNames}>
            {loadingItems}
            <div className="clear" />
        </div>
    );
};

LoadingSection.propTypes = propTypes;
LoadingSection.defaultProps = defaultProps;

export default LoadingSection;
