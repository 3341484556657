import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import withUrl from '../../lib/withUrl';
import FooterComponent from '../../components/partials/Footer';
import LayoutActions from '../../actions/LayoutActions';

const propTypes = {
    layout: React.PropTypes.shape({
        get: React.PropTypes.func,
    }).isRequired,
    dispatch: React.PropTypes.func.isRequired,
};

const FooterContainer = (props) => {
    const { layout, dispatch, ...otherProps } = props;
    const layoutProps = layout.toJS();
    const actionsProps = bindActionCreators(LayoutActions, dispatch);
    return <FooterComponent {...otherProps} {...layoutProps} {...actionsProps} />;
};

FooterContainer.propTypes = propTypes;

export default withUrl(
    withRouter(
        connect((state) => ({
            layout: state.layout,
        }))(FooterContainer),
    ),
);
