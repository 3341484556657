import Immutable from 'immutable';
import {
    BUBBLES_ADD,
} from '../actions/ActionTypes';

const initialState = {};

const BubblesReducer = (previousState, action) => {
    const state = previousState || new Immutable.Map(initialState);

    switch (action.type) {
    case BUBBLES_ADD:
        return state.set(action.payload.url, action.payload.data);
    default:
        return state;
    }
};

export default BubblesReducer;
