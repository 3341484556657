import React from 'react';
import Relay from 'react-relay';

import Title from '../partials/Title';
import Meta from '../partials/Meta';
import Footer from '../../containers/partials/Footer';

import Gallery from '../Gallery';

import DescriptionSection from '../sections/Description';
import CreditsTextSection from '../sections/CreditsText';
import BubblesListSection from '../sections/BubblesList';

const propTypes = {
    bubble: React.PropTypes.shape({
        bubble_id: React.PropTypes.string,
        snippet: React.PropTypes.shape({
            link: React.PropTypes.string,
            title: React.PropTypes.string,
            subtitle: React.PropTypes.string,
        }),
        description: React.PropTypes.string,
        credits_technicians: React.PropTypes.string,
        credits_other: React.PropTypes.string,
        gallery: React.PropTypes.object,
    }).isRequired,
    newsletter: React.PropTypes.object,
};

const BubbleGalleryPage = (props) => {
    const id = props.bubble.bubble_id;
    const { snippet, description, gallery } = props.bubble;
    const { title, subtitle } = snippet;

    const creditsTechnicians = props.bubble.credits_technicians;
    const creditsOther = props.bubble.credits_other;

    const { newsletter = null } = props || {};

    return (
        <article className="container-page container-page-gallery">
            <Meta bubble={props.bubble} section="Galerie de photos" />

            {title ? (
                <div className="container">
                    <Title text={title} newsletter={newsletter} />
                </div>
            ) : null}

            <Gallery gallery={gallery} defaultCredits={creditsOther} />

            <div className="container">
                <DescriptionSection title={subtitle} description={description} />

                <CreditsTextSection title="Techniciens" credits={creditsTechnicians} />

                <BubblesListSection name={`shows:${id}`} title="Productions" />

                <Footer />
            </div>
        </article>
    );
};

BubbleGalleryPage.propTypes = propTypes;

BubbleGalleryPage.RelayComponent = Relay.createContainer(BubbleGalleryPage, {
    fragments: {
        bubble: () =>
            Relay.QL`
                fragment on BubbleGallery {
                    bubble_id
                    snippet {
                        title
                        subtitle
                        link
                        picture_facebook: picture(filter:"facebook") {
                            url
                        }
                    }
                    gallery {
                        title
                        slides {
                            title
                            subtitle
                            description
                            picture_full: picture(filter:"big"){
                                url
                            }
                            picture_mini: picture(filter:"mini"){
                                url
                            }
                            picture_thumbnail: picture(filter:"thumbnail"){
                                url
                            }
                        }
                    }
                    credits_technicians
                    credits_other
                    description
                }
            `,
    },
});

export default BubbleGalleryPage;
