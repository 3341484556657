import React from 'react';
import { RootContainer } from 'react-relay';
import { connect } from 'react-redux';
import { updateFilter, clearFilter } from '../actions/FiltersActions';

import FiltersComponent from '../components/Filters';
import FiltersRoute from '../routes/Filters';

const propTypes = {
    names: React.PropTypes.arrayOf(React.PropTypes.string).isRequired,
    filters: React.PropTypes.shape({
        toJS: React.PropTypes.func.isRequired,
    }).isRequired,
    dispatch: React.PropTypes.func.isRequired,
};

const FiltersContainer = (props) => {
    const { dispatch, filters } = props;
    return (
        <RootContainer
            Component={FiltersComponent.RelayComponent}
            route={
                new FiltersRoute({
                    names: props.names,
                })
            }
            renderFetched={(data) => (
                <FiltersComponent.RelayComponent
                    {...data}
                    values={filters.toJS()}
                    updateFilter={(name, value) => dispatch(updateFilter(name, value))}
                    clearFilter={(name) => dispatch(clearFilter(name))}
                />
            )}
            renderLoading={() => null}
        />
    );
};

FiltersContainer.propTypes = propTypes;

const withConnect = connect((state) => ({
    filters: state.filters,
}))(FiltersContainer);

export default withConnect;
