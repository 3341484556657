import { TimelineLite, TweenMax, RoughEase, Power0 } from 'gsap';
import * as PIXI from 'pixi.js';
import merge from 'lodash/merge';
import GlowFilter from '../filters/glow/glow';
import Box from './box';
import BeepSound from '../../sounds/beep';
import { getSizeWithinMax } from '../../utils/size';

class Boxes {
    constructor(options = { container: null, width: 0, height: 0 }, cues = []) {
        this.setOptions(options);

        this.active = true;
        this.timeline = null;

        this.boxes = [];
        this.sprites = [];
        this.active = {};
        this.sound = new BeepSound();

        this.container = options.container;
        this.containers = [this.container];

        this.ease = RoughEase.ease.config({
            template: Power0.easeNone,
            strength: 1,
            points: 40,
            taper: 'none',
            randomize: true,
            clamp: false,
        });

        this.filter = new GlowFilter(30, 2, 1, 0xff0000, 0.1); // PIXI.filters.BloomFilter();

        this.create = this.create.bind(this);
        this.resize = this.resize.bind(this);

        this.onClick = this.onClick.bind(this);
        this.onResume = this.onResume.bind(this);

        this.onHoverIn = this.onHoverIn.bind(this);
        this.onHoverOut = this.onHoverOut.bind(this);

        this.create(cues);

        return this;
    }

    setOptions(options = {}) {
        const opts = merge(
            {
                time: 1,
                transitionTime: 1.4,
                wait: 2,
                label: null,
                labels: [],
                root: '/img/textures/',
                onClick: () => {},
            },
            options,
        );
        this.options = opts;
    }

    onClick(boxIndex) {
        if (this.timeline !== null) {
            this.timeline.pause();
        }

        if (this.boxes.length > 0) {
            this.boxes.forEach((box) => {
                if (box.index !== boxIndex) {
                    if (box.closeTimeline !== null) {
                        box.closeTimeline.kill();
                        box.closeTimeline = null;
                    }
                    if (box.openTimeline !== null) {
                        box.openTimeline.kill();
                        box.openTimeline = null;
                    }
                    if (box.hoverTimeline !== null) {
                        box.hoverTimeline.kill();
                        box.hoverTimeline = null;
                    }
                }
            });
        }
    }

    onHoverIn(boxIndex) {
        if (!this.active) return;

        if (this.timeline !== null) {
            this.timeline.pause();
        }

        if (this.boxes.length > 0) {
            this.boxes.forEach((box) => {
                if (box.index !== boxIndex && box.sound) {
                    box.sound.stop();
                    if (box.closeTimeline !== null) {
                        box.closeTimeline.stop();
                        box.closeTimeline.play(0);
                    }
                }
            });
        }
    }

    onHoverOut() {
        if (!this.active) return;

        if (this.timeline !== null) {
            this.timeline.pause();
        }

        if (this.boxes.length > 0) {
            this.boxes.forEach((box) => {
                box.sound.stop();
                if (box.closeTimeline !== null) {
                    box.closeTimeline.stop();
                    box.closeTimeline.play(0);
                }
            });
        }
    }

    onResume() {
        if (this.timeline !== null) {
            this.timeline.play();
        }
    }

    create() {
        this.createSprite();
        this.createBoxes();
    }

    resize(props) {
        this.setOptions(props || {});

        if (this.timeline !== null) {
            this.timeline.kill();
            this.timeline = null;
        }

        this.boxes.forEach((box) => {
            box.destroy();
        });

        this.boxes = [];
        this.container.children.forEach((c) => {
            c.destroy();
        });

        this.createSprite();
        this.createBoxes();
        this.timeline = this.getTimeline(); // Nuclear option
        TweenMax.to(this.sprites, 1, { alpha: 1 });
    }

    createSprite() {
        const rname = 'boite_0';
        const name = `${this.options.root}${rname}_chars/${rname}_chars.json`;
        this.active[name] = false;
        const frameData = PIXI.loader.resources[name].data.frames;

        const frames = [];
        Object.keys(frameData).forEach((frameName) => {
            frames.push(PIXI.Texture.fromFrame(frameName));
        });

        const sprite = new PIXI.extras.AnimatedSprite(frames);
        sprite.loop = false;
        sprite.anchor.set(0.5, 1);
        sprite.animationSpeed = 0.08;

        const coords = this.getCoords(sprite);

        sprite.scale = new PIXI.ObservablePoint(null, null, coords.scale, coords.scale);
        sprite.filters = [this.filter];
        sprite.interactive = false;
        sprite.buttonMode = false;
        sprite.hitArea = new PIXI.Rectangle(0, 0, 0, 0);

        const half = this.options.width / 2;

        TweenMax.set(sprite, {
            alpha: 0,
            x: this.options.mobile ? half - 20 : half - 100,
            y: this.options.mobile ? this.options.height - 20 : this.options.height - 100,
        });

        this.container.addChild(sprite);
        this.sprites.push(sprite);
    }

    createBoxes() {
        const { height, width, mobile } = this.options;
        const frameSize = getSizeWithinMax(2448, 1632, width, height, {
            cover: false,
        });

        const offsetX = Math.max(width - frameSize.width, 0) / 2;
        const offsetY = Math.max(height - frameSize.height, 0);

        const ratio = frameSize.scale;
        const boxWidth = Math.floor(416 * ratio);
        const boxHeight = Math.floor(300 * ratio);
        const bottom = boxHeight + (mobile ? 30 : 80);
        const gap = boxWidth / 5; // eslint-disable-line
        const bottomHeight = frameSize.height + offsetY - bottom; // eslint-disable-line
        const boxes = [
            // prettier-disable
            { label: 'nte', x: gap + offsetX + boxWidth, y: bottomHeight },
            // prettier-disable
            { label: 'histoire', x: gap * 2 + offsetX + boxWidth * 2, y: bottomHeight }, //  eslint-disable-line
            // prettier-disable  eslint-disable-next-line
            { label: 'reflexions', x: gap * 3 + offsetX + boxWidth * 3, y: bottomHeight }, //  eslint-disable-line
            // prettier-disable  eslint-disable-next-line
            { label: 'rencontres', x: gap * 4 + offsetX + boxWidth * 4, y: bottomHeight }, //  eslint-disable-line
        ];

        boxes.forEach((box, idx) =>
            this.createBox(box, idx, {
                ...this.options,
                ...box,
                ratio: frameSize.scale,
                onHoverInParent: this.onHoverIn,
                onHoverOutParent: this.onHoverOut,
                onClickParent: this.onClick,
            }),
        );

        this.boxes.forEach((box) => {
            box.addBackground();
            this.container.addChild(box.animationContainer);
        });

        this.boxes.forEach((box) => {
            box.addForeground();
            this.container.addChild(box.container);
        });

        this.boxes.forEach((box, idx) => {
            const bxs = this.boxes.filter((b, i) => i !== idx);
            box.addSiblings(bxs);
        });
    }

    createBox(box, idx, options) {
        if (!box) return;
        const currentBox = new Box(box, this.container, idx, options, this.sprites);
        this.boxes.push(currentBox);
    }

    getTimeline(time = 0) {
        const timeline = new TimelineLite();
        const containers = this.boxes.map(box => box.container);
        const speed = 0.8;
        timeline.staggerTo(
            containers,
            speed,
            {
                alpha: 1,
                delay: 1,
            },
            0.25,
            time,
        );

        timeline.add(() => {
            this.sound.play();
        }, time + 1);

        return timeline;
    }

    fadeOut(box = '', timeline = null, start = 0) {
        const time = start;
        timeline.fromTo(
            box,
            this.options.transitionTime,
            {
                alpha: 1,
            },
            {
                alpha: 0,
            },
            time,
        );
        return timeline;
    }

    fadeIn(box = '', timeline = null, start = 0) {
        const time = start;
        timeline.fromTo(
            box,
            this.options.transitionTime,
            {
                alpha: 0,
            },
            {
                alpha: 1,
            },
            time,
        );
        return timeline;
    }

    getCoords(sprite) {
        return getSizeWithinMax(
            sprite.width,
            sprite.height,
            this.options.width,
            this.options.height,
            { cover: false },
        );
    }

    destroy() {
        this.active = false;
        this.boxes.forEach((box) => {
            box.destroy();
        });
        if (this.timeline !== null) {
            this.timeline.kill();
            this.timeline = null;
        }
        this.boxes = [];
        if (this.sound) {
            this.sound.destroy();
        }
    }
}

export default Boxes;
