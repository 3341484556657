import { TimelineMax } from 'gsap';
import * as PIXI from 'pixi.js';
import merge from 'lodash/merge';
import { getSizeWithinMax } from '../utils/size';
import Grid from './grid';

class Viewer {
    constructor(props = { width: 0, height: 0 }) {
        this.props = merge(
            {
                segments: {},
                innerWidth: 200,
                innerHeight: 100,
            },
            props,
        );

        this.grid = null;
        this.actions = [];

        this.container = new PIXI.Container();
        this.fullContainer = new PIXI.Container();

        this.create();
        return this;
    }

    getTimeline(start = 0) {
        const { segments } = this.props;
        const timeline = new TimelineMax();

        if (segments) {
            Object.keys(segments).forEach((timestamp) => {
                const cue = segments[timestamp];
                const options = { ...cue, ...cue.options };
                timeline.add(this.grid.getTimelineForCue(options), cue.time);
                // timeline.add(() => {
                //     console.log('new call', cue.time);
                // }, cue.time);
                // TODO: would be better, add last call at sound end systematically ?
            });
            timeline.add(this.grid.getCaptions(segments), 0);
        }

        return timeline;
    }

    getActionsTimeline(start = 0) {
        const { segments } = this.props;
        const timeline = new TimelineMax();

        let i = 0;
        Object.keys(segments).forEach((timestamp) => {
            const cue = segments[timestamp];
            const options = { ...cue, ...cue.options };
            if (options.overlay && this.actions[i]) {
                timeline.add(this.actions[i].getTimeline(), cue.time);
                i += 1;
            }
        });

        return timeline;
    }

    create() {
        const {
            width,
            height,
            mobile,
            innerWidth,
            innerHeight,
            segments,
            onOpenOverlay,
        } = this.props;
        const graphics = new PIXI.Graphics();
        graphics.beginFill(0xffd900, 1);

        // Bottom
        graphics.drawPolygon(
            new PIXI.Polygon(
                new PIXI.Point(0, 0),
                new PIXI.Point(0, innerHeight),
                new PIXI.Point(innerWidth, innerHeight),
                new PIXI.Point(innerWidth, 0),
                new PIXI.Point(0, 0),
            ),
        );

        const grid = new Grid({ width: innerWidth, height: innerHeight, mobile }, onOpenOverlay);
        grid.loadImages(segments || []);
        this.container.addChild(grid.container);

        const actions = grid.getActions(segments || [], onOpenOverlay, width, height);
        actions.forEach((action) => {
            this.container.addChild(action.container);
        });

        this.actions = actions;
        this.grid = grid;
    }

    resize(props) {
        this.props = { ...this.props, ...props };
        this.actions.forEach(child => child.resize(props.width, props.height));

        if (this.grid !== null) {
            this.grid.resize({ ...props, width: props.innerWidth, height: props.innerHeight });
        }
    }

    // eslint-disable-next-line
    onTick() {
        // yeah dont delete this
    }

    destroy() {
        this.actions.forEach(act => act.destroy());
        this.container.destroy({
            children: true,
        });
        this.fullContainer.destroy({
            children: true,
        });
    }

    getMaxSize() {
        return getSizeWithinMax(
            this.sprite.width,
            this.sprite.height,
            this.props.width,
            this.props.height,
            { cover: false },
        );
    }
}

export default Viewer;
