import React from 'react';
import Title from '../partials/Title';
import Text from '../partials/Text';

const propTypes = {
    title: React.PropTypes.string,
    credits: React.PropTypes.string,
};

const defaultProps = {
    title: null,
    credits: null,
};

const CreditsTextSection = (props) => {
    const { credits, title } = props;

    return credits ? (
        <section className="page-section page-section-credits credits">
            <Title text={title} className="section-title" subtitle />
            <Text html={credits} />
        </section>
    ) : null;
};

CreditsTextSection.propTypes = propTypes;
CreditsTextSection.defaultProps = defaultProps;

export default CreditsTextSection;
