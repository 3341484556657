/**
 * Constants
 */
const LISTS_UPDATE_SETTINGS = 'LISTS_UPDATE_SETTINGS';
const LISTS_UPDATE_SETTING = 'LISTS_UPDATE_SETTING';
const LISTS_CLEAR_SETTINGS = 'LISTS_CLEAR_SETTINGS';

/**
 * Actions creator
 */
const updateSettings = (name, settings) => ({
    type: LISTS_UPDATE_SETTINGS,
    payload: {
        name,
        settings,
    },
});

const updateSetting = (name, key, value) => ({
    type: LISTS_UPDATE_SETTING,
    payload: {
        name,
        key,
        value,
    },
});

const clearSettings = name => ({
    type: LISTS_CLEAR_SETTINGS,
    payload: {
        name,
    },
});

/**
 * Exports
 */
const FiltersActions = {
    updateSettings,
    updateSetting,
    clearSettings,
};

export default FiltersActions;
export {
    LISTS_UPDATE_SETTINGS,
    LISTS_UPDATE_SETTING,
    LISTS_CLEAR_SETTINGS,
    updateSettings,
    updateSetting,
    clearSettings,
};
