import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MainLayout from '../../components/layouts/Main';
import LayoutActions from '../../actions/LayoutActions';

const propTypes = {
    dispatch: React.PropTypes.func.isRequired,
    sections: React.PropTypes.shape({
        toJS: React.PropTypes.func,
    }).isRequired,
    layout: React.PropTypes.shape({
        get: React.PropTypes.func,
    }).isRequired,
    filters: React.PropTypes.shape({
        toJS: React.PropTypes.func,
    }).isRequired,
};

const MainLayoutContainer = (props) => {
    const { sections, layout, dispatch, filters, ...otherProps } = props;
    const layoutProps = layout.toJS();
    const actionsProps = bindActionCreators(LayoutActions, dispatch);
    return (
        <MainLayout
            {...otherProps}
            {...layoutProps}
            {...actionsProps}
            filters={filters.toJS()}
            sections={sections.toJS()}
        />
    );
};

MainLayoutContainer.propTypes = propTypes;

export default connect((state) => ({
    sections: state.sections,
    layout: state.layout,
    filters: state.filters,
}))(MainLayoutContainer);
