import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TimelineMax } from 'gsap';

import Overlays from './overlays';

const propTypes = {
    open: PropTypes.bool.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    content: PropTypes.object, // eslint-disable-line
    onClose: PropTypes.func.isRequired,
};

const defaultProps = {};

class Overlay extends PureComponent {
    constructor(props) {
        super(props);
        this.timeline = null;
        this.state = {
            visible: true,
        };
    }

    componentDidMount() {
        this.createTimeline();
    }

    componentDidUpdate(prevProps) {
        const openChanged = this.props.open !== prevProps.open;
        if (openChanged) {
            if (this.props.open) {
                this.timeline.play();
            } else {
                this.timeline.reverse();
            }
        }
    }

    createTimeline() {
        this.timeline = new TimelineMax({
            paused: true,
            onStart: () => {
                this.setState({ visible: true });
            },
            onComplete: () => {
                this.setState({ visible: true });
            },
            onReverseComplete: () => {
                this.setState({ visible: false });
            },
        });
        this.timeline.fromTo(
            this.refOverlay,
            0.9,
            {
                autoAlpha: 0,
            },
            {
                autoAlpha: 1,
            },
        );
    }

    render() {
        const { width, height, open, content, onClose } = this.props;
        const { visible } = this.state;
        const Current = content && content.type ? Overlays[content.type] : null;
        const style = {
            width,
            height,
        };
        return (
            <div
                ref={(ref) => {
                    this.refOverlay = ref;
                }}
                className="overlay-container"
                style={style}
            >
                {Current !== null && visible ? (
                    <Current
                        open={open}
                        content={content}
                        width={width}
                        height={height}
                        onClose={onClose}
                    />
                ) : null}
                <button className="close-button" onClick={onClose} />
            </div>
        );
    }
}

Overlay.propTypes = propTypes;
Overlay.defaultProps = defaultProps;

export default Overlay;
