import Immutable from 'immutable';

const initialState = {
    news: [],
    menu: [],
    backgrounds: [],
};

const HomeReducer = (previousState, action) => {
    const state = previousState || new Immutable.Map(initialState);

    switch (action.type) {
        default:
            return state;
    }
};

export default HomeReducer;
