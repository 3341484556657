import React, { Component } from 'react';
import classNames from 'classnames';
import Link from './Link';
import Search from '../../containers/Search';
import Logo from './Logo';

const propTypes = {
    toggleSidebarButton: React.PropTypes.bool,
    closed: React.PropTypes.bool,
    collapsed: React.PropTypes.bool,
    onToggleClick: React.PropTypes.func,
    sections: React.PropTypes.arrayOf(
        React.PropTypes.shape({
            key: React.PropTypes.string,
            label: React.PropTypes.string,
        }),
    ).isRequired,
    currentSection: React.PropTypes.shape({
        key: React.PropTypes.string,
    }),
    onSectionChange: React.PropTypes.func.isRequired,
};

const defaultProps = {
    toggleSidebarButton: true,
    closed: false,
    collapsed: false,
    onToggleClick: null,
    currentSection: {
        key: '',
    },
};

class Header extends Component {
    componentDidMount() {
        const width =
            window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (width < 767) {
            this.props.onToggleClick();
        }
    }

    render() {
        const {
            sections,
            closed,
            collapsed,
            toggleSidebarButton,
            currentSection,
            onSectionChange,
        } = this.props;

        const headerClassNames = classNames({
            header: true,
            closed,
            collapsed,
        });

        return (
            <header className={headerClassNames}>
                <div className="container-cols">
                    <div className="container-col col-logo">
                        <Logo
                            small={closed && !collapsed}
                            horizontal={collapsed}
                            toggleButton={toggleSidebarButton}
                            onToggleClick={this.props.onToggleClick}
                        />
                    </div>
                    <div className="container-col col-nav">
                        <div className="align-padding-left">
                            <div className="container container-cols container-relative">
                                <div className="container-col col-menu">
                                    <nav className="menu">
                                        <ul>
                                            {sections
                                                .filter(
                                                    section =>
                                                        !(typeof section.child === 'undefined'
                                                            ? false
                                                            : section.child),
                                                )
                                                .map((section) => {
                                                    const key = `section-${section.key}`;
                                                    const route =
                                                        section.key === 'catalog'
                                                            ? 'catalog.index'
                                                            : `catalog.${section.key}.index`;
                                                    let className =
                                                        currentSection.key === section.key
                                                            ? 'active'
                                                            : '';
                                                    // Change this if new subsections appear
                                                    if (currentSection.child === true) {
                                                        const values = [
                                                            'galleries',
                                                            'audios',
                                                            'videos',
                                                            'documents',
                                                        ];
                                                        if (
                                                            section.key === 'medias' &&
                                                            values.indexOf(currentSection.key) > -1
                                                        ) {
                                                            className = 'active';
                                                        }
                                                    }
                                                    return (
                                                        <li key={key} className={className}>
                                                            <Link
                                                                route={route}
                                                                onClick={onSectionChange}
                                                            >
                                                                {section.label}
                                                            </Link>
                                                        </li>
                                                    );
                                                })}
                                        </ul>
                                    </nav>
                                </div>
                                <div className="container-col col-search">
                                    <Search />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
