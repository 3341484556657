import { TimelineMax, TweenMax } from 'gsap';
import * as PIXI from 'pixi.js';
import merge from 'lodash/merge';
import { getSizeWithinMax } from '../../utils/size';
import Boxes from '../actions/boxes';

class MainMenu {
    constructor(options = { scene: null, width: 0, height: 0, router: null }) {
        this.setOptions(options);

        this.sprites = [];
        this.boxes = [];
        this.timeline = null;

        this.container = new PIXI.Container();
        this.topContainer = new PIXI.Container();
        this.containers = [this.container];

        this.init = this.init.bind(this);
        this.resize = this.resize.bind(this);
        this.getTimeline = this.getTimeline.bind(this);

        this.init();

        return this;
    }

    setOptions(options = {}) {
        const opts = merge(
            {
                time: 1,
                transitionTime: 2,
                playTime: 5,
                toggleOverlay: () => {},
                onClick: (url) => {
                    options.router.push(`/voyages/${url}`);
                },
            },
            options,
        );
        this.options = opts;
    }

    init() {
        this.newBoxes = new Boxes({
            container: this.container,
            topContainer: this.topContainer,
            ...this.options,
        });
    }

    resize(props) {
        this.newBoxes.resize(props);
    }

    getTimeline(start = 0, onComplete = null) {
        let time = start;

        console.log('main infinite loop timeline');

        const newBoxTimeline = this.newBoxes.getTimeline(0);

        const timeline = new TimelineMax({
            repeat: -1,
            delay: 1.5,
            onStart: () => {
                TweenMax.to(this.newBoxes.sprites[0], 1, {
                    alpha: 1,
                });
                TweenMax.to(this.newBoxes.filter.uniforms, 1, {
                    outerStrength: 0,
                    innerStrength: 0,
                    delay: 0.5,
                    onComplete: () => {
                        newBoxTimeline.play();
                    },
                });
            },
            onComplete: () => {
                if (onComplete !== null) {
                    onComplete();
                }
            },
        });

        for (let i = 0; i < 11; i += 1) {
            timeline.add(() => {
                this.newBoxes.sprites[0].gotoAndStop(i);
            }, time);
            time += 0.22;
        }

        timeline.add(() => {}, time);

        return timeline;
    }

    destroy() {
        if (this.timeline !== null) {
            this.timeline.kill();
            this.timeline = null;
        }
        this.newBoxes.destroy();
        this.containers.forEach((container) => {
            container.destroy({
                children: true,
            });
        });
        this.sprites = [];
        this.graphics = [];
    }

    getCoords(sprite) {
        return getSizeWithinMax(
            sprite.width,
            sprite.height,
            this.options.width,
            this.options.height,
            { cover: false },
        );
    }
}

export default MainMenu;
