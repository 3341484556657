import image from './image';
import video from './video';
import icon from './icon';
import animation from './animation';

export default {
    image,
    icon,
    video,
    animation,
};
