import { TimelineLite, TweenMax } from 'gsap';
import * as PIXI from 'pixi.js';
import merge from 'lodash/merge';
import { getSizeWithinMax } from '../../utils/size';
import filters from '../filters';

class IconComponent {
    constructor(options = { scene: null, width: 0, height: 0 }) {
        this.setOptions(options);

        this.sprite = null;
        this.border = null;
        this.coords = null;

        this.timeline = null;

        this.container = new PIXI.Container();
        this.topContainer = new PIXI.Container();

        this.init = this.init.bind(this);
        this.create = this.create.bind(this);
        this.resize = this.resize.bind(this);

        return this;
    }

    setOptions(options = {}) {
        const opts = merge(
            {
                wait: 3,
                transitionTime: 1.5,
                texture: {
                    src: '/img/trips/portraits/mao2.jpg',
                },
            },
            options,
        );
        this.options = opts;
    }

    init() {
        this.create();
    }

    create() {
        if (this.sprite !== null) {
            this.sprite.destroy();
        }
        this.sprite = new PIXI.Sprite(PIXI.loader.resources[this.options.src].texture);
        // eslint-disable-next-line
        this.sprite.tint = parseInt(0xffffff);

        this.coords = this.getCoords();

        this.pixelFilter = new filters.AsciiFilter(8);
        this.pixelFilter.size = 8;

        this.sprite.width = this.coords.width;
        this.sprite.height = this.coords.height;
        this.sprite.filters = [this.pixelFilter];

        TweenMax.set(this.sprite, {
            alpha: 0,
            x: (this.options.width - this.sprite.width) / 2,
            y: (this.options.height - this.sprite.height) / 2,
        });

        // Add it
        this.container.addChild(this.sprite);
    }

    resize() {
        if (this.sprite !== null && this.container !== null) {
            this.coords = this.getCoords();

            this.sprite.width = this.coords.width;
            this.sprite.height = this.coords.height;

            TweenMax.set(this.sprite, {
                x: (this.options.width - this.sprite.width) / 2,
                y: (this.options.height - this.sprite.height) / 2,
            });
        }
    }

    getTimeline(start = 0, onComplete = null) {
        let time = start;
        this.container.visible = false;

        const timeline = new TimelineLite({
            onComplete: () => {
                this.container.visible = false;
                if (onComplete !== null) {
                    onComplete();
                }
            },
        });
        timeline.set(this.sprite, { alpha: 0 });
        timeline.set(this.container, { visible: true });

        timeline.to(
            this.sprite,
            this.options.transitionTime,
            {
                alpha: 1,
            },
            time,
        );

        time += this.options.wait;

        timeline.to(
            this.sprite,
            this.options.transitionTime,
            {
                alpha: 0,
            },
            time,
        );

        return timeline;
    }

    destroy() {
        this.container.destroy({
            children: true,
        });
        this.container.destroy({
            children: true,
        });
    }

    getCoords() {
        return getSizeWithinMax(
            this.sprite.width,
            this.sprite.height,
            this.options.width,
            this.options.height,
            { cover: false },
        );
    }
}

export default IconComponent;
