import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import withUrl from '../../lib/withUrl';
import HeaderComponent from '../../components/partials/Header';
import { getCurrentSectionFromLocation } from '../../lib/utils';
import { clearFilters, clearFilter } from '../../actions/FiltersActions';

const propTypes = {
    sections: React.PropTypes.shape({
        filter: React.PropTypes.func,
        toJS: React.PropTypes.func,
    }).isRequired,
    url: React.PropTypes.shape({
        route: React.PropTypes.func,
    }).isRequired,
    location: React.PropTypes.shape({
        pathname: React.PropTypes.string,
    }).isRequired,
    dispatch: React.PropTypes.func.isRequired,
};

const HeaderContainer = (props) => {
    const { dispatch, sections, location, url, ...otherProps } = props;
    const currentSection = getCurrentSectionFromLocation(sections, url, location);

    return (
        <HeaderComponent
            {...otherProps}
            location={location}
            currentSection={currentSection}
            sections={sections.toJS()}
            onSectionChange={() => {
                dispatch(clearFilters());
                dispatch(clearFilter('search_search'));
            }}
        />
    );
};

HeaderContainer.propTypes = propTypes;

export default withUrl(
    withRouter(
        connect(state => ({
            sections: state.sections,
        }))(HeaderContainer),
    ),
);
