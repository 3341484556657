import React from 'react';
import ReactDOM from 'react-dom';
import Relay from 'react-relay';
import domready from 'domready';
import WebFont from 'webfontloader';
// import attachFastClick from 'fastclick';
import { match } from 'react-router';
import { load as loadHypernova } from 'hypernova';
import HypernovaComponents from './hypernova';

import createRoutes from './routes';

/* eslint global-require:0 */
if (process.env.NODE_ENV === 'development') {
    const SuperagentNetworkLayer = require('./lib/SuperagentNetworkLayer').default;
    Relay.injectNetworkLayer(new SuperagentNetworkLayer('/graphql'));
}

domready(() => {
    function findComponent(name) {
        return HypernovaComponents[name] || null;
    }

    function renderReact(el, componentName, props) {
        const Component = findComponent(componentName);
        if (!Component) {
            console.warn(`Component ${componentName} not found.`);
            return;
        }
        ReactDOM.render(React.createElement(Component, props), el);
    }

    function createRenderReact(node, componentName, props) {
        return () => {
            renderReact(node, componentName, props);
        };
    }

    function loadFonts(callback) {
        const isPrerender = typeof IS_PRERENDER !== 'undefined' && IS_PRERENDER;
        WebFont.load({
            google: {
                families: ['Roboto:400,700'],
            },
            active: () => {
                if (!isPrerender) {
                    callback();
                }
            },
        });
        if (isPrerender) {
            callback();
        }
    }

    loadFonts(() => {
        // Render the hypernova elements
        const elements = document.querySelectorAll('div[data-hypernova-key]');
        let el;
        let componentName;
        let nodes;
        let node;
        let props;
        let routes;
        for (let i = 0, elementsCount = elements.length; i < elementsCount; i += 1) {
            el = elements[i];
            componentName = el.dataset.hypernovaKey;
            nodes = loadHypernova(componentName);
            for (let ii = 0, nodesCount = nodes.length; ii < nodesCount; ii += 1) {
                node = nodes[ii].node;
                props = nodes[ii].data;
                if (props.routes && props.url) {
                    routes = createRoutes(props.routes);
                    match(
                        {
                            routes,
                            location: props.url,
                        },
                        createRenderReact(node, componentName, { routerRoutes: routes, ...props }),
                    );
                } else {
                    renderReact(node, componentName, props);
                }
            }
        }
    });

    /*
    if ('addEventListener' in document) {
        attachFastClick(document.body);
    }
    */
});
