import React from 'react';
import { connect } from 'react-redux';
import { updateFilter, clearFilter } from '../actions/FiltersActions';

import SearchComponent from '../components/Search';

const propTypes = {
    filters: React.PropTypes.shape({
        toJS: React.PropTypes.func.isRequired,
    }).isRequired,
    dispatch: React.PropTypes.func.isRequired,
};

const SearchContainer = (props) => {
    const { dispatch, filters, ...otherProps } = props;
    const search = filters.get('search_search');

    return (
        <SearchComponent
            {...otherProps}
            search={search && search[0] ? search[0] : ''}
            updateSearch={value => dispatch(updateFilter('search_search', [value]))}
            clearSearch={() => dispatch(clearFilter('search_search'))}
        />
    );
};

SearchContainer.propTypes = propTypes;

export default connect(state => ({
    filters: state.filters,
}))(SearchContainer);
