import * as PIXI from 'pixi.js';
import { Howler } from 'howler';
import { TimelineLite } from 'gsap';
import filters from './filters';

const opts = {
    smoothing: 0.4,
    fft: 8,
    minDecibels: -72,
    scale: 1.5,
    colors: {
        0: 0xff0000,
        1: 0xff0000,
        2: 0xff0000,
    },
    lineWidth: 5,
    shift: 70,
    width: 100,
    amp: 1,
    alpha: 0.9,
};

function range(i) {
    return Array.from(Array(i).keys());
}

// Better shuffle frequencies
const shuffle = [1, 3, 0, 4, 2, 5, 6, 7];
const filterSizes = [12, 16, 20, 24];
const opacities = [0.5, 0.5, 0.5, 0.1];
const channels = 3;

/**
 * Pick a frequency for the given channel and value index.
 *
 * The channel goes from 0 to 2 (R/G/B)
 * The index goes from 0 to 4 (five peaks in the curve)
 *
 * We have 32 (2^opts.fft) frequencies to choose from and
 * we want to visualize most of the spectrum. This function
 * returns the bands from 0 to 28 in a nice distribution.
 */

function freq(freqs, channel, i) {
    const channel2x = channel * 2;
    const shuffle6x = shuffle[i] * 6;
    const band = channel2x + shuffle6x;
    return freqs[band];
}

/**
 * Returns the scale factor fot the given value index.
 * The index goes from 0 to 4 (curve with 5 peaks)
 */

function scale(i) {
    const x = Math.abs(2 - i); // 2,1,0,1,2
    const s = 3 - x; // 1,2,3,2,1
    const third = s / 3;
    return third * opts.amp;
}

class FFTAudio {
    constructor(width, height) {
        this.timer = this.timer.bind(this);
        this.visualize = this.visualize.bind(this);
        this.path = this.path.bind(this);

        this.filters = [];
        this.containers = [];
        this.container = new PIXI.Container();

        for (let i = 0; i < channels; i += 1) {
            const c = new PIXI.Container();
            this.containers.push(c);
            this.container.addChild(c);

            const pixelFilter = new filters.AsciiFilter(filterSizes[i]);
            this.filters.push(pixelFilter);
            pixelFilter.size = filterSizes[i];
            c.filters = [pixelFilter];
        }

        this.create(width, height);
    }

    create(width, height) {
        this.analyser = Howler.ctx.createAnalyser();
        Howler.masterGain.connect(this.analyser);
        this.analyser.connect(Howler.ctx.destination);
        this.size = this.analyser.frequencyBinCount;

        this.freqs = new Uint8Array(this.size);
        this.timeLoad = performance.now();
        this.timePrev = 0;

        this.width = width;
        this.height = height;

        this.timeline = new TimelineLite({
            paused: true,
            onComplete: () => {
                this.lock = false;
            },
        });

        this.graphics = {};

        this.lock = false;

        for (let i = 0; i < channels; i += 1) {
            this.graphics[i] = new PIXI.Graphics();
            this.graphics[i].alpha = opacities[i];
            this.containers[i].addChild(this.graphics[i]);
            this.timeline.add(() => {
                this.path(i);
            }, 0);
            this.timeline.add(new TimelineLite().to(this.graphics[i], 0.05, { alpha: 1 }), 0);
        }
    }

    resize({ width, height }) {
        this.destroy();
        this.create(width, height);
    }

    timer() {
        const now = performance.now();
        this.timeDelta = (now - this.timePrev) / 1000.0;
        this.timePrev = now;
        this.customFilter.time = (now - this.timeLoad) / 1000.0;
    }

    visualize() {
        if (!this.lock) {
            this.analyser.smoothingTimeConstant = opts.smoothing;
            // eslint-disable-next-line
            this.analyser.fftSize = Math.pow(2, opts.fft);
            this.analyser.minDecibels = opts.minDecibels;
            this.analyser.maxDecibels = 0;
            this.analyser.getByteFrequencyData(this.freqs);
            this.timeline.kill(); // Why?
            this.timeline.play(0);
        }
    }

    path(channel) {
        const color = opts.colors[channel];
        const graphics = this.graphics[channel];
        graphics.clear();
        // graphics.alpha = 0.5;

        const m = this.height / 2; // the vertical middle of the canvas
        // for the curve with 5 peaks we need 15 control points
        // calculate how much space is left around it

        // prettier-ignore
        const offset = (this.width - (15 * opts.width)) / 2;
        // calculate the 15 x-offsets

        // prettier-ignore
        const x = range(15).map(i => offset + (channel * opts.shift) + (i * opts.width));
        // pick some frequencies to calculate the y values
        // scale based on position so that the center is always bigger

        // prettier-ignore
        const y = range(5).map(i => Math.max(0, m - (scale(i) * freq(this.freqs, channel, i))));
        const h = 2 * m;

        graphics.beginFill(color, opts.alpha);
        graphics.lineStyle(opts.lineWidth, 0x000000, opts.alpha);

        graphics.moveTo(0, m); // start in the middle of the left side
        graphics.lineTo(x[0], m + 1); // straight line to the start of the first peak

        graphics.bezierCurveTo(x[1], m + 1, x[2], y[0], x[3], y[0]); // curve to 1st value
        graphics.bezierCurveTo(x[4], y[0], x[4], y[1], x[5], y[1]); // 2nd value
        graphics.bezierCurveTo(x[6], y[1], x[6], y[2], x[7], y[2]); // 3rd value
        graphics.bezierCurveTo(x[8], y[2], x[8], y[3], x[9], y[3]); // 4th value
        graphics.bezierCurveTo(x[10], y[3], x[10], y[4], x[11], y[4]); // 5th value
        graphics.bezierCurveTo(x[12], y[4], x[12], m, x[13], m); // curve back down to the middle

        graphics.lineTo(1000, m + 1); // straight line to the right edge
        graphics.lineTo(x[13], m - 1); // and back to the end of the last peak

        // the same in reverse for the lower half of out shape
        graphics.bezierCurveTo(x[12], m, x[12], h - y[4], x[11], h - y[4]);
        graphics.bezierCurveTo(x[10], h - y[4], x[10], h - y[3], x[9], h - y[3]);
        graphics.bezierCurveTo(x[8], h - y[3], x[8], h - y[2], x[7], h - y[2]);
        graphics.bezierCurveTo(x[6], h - y[2], x[6], h - y[1], x[5], h - y[1]);
        graphics.bezierCurveTo(x[4], h - y[1], x[4], h - y[0], x[3], h - y[0]);
        graphics.bezierCurveTo(x[2], h - y[0], x[1], m, x[0], m);

        graphics.lineTo(0, m);
        graphics.endFill();
    }

    destroy() {
        this.lock = true;

        this.analyser.disconnect(Howler.ctx.destination);
        if (this.timeline) {
            this.timeline.kill();
        }

        this.containers.forEach((c) => {
            c.children.forEach((child) => {
                c.removeChild(child);
                child.destroy();
            });
        });

        this.graphics = null;
    }
}

export default FFTAudio;
