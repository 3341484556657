import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
    onClick: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
};

const defaultProps = {};

// eslint-disable-next-line
class Previous extends PureComponent {
    render() {
        const { visible, onClick } = this.props;

        const playClassName = classNames({
            'icon-previous': true,
            hidden: !visible,
        });

        return (
            <div className="next">
                <button className={playClassName} onClick={onClick} />
            </div>
        );
    }
}

Previous.propTypes = propTypes;
Previous.defaultProps = defaultProps;

export default Previous;
