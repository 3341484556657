import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import merge from 'lodash/merge';
import withUrl from '../../lib/withUrl';

import TripLayout from '../../voyages/Layout';
import content from '../../voyages/data/index';

const propTypes = {
    params: React.PropTypes.shape({
        section_slug: React.PropTypes.string,
        segment_slug: React.PropTypes.string,
    }),
};

const defaultProps = {
    params: {
        section_slug: null,
        segment_slug: null,
    },
};

// Transform seconds to time
Object.keys(content).forEach((slug) => {
    const data = merge(content[slug], content.all, {}); // Maps the basic stuff

    if (data.items) {
        const items = [];
        data.items.forEach((segment) => {
            const item = {
                ...segment,
                track: segment.track,
                cues: {},
            }; // track and cues
            Object.keys(segment.cues).forEach((timestamp) => {
                const cue = segment.cues[timestamp];
                if (cue.src) {
                    cue.src = data.contentRoot + cue.src;
                }
                if (cue.altSrc) {
                    cue.altSrc = data.contentRoot + cue.altSrc;
                }
                const ms = timestamp.split(':');
                // prettier-ignore
                cue.time = (60 * parseInt(ms[0], 10)) + parseInt(ms[1], 10);
                item.cues[timestamp] = cue;
            });
            items.push(item);
        });
        data.items = items;
    }

    if (data.scene && data.scene.cues) {
        const segment = data.scene;
        const item = {
            ...segment,
            track: segment.track,
            cues: {},
        };
        Object.keys(segment.cues).forEach((timestamp) => {
            const cue = segment.cues[timestamp];
            if (cue.src) {
                cue.src = data.contentRoot + cue.src;
            }
            const ms = timestamp.split(':');
            // prettier-ignore
            cue.time = (60 * parseInt(ms[0], 10)) + parseInt(ms[1], 10);
            item.cues[timestamp] = cue;
        });
        data.scene = item;
    }

    if (data.assets) {
        Object.keys(data.assets).forEach((name) => {
            if (data.assets[name].src) {
                data.assets[name].src = data.contentRoot + data.assets[name].src;
            }
        });
    }

    if (data.rawAssets) {
        Object.keys(data.rawAssets).forEach((name) => {
            if (data.rawAssets[name].src) {
                data.rawAssets[name].src = data.rawAssets[name].src;
            }
        });
    }

    if (data.overlays) {
        Object.keys(data.overlays).forEach((name) => {
            data.overlays[name].items = data.overlays[name].items.map((item) => {
                if (item.src) {
                    item.src = data.contentRoot + item.src; //eslint-disable-line
                }
                return item;
            });
        });
    }
});

// eslint-disable-next-line react/prefer-stateless-function
const TripPageContainer = (props) => {
    const { params } = props;
    const section = params.section_slug ? params.section_slug.replace('-', '_') : null;
    const segment = params.segment_slug ? params.segment_slug : null;
    return <TripLayout {...props} content={content} sectionSlug={section} segmentSlug={segment} />;
};

TripPageContainer.propTypes = propTypes;
TripPageContainer.defaultProps = defaultProps;

export default withUrl(
    withRouter(
        connect(state => ({
            sections: state.sections,
        }))(TripPageContainer),
    ),
);
