import React from 'react';
import Link from '../partials/Link';

const propTypes = {
    text: React.PropTypes.string,
    url: React.PropTypes.string,
};

const defaultProps = {
    text: null,
    url: null,
};

const UListItem = (props) => {
    const { text, url } = props;
    if (!text) return null;

    const link = (url || '').replace(/https?:\/\/[^/]+/, '');

    return (
        <li className="ulist-item-li">
            {
                link ? (
                    <Link to={link}>{text}</Link>
                ) : (
                    <span>{text}</span>
                )
            }
        </li>
    );
};

UListItem.propTypes = propTypes;
UListItem.defaultProps = defaultProps;

export default UListItem;
