import React from 'react';
import Relay from 'react-relay';

import Title from '../partials/Title';
import Meta from '../partials/Meta';
import Footer from '../../containers/partials/Footer';

import CategoriesSection from '../../containers/sections/Categories';

import DescriptionSection from '../sections/Description';
import PerformancesSection from '../sections/Performances';
import CreditsSection from '../sections/Credits';
import CreditsTextSection from '../sections/CreditsText';
import BubblesListSection from '../sections/BubblesList';

import { getWidth } from '../../lib/getSize';

const propTypes = {
    bubble: React.PropTypes.shape({
        bubble_id: React.PropTypes.string,
        snippet: React.PropTypes.shape({
            link: React.PropTypes.string,
            title: React.PropTypes.string,
            subtitle: React.PropTypes.string,
        }),
        related: React.PropTypes.array,
        credits: React.PropTypes.array,
        performances: React.PropTypes.array,
        gallery: React.PropTypes.object,
        description: React.PropTypes.string,
        credits_technicians: React.PropTypes.string,
        credits_other: React.PropTypes.string,
    }).isRequired,
    newsletter: React.PropTypes.object,
};

const BubbleShowPage = (props) => {
    const {
        description,
        performances,
        credits,
        related,
        gallery,
        snippet,
        // datation,
        categories,
    } = props.bubble;
    const id = props.bubble.bubble_id;
    const { newsletter } = props || {};
    const { title } = snippet;
    let imageWidth = 0;
    let imageHeight = 0;
    let computedHeight = 0;

    const creditsTechnicians = props.bubble.credits_technicians;
    const creditsOther = props.bubble.credits_other;
    const hasGallery = gallery && gallery.slides;
    const hasTopPicture = snippet && snippet.picture_top;

    let caption = null;
    let topPicture = hasTopPicture ? snippet.picture_top.url : null;
    if (topPicture) {
        imageWidth = snippet.picture_top.width || 0;
        imageHeight = snippet.picture_top.height || 0;
        caption = snippet.picture_top.credits;
        if (imageWidth > 0 && imageHeight > 0) {
            const width = getWidth();
            if (width < 500) {
                computedHeight = (width / imageWidth) * imageHeight;
            }
        }
    }

    if (topPicture === null && hasGallery && gallery.slides[0]) {
        topPicture = gallery.slides[0].picture_splash.url;
        const slide = gallery.slides[0];
        caption = slide.title || null;
    }

    if (caption === null && hasGallery && gallery.slides[0]) {
        const captionSlide = gallery.slides[0];
        caption = captionSlide.title || null;
    }

    const containerStyle = {
        height: computedHeight > 0 ? computedHeight : '100%',
        minHeight: computedHeight > 0 ? 'auto' : '67vh',
        padding: computedHeight > 0 ? 0 : '20px 0',
    };

    const pictureStyle = {
        backgroundImage: `url("${topPicture}")`,
    };

    return (
        <article className="container-page container-show">
            <Meta bubble={props.bubble} section="Production" />

            {title ? (
                <div className="container">
                    <Title text={title} newsletter={newsletter} />
                </div>
            ) : null}

            {topPicture ? (
                <div className="container-picture" style={containerStyle}>
                    <div className="container-picture-background" style={pictureStyle} />
                    <div className="container-picture-foreground" style={pictureStyle} />
                    <p className="container-picture-caption">{caption}</p>
                </div>
            ) : null}

            <div className="container">
                <CategoriesSection categories={categories} section="productions" />

                <DescriptionSection description={description} separator />

                <PerformancesSection title="Représentations" performances={performances} />

                <CreditsSection title="Crédits" name="credits-show" credits={credits} />

                <CreditsTextSection title="Techniciens" credits={creditsTechnicians} />

                <CreditsTextSection title="Autres crédits" credits={creditsOther} />

                <BubblesListSection name={`galleries:${id}`} title="Photos" sectionSize="small" />

                <BubblesListSection name={`videos:${id}`} title="Videos" sectionSize="small" />

                <BubblesListSection name={`audios:${id}`} title="Audios" sectionSize="small" />

                <BubblesListSection
                    name={`documents:${id}`}
                    title="Documents"
                    sectionSize="small"
                />

                {related.length > 0
                    ? related.map((section) => (
                          <BubblesListSection
                              key={`related-${section.id}`}
                              name={section.id}
                              title={`En savoir plus${section.title ? `: ${section.title}` : ''}`}
                              sectionSize="small"
                          />
                      ))
                    : null}
                <Footer />
            </div>
        </article>
    );
};

// <BubblesListSection
//     name={`shows:${id}`}
//     title="Sous-productions"
//     sectionSize="small"
// />

BubbleShowPage.propTypes = propTypes;

// sub_productions {
//     id
//     items {
//         id
//         snippet {
//             link
//             title
//             subtitle
//             picture(filter:"thumbnail") {
//                 url
//             }
//         }
//     }
// }

BubbleShowPage.RelayComponent = Relay.createContainer(BubbleShowPage, {
    fragments: {
        bubble: () =>
            Relay.QL`
                fragment on BubbleShow {
                    bubble_id
                    snippet {
                        title
                        subtitle
                        link
                        picture_facebook: picture(filter:"facebook") {
                            url
                        }
                        picture_top: picture(filter:"splash") {
                            url
                            credits
                            width
                            height
                        }
                    }
                    related {
                        id
                        title
                        items {
                            id
                            snippet {
                                link
                                title
                                subtitle
                                picture(filter:"thumbnail") {
                                    url
                                }
                            }
                        }
                    }
                    credits {
                        id
                        position
                        bubble {
                            id
                            lastname
                            snippet {
                                link
                                title
                            }
                        }
                    }
                    performances {
                        id
                        date
                        location {
                            snippet {
                                title
                            }
                        }
                    }
                    gallery {
                        id
                        slides {
                            picture_splash: picture(filter:"splash"){
                                url
                            }
                            title
                            subtitle
                            description
                        }
                    }
                    categories {
                        id
                        name
                    }
                    description
                    datation
                    credits_technicians
                    credits_other
                }
            `,
    },
});

export default BubbleShowPage;
