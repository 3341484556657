/**
 * Routes constants
 */
export const ROUTES_SET = 'ROUTES_SET';

/**
 * Sections constants
 */
export const SECTIONS_SET = 'SECTIONS_SET';
export const SUBSECTIONS_SET = 'SUBSECTIONS_SET';

/**
 * Bubbles constants
 */
export const BUBBLES_ADD = 'BUBBLES_ADD';
export const BUBBLES_REMOVE = 'BUBBLES_REMOVE';

/**
 * Filters constants
 */
export const FILTERS_UPDATE = 'FILTERS_UPDATE';
export const FILTERS_CLEAR = 'FILTERS_CLEAR';
