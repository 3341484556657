import React from 'react';
import { Link } from 'react-router';
import withUrl from '../../lib/withUrl';

const propTypes = {
    url: React.PropTypes.shape({
        route: React.PropTypes.func,
    }).isRequired,
    route: React.PropTypes.string,
    routeParams: React.PropTypes.object,
    children: React.PropTypes.oneOfType([
        React.PropTypes.arrayOf(React.PropTypes.node),
        React.PropTypes.node,
    ]),
};

const defaultProps = {
    route: null,
    children: null,
    routeParams: null,
};

const LinkWithRoute = (props) => {
    const { route, routeParams, url, children, ...linkProps } = props;

    let to = '';
    if (route) {
        to = routeParams ? url.route(route, routeParams) : url.route(route);
    }

    const updatedProps = { to, ...linkProps };

    return <Link {...updatedProps}>{children}</Link>;
};

LinkWithRoute.propTypes = propTypes;
LinkWithRoute.defaultProps = defaultProps;

export default withUrl(LinkWithRoute);
