import React from 'react';
import classNames from 'classnames';
import Filters from '../../containers/Filters';

const propTypes = {
    closed: React.PropTypes.bool,
    collapsed: React.PropTypes.bool,
    filters: React.PropTypes.arrayOf(React.PropTypes.string).isRequired,
};

const defaultProps = {
    closed: false,
    collapsed: false,
    //  onScroll: null,
};

const Sidebar = (props) => {
    const { filters, closed, collapsed } = props;

    const sidebarClassNames = classNames({
        sidebar: true,
        closed,
        collapsed,
    });

    return (
        <div className={sidebarClassNames}>
            <div className="container-col">
                <div className="menu">
                    <div className="scroller">
                        <Filters names={filters} />
                    </div>
                </div>
            </div>
        </div>
    );
};

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

export default Sidebar;
