import extend from 'lodash/extend';
import { Howl } from 'howler';

const defaultProps = {
    tracks: [],
    timelineDuration: 0,
    playing: false,
    seek: null,

    volume: 0.4,
    loop: false,
    autoPlay: false,
    mute: false,
};

class SoundEffect {
    constructor(props) {
        const opts = extend(defaultProps, props);

        this.sound = null;

        this.play = this.play.bind(this);
        this.stop = this.stop.bind(this);
        this.pause = this.pause.bind(this);
        this.seek = this.seek.bind(this);
        this.destroy = this.destroy.bind(this);

        if (opts.src) {
            this.sound = new Howl(opts);
        }
    }

    seek(seconds) {
        if (this.sound === null) return;
        this.sound.seek(seconds);
    }

    play() {
        if (this.sound === null) return;
        this.sound.play();
    }

    stop() {
        if (this.sound === null) return;
        this.sound.stop();
    }

    pause() {
        if (this.sound === null) return;
        this.sound.pause();
    }

    destroy() {
        if (this.sound === null) return;
        this.sound.unload();
        this.sound = null;
    }
}

export default SoundEffect;
