import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TimelineLite } from 'gsap';
import classNames from 'classnames';

const propTypes = {
    label: PropTypes.string,
    // visible: PropTypes.bool.isRequired,
    mobile: PropTypes.bool.isRequired,
    height: PropTypes.number.isRequired,
    delay: PropTypes.number,
};

const defaultProps = {
    label: "L'histoire du NTE",
    delay: 0,
};

class Title extends PureComponent {
    constructor(props) {
        super(props);
        this.title = null;
        this.timeline = null;
    }

    componentDidMount() {
        // console.log('pp', this.props);
        // if (this.props.visible) {
        // } else {
        //     TweenMax.set(this.title, { autoAlpha: 0 });
        // }
        this.toggleVisible(0);
    }

    componentDidUpdate(prevProps) {
        const labelChanged = prevProps.label !== this.props.label;
        // const visibleChanged = prevProps.visible !== this.props.visible;

        if (labelChanged) {
            this.toggleVisible(1);
        }
    }

    toggleVisible(time = 1) {
        if (this.timeline !== null) {
            this.timeline.kill();
            this.timeline = null;
        }

        const { delay } = this.props;
        this.timeline = new TimelineLite({
            delay,
            onStart: () => {
                // console.log('hum');
            },
        });

        this.timeline.to(
            this.title,
            time,
            {
                autoAlpha: 1,
                display: 'table',
            },
            0.7,
        );

        this.timeline.to(
            this.title,
            time,
            {
                autoAlpha: 0,
            },
            time * 3.7,
        );

        this.timeline.to(
            this.title,
            time,
            {
                display: 'none',
            },
            time * 5,
        );
    }

    render() {
        const { label, mobile, height } = this.props;

        const className = classNames({
            'scene-menu': true,
            'title-only': true,
            desktop: !mobile,
        });

        return (
            <div
                className={className}
                ref={(ref) => {
                    this.title = ref;
                }}
                style={{ height: 150, top: (height - 150) / 2 }}
            >
                {label ? (
                    <div className="inner-scene-menu">
                        <div className="title">
                            <h2 className="scene">{label}</h2>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

Title.propTypes = propTypes;
Title.defaultProps = defaultProps;

export default Title;
