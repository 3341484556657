import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { debounce } from 'lodash';
import classNames from 'classnames';
import withUrl from '../lib/withUrl';

const propTypes = {
    url: React.PropTypes.shape({
        route: React.PropTypes.func,
    }).isRequired,
    search: React.PropTypes.string,
    router: React.PropTypes.object.isRequired,
    updateSearch: React.PropTypes.func.isRequired,
};

const defaultProps = {
    search: '',
};

class Search extends Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onErase = this.onErase.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.debouncedChange = debounce(this.applyChange, 800).bind(this);

        this.isExpanded = this.isExpanded.bind(this);
        this.isLarge = this.isLarge.bind(this);

        this.state = {
            hasValue: false,
            expanded: this.isLarge(),
            open: this.isLarge(),
        };
    }

    componentDidMount() {
        this.onResize();
        window.addEventListener('load', this.onResize);
        window.addEventListener('resize', this.onResize);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.expanded !== this.state.expanded) {
            if (this.state.expanded === true) {
                this.refInput.focus();
            }
        }

        if (prevProps.search !== this.props.search) {
            this.refInput.value = this.props.search;
            if (this.props.search.length > 0) {
                this.setState({
                    hasValue: true,
                    expanded: true,
                });
            } else {
                this.setState({
                    hasValue: false,
                    expanded: this.isLarge(),
                });
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('load', this.onResize);
        window.removeEventListener('resize', this.onResize);
    }

    onResize() {
        // this.setState({
            // expanded: this.isExpanded(),
            // large: this.isLarge(),
        // });
    }

    onChange(e) {
        e.preventDefault();
        e.persist();
        this.debouncedChange(e);
    }

    onSubmit(e) {
        e.preventDefault();
        // console.log('submit');
        this.setState({
            expanded: !this.state.expanded,
        });
    }

    onFormSubmit(e) {
        e.preventDefault();
        this.refInput.blur();
    }

    onErase(e) {
        e.preventDefault();
        this.props.updateSearch('');
        this.refInput.value = '';
        this.setState({
            hasValue: false,
            expanded: this.isExpanded(),
        });
    }

    isExpanded() {
        // console.log('is');
        return this.isLarge() || (this.state.hasValue === true);
    }

    isLarge() {
        const width = window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth;

        return (width > 1400);
    }

    applyChange(e) {
        if (e.target.value.length >= 3) {
            this.goToSearchPage();
            this.props.updateSearch(e.target.value);
        } else if (this.props.search && this.props.search.length > 0) {
            this.props.updateSearch('');
        }

        if (e.target.value.length > 0) {
            this.setState({
                hasValue: true,
                expanded: true,
            });
        } else {
            this.setState({
                hasValue: false,
                expanded: true,
            });
        }
    }

    goToSearchPage() {
        if (location.pathname !== this.props.url.route('catalog.search.index')) {
            this.props.router.push(this.props.url.route('catalog.search.index'));
        }
    }

    render() {
        const isLarge = this.isLarge();
        let isEmpty = true;
        if (isLarge) {
            isEmpty = !this.state.hasValue;
        } else {
            isEmpty = !this.state.expanded;
        }

        const btnCloseClassNames = classNames({
            btn: true,
            'btn-close': true,
            empty: isEmpty,
        });

        const expandedStyle = {
            display: this.state.expanded ? 'inline-block' : 'none',
        };

        const searchClassNames = classNames({
            search: true,
            expanded: this.state.expanded,
            active: this.state.hasValue,
        });

        return (
            <div className={searchClassNames}>
                <form onSubmit={this.onFormSubmit}>
                    <div className="container-cols">
                        <div className="container-col">
                            <button
                                type="button"
                                className="btn btn-submit"
                                onClick={this.onSubmit}
                            />
                        </div>
                        <div className="container-col">
                            <input
                                ref={(ref) => { this.refInput = ref; }}
                                className="input-text"
                                type="text"
                                name="search"
                                placeholder="Recherche"
                                onChange={this.onChange}
                                style={expandedStyle}
                                autoComplete="off"
                            />
                        </div>
                        <div className="container-col">
                            <button
                                type="button"
                                className={btnCloseClassNames}
                                style={expandedStyle}
                                onClick={this.onErase}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

Search.propTypes = propTypes;
Search.defaultProps = defaultProps;

export default withUrl(
    withRouter(
        Search,
    ),
);
