import React from 'react';
import classNames from 'classnames';

const propTypes = {
    items: React.PropTypes.array,
};

const defaultProps = {
    items: [],
};

const News = (props) => {
    const itemClassNames = classNames({
        'news-container': true,
    });

    const items = props.items.map((item) => {
        const link = `http://${item.link}`;
        return (
            <a className="news-item" key={`news-item-${item.title}-${item.link}`} href={link}>
                <div className="news-item-inner">
                    <div className="background">
                        <h4 className="title">{item.title}</h4>
                        <p className="subtitle">{item.subtitle}</p>
                    </div>
                </div>
            </a>
        );
    });

    return (
        <div className={itemClassNames}>
            {items}
        </div>
    );
};

News.propTypes = propTypes;
News.defaultProps = defaultProps;

export default News;
