import React, { Component } from 'react';
import { TimelineMax, Sine } from 'gsap';

const propTypes = {
    children: React.PropTypes.node.isRequired,
};

const defaultProps = {};

class Splash extends Component {
    constructor(props) {
        super(props);

        this.refFirst = null;
        this.refSecond = null;
        this.refThird = null;
        this.container = null;

        this.pageContainer = null;

        this.getTween = this.getTween.bind(this);
    }

    getTween() {
        const timeline = new TimelineMax({
            paused: true,
        });

        timeline.staggerFromTo(
            [this.refFirst, this.refSecond],
            0.8,
            {
                autoAlpha: 0,
            },
            {
                autoAlpha: 1,
            },
            1,
            0.5,
        );

        const toTween = { gray: 0 };
        timeline.fromTo(
            toTween,
            3,
            {
                gray: 0,
            },
            {
                ease: Sine.easeInOut,
                gray: 30,
                onUpdate: () => {
                    if (this.container !== null) {
                        this.container.style.filter = `grayscale(${toTween.gray}%)`;
                    }
                },
            },
            1,
        );

        timeline.fromTo(
            [this.container],
            2.2,
            {
                autoAlpha: 1,
            },
            {
                autoAlpha: 0,
                ease: Sine.easeInOut,
            },
            3.4,
        );

        timeline.fromTo(
            [this.pageContainer],
            2.2,
            {
                autoAlpha: 0,
            },
            {
                autoAlpha: 1,
                ease: Sine.easeInOut,
            },
            3.4,
        );

        return timeline;
    }

    render() {
        const { children } = this.props;
        const phare = "d'un théâtre phare";

        return (
            <div>
                <button
                    className="splash"
                    onClick={this.hide}
                    ref={(ref) => {
                        this.container = ref;
                    }}
                >
                    <div className="animation-container">
                        <div className="splash-container">
                            <h1
                                ref={(ref) => {
                                    this.refFirst = ref;
                                }}
                                className="splash-logo"
                            >
                                Nouveau
                                <br />
                                Théâtre
                                <br />
                                Expérimental
                                <br />
                                <span className="icon icon-return-large" />
                                Archives
                            </h1>
                            <p
                                ref={(ref) => {
                                    this.refSecond = ref;
                                }}
                                className="splash-subtitle"
                            >
                                Les documents historiques
                                <br />
                                {phare}
                                <br />
                                du Québec moderne
                            </p>
                        </div>
                    </div>
                </button>
                <div
                    className="splash-content"
                    ref={(ref) => {
                        this.pageContainer = ref;
                    }}
                >
                    {children}
                </div>
            </div>
        );
    }
}

Splash.propTypes = propTypes;
Splash.defaultProps = defaultProps;

export default Splash;
