// import _ from 'lodash';
import { TimelineLite } from 'gsap';
import * as PIXI from 'pixi.js';
import merge from 'lodash/merge';

import Segments from './segments';
import ToggleHand from './actions/toggleHand';
import Captions from './actions/captions';

class Grid {
    constructor(options, toggleOverlay = null) {
        this.onTick = this.onTick.bind(this);

        this.toggleOverlay = toggleOverlay;
        this.size = 18;
        this.lineOffset = 0;

        this.width = options.width;
        this.height = options.height;
        this.mobile = options.mobile;

        this.cols = Math.ceil(this.width / this.size);
        this.rows = Math.ceil(this.height / this.size);

        this.imageData = {};
        this.binaryImageData = {};
        this.components = {};

        this.timeline = null;
        this.grid = [];
        this.captions = null;

        this.container = new PIXI.Container();

        this.create();
    }

    getTimelineForCue(opts) {
        if (this.components[opts.src]) {
            return this.components[opts.src].getTimeline();
        }
        return new TimelineLite();
    }

    getCaptions(segments) {
        if (segments) {
            return this.captions.getTimeline(segments);
        }
        return new TimelineLite();
    }

    loadImages(cues) {
        const options = {
            scene: this.container,
            width: this.width,
            height: this.height,
            mobile: this.mobile,
            toggleOverlay: this.toggleOverlay,
        };

        // For everything
        Object.keys(cues).forEach((timestamp) => {
            const cue = cues[timestamp];
            if (cue.type && Segments[cue.type]) {
                const component = new Segments[cue.type](merge(cue, cue.options, options));
                component.init();
                this.components[cue.src] = component;
                this.componentContainer.addChild(this.components[cue.src].container);
                this.topContainer.addChild(this.components[cue.src].topContainer);
            }
        });

        // For the captions
        this.captions = new Captions(options, cues);
        this.topContainer.addChild(this.captions.container);
    }

    getActions(cues, toggle, fullWidth, fullHeight) {
        const hands = [];
        const params = {
            width: fullWidth,
            height: fullHeight,
            toggleOverlay: toggle,
        };
        Object.keys(cues).forEach((timestamp) => {
            const cue = cues[timestamp];
            if (cue.options && cue.options.overlay) {
                const action = toggle.bind(this, cue.options.overlay);
                const component = new ToggleHand({
                    ...cue.options,
                    ...params,
                    mobile: this.mobile,
                    toggle: () => {
                        action();
                    },
                });
                hands.push(component);
            }
        });
        return hands;
    }

    create() {
        if (this.container !== null) {
            this.container.destroy({
                children: true,
            });
        }
        this.container = new PIXI.Container();

        this.componentContainer = new PIXI.Container();
        this.container.addChild(this.componentContainer);

        this.topContainer = new PIXI.Container();
        this.container.addChild(this.topContainer);
    }

    resize(props) {
        const { width, height, mobile } = props;
        this.width = width;
        this.height = height;
        this.mobile = mobile;

        this.cols = Math.ceil(width / this.size);
        this.rows = Math.ceil(height / this.size);

        Object.keys(this.components).forEach((key) => {
            if (this.components[key].resize) {
                this.components[key].resize({ width, height, mobile });
            }
        });

        if (this.captions !== null) {
            this.captions.resize({ width, height, mobile });
        }
    }

    onTick() {
        // TODO: This one is actually called somewhere
    }
}

export default Grid;
