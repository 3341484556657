/* eslint-disable react/jsx-indent */
import React from 'react';
import Relay from 'react-relay';
import { snake as snakeCase } from 'change-case';

import Title from '../partials/Title';
import Meta from '../partials/Meta';
import Footer from '../../containers/partials/Footer';

import { removeDiacritics } from '../../lib/str';

import CategoriesSection from '../../containers/sections/Categories';

import DescriptionSection from '../sections/Description';
import CreditsSection from '../sections/Credits';
import CreditsTextSection from '../sections/CreditsText';
import BubblesListSection from '../sections/BubblesList';

const propTypes = {
    bubble: React.PropTypes.shape({
        bubble_id: React.PropTypes.string,
        snippet: React.PropTypes.shape({
            link: React.PropTypes.string,
            title: React.PropTypes.string,
            subtitle: React.PropTypes.string,
        }),
        related: React.PropTypes.array,
        description: React.PropTypes.string,
        credits: React.PropTypes.array,
        credits_other: React.PropTypes.string,
    }).isRequired,
    newsletter: React.PropTypes.object,
};

const BubbleArtistPage = (props) => {
    const id = props.bubble.bubble_id;
    const { description, snippet, credits, related } = props.bubble;

    const { title } = snippet;
    const creditsOther = props.bubble.credits_other;

    const { newsletter = null } = props || {};

    const filteredCredits = {};
    credits.forEach((credit) => {
        const cid = snakeCase(removeDiacritics(credit.position));
        filteredCredits[cid] = { id: cid, name: credit.position };
    });
    const categoriesCredits = Object.keys(filteredCredits).map((filterId) => ({
        id: filterId,
        name: filteredCredits[filterId].name,
    }));

    return (
        <article className="container container-page container-artist">
            <Meta bubble={props.bubble} section="Artisan" />

            {title ? <Title text={title} newsletter={newsletter} /> : null}

            <DescriptionSection description={description} />

            <CreditsSection title="Crédits" name="credits-artiste" credits={credits} />

            <CreditsTextSection title="Autres crédits" credits={creditsOther} />

            <CategoriesSection categories={categoriesCredits} section="artists" />

            <BubblesListSection name={`shows:${id}`} title="Productions" />

            {related && related.length > 0
                ? related.map((section) => (
                      <BubblesListSection
                          key={`related-${section.id}`}
                          name={section.id}
                          title={section.title}
                          sectionSize="small"
                      />
                  ))
                : null}

            <Footer />
        </article>
    );
};

BubbleArtistPage.propTypes = propTypes;

BubbleArtistPage.RelayComponent = Relay.createContainer(BubbleArtistPage, {
    fragments: {
        bubble: () =>
            Relay.QL`
                fragment on BubbleArtist {
                    bubble_id
                    snippet {
                        title
                        subtitle
                        link
                    }
                    related {
                        id
                        title
                    }
                    description
                    credits {
                        position
                        bubble {
                            snippet {
                                link
                                title
                            }
                        }
                    }
                    credits_other
                }
            `,
    },
});

export default BubbleArtistPage;
