import React, { Component } from 'react';
import AboutPageComponent from '../../components/pages/About';

const propTypes = {};

class AboutPageContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        // Load markdown / json here or something ?
        return <AboutPageComponent />;
    }
}

AboutPageContainer.propTypes = propTypes;

export default AboutPageContainer;
