/**
 * Constants
 */
const LAYOUT_SCROLL_Y = 'LAYOUT_SCROLL_Y';
const LAYOUT_SIDEBAR_CLOSE = 'LAYOUT_SIDEBAR_CLOSE';
const LAYOUT_SIDEBAR_OPEN = 'LAYOUT_SIDEBAR_OPEN';
const LAYOUT_HEADER_COLLAPSE = 'LAYOUT_HEADER_COLLAPSE';
const LAYOUT_HEADER_UNCOLLAPSE = 'LAYOUT_HEADER_UNCOLLAPSE';
const LAYOUT_SHOW_COOKIES_POPUP = 'LAYOUT_SHOW_COOKIES_POPUP';

/**
 * Actions creator
 */

const updateScroll = (scrollY) => ({
    type: LAYOUT_SCROLL_Y,
    payload: scrollY,
});

const closeSidebar = () => ({
    type: LAYOUT_SIDEBAR_CLOSE,
});

const openSidebar = () => ({
    type: LAYOUT_SIDEBAR_OPEN,
});

const collapseHeader = () => ({
    type: LAYOUT_HEADER_COLLAPSE,
});

const uncollapseHeader = () => ({
    type: LAYOUT_HEADER_UNCOLLAPSE,
});

const showCookiesPopup = (open = false) => ({
    type: LAYOUT_SHOW_COOKIES_POPUP,
    payload: open,
});

/**
 * Exports
 */
const LayoutActions = {
    updateScroll,
    closeSidebar,
    openSidebar,
    collapseHeader,
    uncollapseHeader,
    showCookiesPopup,
};

export default LayoutActions;
export {
    LAYOUT_SCROLL_Y,
    LAYOUT_SIDEBAR_CLOSE,
    LAYOUT_SIDEBAR_OPEN,
    LAYOUT_HEADER_COLLAPSE,
    LAYOUT_HEADER_UNCOLLAPSE,
    LAYOUT_SHOW_COOKIES_POPUP,
    updateScroll,
    closeSidebar,
    openSidebar,
    collapseHeader,
    uncollapseHeader,
    showCookiesPopup,
};
