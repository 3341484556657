import { TimelineLite, TweenMax } from 'gsap';
import * as PIXI from 'pixi.js';
import merge from 'lodash/merge';
import GlowFilter from '../filters/glow/glow';
import BeepSound from '../../sounds/beep';
import { getSizeWithinMax } from '../../utils/size';

class Intro {
    constructor(options = { scene: null, width: 0, height: 0 }) {
        this.setOptions(options);

        this.tv = null;
        this.film = null;
        this.enveloppe = null;

        this.timeline = null;
        this.filter = new GlowFilter(20, 2, 1, 0xff0000, 0.1);
        this.sound = new BeepSound('/audio/general/beepbeep2_edit.mp3', 'lowPassFilter');

        this.container = new PIXI.Container();
        this.topContainer = new PIXI.Container();

        this.containers = [this.container, this.topContainer];

        this.init = this.init.bind(this);
        this.resize = this.resize.bind(this);

        this.init();

        return this;
    }

    setOptions(options = {}) {
        const opts = merge(
            {
                time: 1,
                transitionTime: 2,
                playTime: 5,
                wait: 45,
                tv: '/img/textures/tele/tele.json',
                film: '/img/textures/film/film.json',
                toggleOverlay: () => {},
            },
            options,
        );

        this.options = opts;
    }

    init() {
        this.tv = this.addBonhomme(this.options.tv);
        this.film = this.addBonhomme(this.options.film);
    }

    addBonhomme(src) {
        const frameData = PIXI.loader.resources[src].data.frames;
        const frames = [];
        Object.keys(frameData).forEach((frame) => {
            frames.push(PIXI.Texture.fromFrame(frame));
        });

        const sprite = new PIXI.extras.AnimatedSprite(frames);
        sprite.loop = false;
        sprite.anchor.set(0.5, 1);
        sprite.animationSpeed = 0.1;
        sprite.filters = [this.filter];

        this.resizeBonhomme(sprite);

        this.container.addChild(sprite);

        return sprite;
    }

    resizeBonhomme(sprite) {
        if (sprite) {
            const coords = this.getCoords(sprite);
            sprite.width = coords.width; // eslint-disable-line
            sprite.height = coords.width * 0.6666; // eslint-disable-line
            TweenMax.set(sprite, {
                alpha: 0,
                x: this.options.width / 2,
                y: this.options.height,
            });
        }
    }

    resize(options) {
        this.setOptions(options);
        // console.log('resize intro');
        this.resizeBonhomme(this.tv);
        this.resizeBonhomme(this.film);
    }

    getTimeline(start = 0, onComplete = null) {
        let time = start;

        const timeline = new TimelineLite({
            onComplete: () => {
                if (onComplete !== null) {
                    onComplete();
                }
            },
        });

        timeline.set(this.tv, { alpha: 0 });
        timeline.set(this.film, { alpha: 0 });

        timeline.set(this.filter.uniforms, {
            outerStrength: 20,
            innerStrength: 2,
        });

        timeline.to(
            this.tv,
            this.options.transitionTime / 1.05,
            {
                alpha: 1,
            },
            time,
        );

        timeline.to(
            this.filter.uniforms,
            this.options.transitionTime + 1,
            {
                outerStrength: 0,
                innerStrength: 0,
            },
            time,
        );

        timeline.add(() => {
            this.sound.play();
        }, time + 1);

        time += this.options.transitionTime / 1.05;

        timeline.add(() => {
            this.tv.gotoAndPlay(0);
        }, time);

        time += this.options.transitionTime * 3;

        timeline.to(
            this.tv,
            this.options.transitionTime,
            {
                alpha: 0,
            },
            time,
        );

        timeline.to(
            this.filter.uniforms,
            this.options.transitionTime + 1,
            {
                outerStrength: 20,
                innerStrength: 2,
            },
            time,
        );

        time += 19;

        timeline.to(
            this.film,
            this.options.transitionTime / 1.05,
            {
                alpha: 1,
            },
            time,
        );

        timeline.to(
            this.filter.uniforms,
            this.options.transitionTime + 1,
            {
                outerStrength: 0,
                innerStrength: 0,
            },
            time,
        );

        time += this.options.transitionTime / 1.05;

        timeline.add(() => {
            this.film.gotoAndPlay(0);
        }, time);

        time += this.options.transitionTime * 3;

        timeline.to(
            this.film,
            this.options.transitionTime,
            {
                alpha: 0,
            },
            time,
        );

        timeline.to(
            this.filter.uniforms,
            this.options.transitionTime + 1,
            {
                outerStrength: 20,
                innerStrength: 2,
            },
            time,
        );

        time += this.options.transitionTime * 6;

        // timeline.to(
        //     this.enveloppe,
        //     this.options.transitionTime / 1.05,
        //     {
        //         alpha: 1,
        //     },
        //     time,
        // );

        // timeline.to(
        //     this.filter.uniforms,
        //     this.options.transitionTime + 1,
        //     {
        //         outerStrength: 0,
        //         innerStrength: 0,
        //     },
        //     time,
        // );

        time += this.options.transitionTime / 1.05;

        // timeline.add(() => {
        //     this.enveloppe.gotoAndPlay(0);
        // }, time);

        time += this.options.transitionTime * 2;

        // timeline.to(
        //     this.enveloppe,
        //     this.options.transitionTime,
        //     {
        //         alpha: 0,
        //     },
        //     time,
        // );

        // timeline.to(
        //     this.filter.uniforms,
        //     this.options.transitionTime + 1,
        //     {
        //         outerStrength: 20,
        //         innerStrength: 2,
        //     },
        //     time,
        // );

        return timeline;
    }

    destroy() {
        this.containers.forEach((container) => {
            container.destroy({
                children: true,
            });
        });
        if (this.sound) {
            this.sound.destroy();
        }
    }

    getCoords(sprite) {
        return getSizeWithinMax(
            sprite.width,
            sprite.height,
            this.options.width,
            this.options.height,
            { cover: false },
        );
    }
}

export default Intro;
