/* eslint-disable react/jsx-indent */
import React from 'react';
import Relay from 'react-relay';

import Title from '../partials/Title';
import Meta from '../partials/Meta';
import Footer from '../../containers/partials/Footer';
import CreditsHtml from '../partials/CreditsHtml';

import Audio from '../Audio';

import DescriptionSection from '../sections/Description';
import CreditsTextSection from '../sections/CreditsText';
import BubblesListSection from '../sections/BubblesList';

const propTypes = {
    bubble: React.PropTypes.shape({
        bubble_id: React.PropTypes.string,
        snippet: React.PropTypes.shape({
            link: React.PropTypes.string,
            title: React.PropTypes.string,
            subtitle: React.PropTypes.string,
        }),
        // eslint-disable-next-line react/forbid-prop-types
        audios: React.PropTypes.array,
        description: React.PropTypes.string,
        credits_technicians: React.PropTypes.string,
        credits_other: React.PropTypes.string,
    }).isRequired,
    newsletter: React.PropTypes.object,
};

const BubbleAudioPage = (props) => {
    const id = props.bubble.bubble_id;
    const { title, subtitle } = props.bubble.snippet;
    const { description, audios } = props.bubble;

    const creditsTechnicians = props.bubble.credits_technicians;
    const creditsOther = props.bubble.credits_other;

    const { newsletter = null } = props || {};

    return (
        <article className="container-page container-audio">
            <Meta bubble={props.bubble} section="Audio" />

            {title ? (
                <div className="container">
                    <Title text={title} newsletter={newsletter} />
                </div>
            ) : null}

            {audios
                ? audios.map((audio, idx) => (
                      <section
                          key={`audioplayer-${audio.id}-${idx + 1}`}
                          className="page-section page-section-audio"
                      >
                          <Audio audio={audio} />
                      </section>
                  ))
                : null}

            <CreditsHtml text={creditsOther} />

            <div className="container">
                <DescriptionSection title={subtitle} description={description} />

                <CreditsTextSection title="Techniciens" credits={creditsTechnicians} />

                <BubblesListSection name={`shows:${id}`} title="Productions" />

                <BubblesListSection name={`audios:${id}`} title="Audio" sectionSize="small" />

                <Footer />
            </div>
        </article>
    );
};

BubbleAudioPage.propTypes = propTypes;

BubbleAudioPage.RelayComponent = Relay.createContainer(BubbleAudioPage, {
    fragments: {
        bubble: () =>
            Relay.QL`
                fragment on BubbleAudio {
                    bubble_id
                    snippet {
                        title
                        subtitle
                        link
                        picture_facebook: picture(filter:"facebook") {
                            url
                        }
                    }
                    audios {
                        id
                        name
                        src
                        duration
                        picture_full: picture(filter:"big"){
                            url
                        }
                        picture_mask: picture(filter:"big_blue"){
                            url
                        }
                        picture_mask_loading: picture(filter:"big_gray"){
                            url
                        }
                    }
                    description
                    credits_technicians
                    credits_other
                }
            `,
    },
});

export default BubbleAudioPage;
