import Immutable from 'immutable';
import {
    SUBSECTIONS_SET,
} from '../actions/ActionTypes';

const initialState = [];

const SubsectionsReducer = (previousState, action) => {
    const state = previousState || new Immutable.List(initialState);

    switch (action.type) {
    case SUBSECTIONS_SET:
        return new Immutable.List(action.payload);
    default:
        return state;
    }
};

export default SubsectionsReducer;
