import React from 'react';

const propTypes = {
    text: React.PropTypes.string,
    caption: React.PropTypes.oneOfType([React.PropTypes.string, React.PropTypes.node]),
};

const defaultProps = {
    text: '',
    caption: null,
};

// eslint-disable-next-line
const CreditsHtml = ({ text, caption }) =>
    text ? (
        <div className="container">
            <div className="caption gallery-caption">
                <p>
                    <strong dangerouslySetInnerHTML={{ __html: text }} />
                </p>
                {caption || null}
            </div>
        </div>
    ) : null;

CreditsHtml.propTypes = propTypes;
CreditsHtml.defaultProps = defaultProps;

export default CreditsHtml;
