import Immutable from 'immutable';
import {
    LISTS_UPDATE_SETTINGS,
    LISTS_UPDATE_SETTING,
    LISTS_CLEAR_SETTINGS,
} from '../actions/ListsActions';

const initialState = {};

const ListsReducer = (previousState, action) => {
    const state = previousState || new Immutable.Map(initialState);

    switch (action.type) {

    case LISTS_UPDATE_SETTINGS:
        return state.set(action.payload.name, action.payload.settings);

    case LISTS_UPDATE_SETTING:
        return state.setIn([action.payload.name, action.payload.key], action.payload.value);

    case LISTS_CLEAR_SETTINGS:
        return state.delete(action.payload.name);

    default:
        return state;
    }
};

export default ListsReducer;
