import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import withUrl from '../../lib/withUrl';
import SidebarComponent from '../../components/partials/Sidebar';
import { getCurrentSectionFromLocation } from '../../lib/utils';

const propTypes = {
    sections: React.PropTypes.shape({
        filter: React.PropTypes.func,
        toJS: React.PropTypes.func,
    }).isRequired,
    url: React.PropTypes.shape({
        route: React.PropTypes.func,
    }).isRequired,
    location: React.PropTypes.shape({
        pathname: React.PropTypes.string,
    }).isRequired,
};

const SidebarContainer = (props) => {
    const { sections, location, url, ...otherProps } = props;

    const isCatalog = location.pathname === url.route('catalog.index');
    const selectedSections = isCatalog
        ? sections
        : sections.filter(
              (section) => location.pathname === url.route(`catalog.${section.key}.index`),
          );
    const currentSection = getCurrentSectionFromLocation(sections, url, location);
    // console.log('cs', currentSection);

    let filters =
        currentSection && currentSection.filters && currentSection.filters.length > 0
            ? currentSection.filters
            : selectedSections.reduce(
                  (currentFilters, value) => currentFilters.concat(value.filters),
                  [],
              );
    // Hide the hidden filters
    if (filters.length > 0) {
        filters = filters
            .filter(
                (filter) =>
                    typeof filter.is_hidden_from_sidebar === 'undefined' ||
                    !filter.is_hidden_from_sidebar,
            )
            .map((filter) => filter.name);
    }

    return <SidebarComponent {...otherProps} filters={filters} url={url} />;
};

SidebarContainer.propTypes = propTypes;

export default withUrl(
    withRouter(
        connect((state) => ({
            sections: state.sections,
        }))(SidebarContainer),
    ),
);
