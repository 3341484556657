import Relay, { Route } from 'react-relay';

class BubblesListRoute extends Route
{
    static routeName = 'BubblesList';

    static queries = {
        list: (Component, variables) => {
            const fragmentVariables = {};

            if (variables.filters) {
                fragmentVariables.filters = variables.filters;
            }
            if (variables.pageSize) {
                fragmentVariables.pageSize = variables.pageSize;
            }
            if (variables.letter) {
                fragmentVariables.letter = variables.letter;
            }

            return Relay.QL`
                query {
                    bubbles_list(
                        name: $name,
                    ) {
                        ${Component.getFragment('list', fragmentVariables)}
                    }
                }
            `;
        },
    };

    static paramDefinitions = {
        name: {
            required: true,
        },
    };
}

export default BubblesListRoute;
