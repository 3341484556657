import React, { Component } from 'react';
import Cookies from 'js-cookie';

const propTypes = {
    text: React.PropTypes.string,
    className: React.PropTypes.string,
    subtitle: React.PropTypes.bool,
    newsletter: React.PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

const defaultProps = {
    text: null,
    className: 'page-title',
    subtitle: false,
    newsletter: null,
};

class Title extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showNewsletter: parseInt(Cookies.get('showNewsletter') || 0, 10) < 5,
        };
    }

    componentDidMount() {
        const { newsletter } = this.props;
        if (newsletter !== null) {
            const previous = parseInt(Cookies.get('showNewsletter') || 0, 10);
            Cookies.set('showNewsletter', previous + 1, { expires: 7 });
        }
    }

    render() {
        const { showNewsletter } = this.state;
        const { text, className, subtitle, newsletter } = this.props;
        const { fields = null } = newsletter || {};
        const { title = null, url = null } = fields || {};
        const { fr: titleText = null } = title || {};
        const { fr: link = null } = url || {};

        if (!text) {
            return <div className={className} />;
        }

        return (
            <div className={className}>
                {newsletter !== null && titleText !== null && showNewsletter ? (
                    <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="newsletter-bar"
                    >
                        <div className="inner-bar">
                            <div className="letter" />
                            <div className="text">{titleText}</div>
                        </div>
                    </a>
                ) : null}
                {subtitle ? (
                    <h4 className="subtitle">{text}</h4>
                ) : (
                    <h1 className="title">{text}</h1>
                )}
            </div>
        );
    }
}

Title.propTypes = propTypes;
Title.defaultProps = defaultProps;

export default Title;
