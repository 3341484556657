import { TimelineLite } from 'gsap';
import * as PIXI from 'pixi.js';
import merge from 'lodash/merge';

class Captions {
    constructor(options = { scene: null, width: 0, height: 0 }, cues = []) {
        this.setOptions(options);

        this.timeline = null;
        this.label = null;
        this.labels = {};

        this.container = new PIXI.Container();
        this.container.visible = true;

        this.containers = [this.container];

        this.create = this.create.bind(this);
        this.resize = this.resize.bind(this);

        this.create(cues);

        return this;
    }

    setOptions(options = {}) {
        const opts = merge(
            {
                time: 1,
                transitionTime: 1.4,
                wait: 2,
                label: null,
                labels: [],
                captionStyle: {
                    fontFamily: 'Roboto',
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fill: '#ffffff',
                    stroke: '#000000',
                    strokeThickness: 2,
                    wordWrap: false,
                },
                hoverStyle: {
                    fontFamily: 'Roboto',
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fill: '#000000',
                    stroke: '#ff0000',
                    strokeThickness: 12,
                    wordWrap: false,
                },
            },
            options,
        );

        this.options = opts;
    }

    create(cues) {
        Object.keys(cues).map(timestamp => this.createCue(timestamp, cues[timestamp]));
    }

    createCue(timestamp, cue) {
        if (!cue.label) return;

        const height = this.options.height;
        const label = cue.label;

        const style = new PIXI.TextStyle(this.options.captionStyle);
        const hoverStyle = new PIXI.TextStyle(this.options.captionStyle);

        const text = new PIXI.Text(label || '', style);
        text.x = 10;
        text.y = this.options.mobile ? height - 67 : height - 27;
        text.alpha = 0;

        text.visible = false;
        text.interactive = false;
        text.buttonMode = false;

        text.on('pointerdown', () => {
            if (this.options.url) {
                window.open(this.options.url, '_blank');
            }
        });

        text.on('mouseover', () => {
            text.style = hoverStyle;
        });

        text.on('mouseout', () => {
            text.style = style;
        });

        this.container.addChild(text);
        this.labels[timestamp] = text;
    }

    resize({ height, mobile }) {
        Object.keys(this.labels).forEach((name) => {
            const text = this.labels[name];
            text.x = 10;
            text.y = mobile ? height - 67 : height - 27;
        });
    }

    getTimeline(cues) {
        const timeline = new TimelineLite();
        let previousTimestamp = null;
        const size = Object.keys(cues).length - 1;
        Object.keys(cues).forEach((timestamp, index) => {
            if (this.labels[timestamp]) {
                if (previousTimestamp && cues[timestamp].label === cues[previousTimestamp].label) {
                    // Do nothing if its the same
                } else {
                    if (previousTimestamp) {
                        // Fade out the last one
                        const fadeOut = this.fadeOut(this.labels[previousTimestamp]);
                        timeline.add(fadeOut, cues[timestamp].time - this.options.transitionTime);
                    }
                    // Fade in the other
                    const fadeIn = this.fadeIn(this.labels[timestamp]);
                    timeline.add(fadeIn, cues[timestamp].time);
                    previousTimestamp = timestamp;
                }
            }
            if (index === size && previousTimestamp) {
                const fadeOutLast = this.fadeOut(this.labels[previousTimestamp]);
                timeline.add(fadeOutLast, cues[timestamp].time + 5);
            }
        });
        return timeline;
    }

    fadeOut(label = '', start = 0) {
        const time = start;
        const timeline = new TimelineLite();
        timeline.fromTo(
            label,
            this.options.transitionTime,
            {
                alpha: 1,
            },
            {
                alpha: 0,
            },
            time,
        );
        timeline.add(() => {
            if (label) {
                label.visible = false; // eslint-disable-line
                label.interactive = false; // eslint-disable-line
                label.buttonMode = false; // eslint-disable-line
            }
        }, time + this.options.transitionTime);

        return timeline;
    }

    fadeIn(label = '', start = 0) {
        const time = start;
        const timeline = new TimelineLite();

        timeline.add(() => {
            if (label) {
                label.visible = true; // eslint-disable-line
                label.interactive = true; // eslint-disable-line
                label.buttonMode = true; // eslint-disable-line
            }
        }, time);

        timeline.fromTo(
            label,
            this.options.transitionTime,
            {
                alpha: 0,
            },
            {
                alpha: 1,
            },
            time,
        );

        return timeline;
    }

    destroy() {
        this.containers.forEach((container) => {
            container.destroy({
                children: true,
            });
        });
    }
}

export default Captions;
