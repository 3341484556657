import Relay, { Route } from 'react-relay';

class FiltersRoute extends Route
{
    static routeName = 'Filters';

    static queries = {
        filters: () => Relay.QL`query { filters(names: $names) }`,
    };

    static paramDefinitions = {
        names: {
            required: true,
        },
    };
}

export default FiltersRoute;
