/* global TweenMax */
import React, { Component } from 'react';
import Relay from 'react-relay';
import screenfull from 'screenfull';
// import Transitionable from 'react-transitionable';
import Draggable from 'react-draggable';
import 'gsap';

import { getWidth } from '../lib/getSize';
import CreditsHtml from './partials/CreditsHtml';

const propTypes = {
    title: React.PropTypes.string,
    defaultCredits: React.PropTypes.string,
    items: React.PropTypes.array,
    currentIndex: React.PropTypes.number,
    width: React.PropTypes.oneOfType([React.PropTypes.string, React.PropTypes.number]),
    height: React.PropTypes.oneOfType([React.PropTypes.string, React.PropTypes.number]),
};

const defaultProps = {
    title: null,
    defaultCredits: null,
    items: [],
    currentIndex: 0,
    width: 0,
    height: 0,
};

class Gallery extends Component {
    static transitionImageIn(transitionable, opts, done) {
        TweenMax.fromTo(
            transitionable.el,
            opts.mounting ? 0 : 0.4,
            {
                opacity: 0,
            },
            {
                opacity: 1,
                onComplete: done,
            },
        );
    }

    static transitionImageOut(transitionable, opts, done) {
        TweenMax.to(transitionable.el, opts.mounting ? 0 : 0.4, {
            opacity: 0,
            onComplete: done,
        });
    }

    static transitionMenuItemIn(transitionable, opts, done) {
        TweenMax.fromTo(
            transitionable.el,
            opts.mounting ? 0 : 0.4,
            {
                opacity: 0,
            },
            {
                opacity: 1,
                onComplete: done,
            },
        );
    }

    static transitionMenuItemOut(transitionable, opts, done) {
        TweenMax.to(transitionable.el, opts.mounting ? 0 : 0.4, {
            opacity: 0,
            onComplete: done,
        });
    }

    constructor(props) {
        super(props);

        this.dragTimeout = null;

        this.onMenuItemClick = this.onMenuItemClick.bind(this);

        this.onClickRotate = this.onClickRotate.bind(this);
        this.onClickFullscreen = this.onClickFullscreen.bind(this);
        this.onClickZoomIn = this.onClickZoomIn.bind(this);
        this.onClickZoomOut = this.onClickZoomOut.bind(this);

        this.onClickNext = this.onClickNext.bind(this);
        this.onClickPrevious = this.onClickPrevious.bind(this);

        this.onStartDrag = this.onStartDrag.bind(this);
        this.onStopDrag = this.onStopDrag.bind(this);

        this.onResize = this.onResize.bind(this);
        this.gotoSlide = this.gotoSlide.bind(this);
        this.updateSize = this.updateSize.bind(this);

        this.renderMenuItem = this.renderMenuItem.bind(this);
        this.renderMenuItems = this.renderMenuItems.bind(this);

        this.refNav = null;
        this.refGallery = null;

        this.state = {
            currentIndex: this.props.currentIndex,
            nextIndex: -1,
            width: '100%',
            height: '100%',
            angle: 0,
            scale: 100,
            x: 0,
            y: 0,
            menuWidth: 0,
            menuItemWidth: 0,
            viewerHeight: 0,
            isFullScreen: false,
            dragging: false,
            goNextOnEndDrag: false,
            windowWidth: getWidth(),
        };
    }

    componentDidMount() {
        this.updateSize();
        window.addEventListener('resize', this.onResize);
        if (screenfull.enabled) {
            document.addEventListener(screenfull.raw.fullscreenchange, () => {
                this.updateSize();
            });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.dragTimeout);
        window.removeEventListener('resize', this.onResize);
    }

    onResize() {
        this.updateSize();
    }

    onClickPrevious(e) {
        e.preventDefault();
        const currentIndex = this.state.currentIndex;
        const index = currentIndex === 0 ? this.props.items.length - 1 : currentIndex - 1;
        this.gotoSlide(index);
    }

    onClickNext(e) {
        if (typeof e !== 'undefined') {
            e.preventDefault();
        }
        if (this.state.dragging) {
            return;
        }
        const currentIndex = this.state.currentIndex;
        const index = currentIndex === this.props.items.length - 1 ? 0 : currentIndex + 1;
        this.gotoSlide(index);
    }

    onStartDrag(e) {
        if (this.state.windowWidth >= 768) {
            this.dragTimeout = setTimeout(() => {
                this.setState({
                    dragging: true,
                });
            }, 300);
        } else {
            e.preventDefault();
        }
    }

    onStopDrag() {
        if (this.state.dragging === false) {
            this.onClickNext();
        }
        clearTimeout(this.dragTimeout);
        this.setState({
            dragging: false,
        });
    }

    onClickFullscreen(e) {
        e.preventDefault();
        this.setState(
            {
                isFullScreen: !this.state.isFullScreen,
            },
            () => {
                const el = this.refGallery;
                if (screenfull.enabled) {
                    screenfull.toggle(el);
                }
            },
        );
    }

    onClickZoomIn(e) {
        e.preventDefault();
        this.setState({
            scale: this.state.scale < 250 ? (this.state.scale += 15) : this.state.scale,
        });
    }

    onClickZoomOut(e) {
        e.preventDefault();
        this.setState({
            scale: this.state.scale > 50 ? (this.state.scale -= 15) : this.state.scale,
        });
    }

    onClickRotate(e) {
        e.preventDefault();
        const angle = this.state.angle === 360 ? 90 : this.state.angle + 90;
        this.setState({
            angle,
        });
    }

    onMenuItemClick(e, it, index) {
        e.preventDefault();
        this.gotoSlide(index);
    }

    updateSize() {
        const { isFullScreen } = this.state;
        const el = this.refGallery;
        const elWidth = el.getBoundingClientRect().width;
        const elHeight = el.getBoundingClientRect().height;

        const nav = this.refNav;

        const leftNav = nav.querySelector('.col-left');
        const rightNav = nav.querySelector('.col-right');

        const colLeftWidth = leftNav !== null ? leftNav.getBoundingClientRect().width : 0;
        const colRightWidth = rightNav !== null ? rightNav.getBoundingClientRect().width : 0;

        const menuItem = nav.querySelector('.menu-item:first-child');
        const menuItemWidth = menuItem !== null ? menuItem.getBoundingClientRect().width : 50;

        this.setState({
            width: elWidth - 500,
            height: elHeight,
            menuWidth: window.innerWidth - colLeftWidth - colRightWidth,
            menuItemWidth,
            viewerHeight: window.innerHeight / (isFullScreen ? 1 : 1.33),
        });
    }

    gotoSlide(index) {
        this.setState({
            currentIndex: index,
            angle: 0,
            scale: 100,
            dragging: false,
        });
    }

    renderMenuItems() {
        const menuItemWidth = this.state.menuItemWidth;
        const itemsCount = this.props.items.length;
        const numberOfItems = !menuItemWidth
            ? itemsCount
            : Math.max(Math.floor(this.state.menuWidth / menuItemWidth), 1);

        let start =
            itemsCount <= numberOfItems
                ? 0
                : Math.max(this.state.currentIndex + 1 - numberOfItems, 0); // eslint-disable-line
        const end = Math.min(start + numberOfItems, itemsCount);

        if (end - start < numberOfItems && itemsCount > numberOfItems) {
            start = Math.max(end - numberOfItems, 0);
        }

        const items = [];
        for (let i = start; i < end; i += 1) {
            if (this.props.items[i].picture_mini) {
                items.push(this.renderMenuItem(this.props.items[i], i));
            }
        }

        return items;
    }

    renderMenuItem(it, i) {
        const key = `menu-item-${i}`;

        const imageStyle = {
            backgroundImage: `url("${it.picture_mini.url || ''}")`,
        };

        let className = 'menu-item';
        if (i === this.state.currentIndex) {
            className += ' current';
        }

        const onClick = (e) => this.onMenuItemClick(e, it, i);

        return (
            <div key={key} className={className}>
                <button onClick={onClick}>
                    <span className="image" style={imageStyle} />
                </button>
            </div>
        );
    }

    render() {
        const style = {
            width: this.props.width,
            height: this.props.height,
        };

        const viewerStyle = {
            height: this.state.viewerHeight,
        };

        const menuStyle = {
            width: this.state.menuWidth,
        };

        const slide = this.props.items[this.state.currentIndex];

        const alternateTitle = this.props.defaultCredits
            ? this.props.defaultCredits
            : this.props.title;

        const title = slide.title !== null && slide.title !== '' ? slide.title : alternateTitle;

        const subtitle =
            slide.subtitle !== null && slide.description !== '' ? slide.description : '';

        const description = slide.description !== null && slide.subtitle ? slide.subtitle : '';

        const backgroundImage = slide.picture_full !== null ? slide.picture_full.url : '';

        const caption =
            (subtitle ? ` / ${subtitle}` : '') + (description ? ` / ${description}` : '');

        const imageStyle = {
            transform: `rotate(${this.state.angle}deg) scale(${this.state.scale / 100})`,
        };

        const imgStyle = {
            backgroundColor: backgroundImage.indexOf('png') > -1 ? '#FFF' : 'transparent',
        };

        const imageKey = `image-${this.state.currentIndex}`;
        const menuItems = this.renderMenuItems(this.props.items);

        const fullScreenClassName = screenfull.isFullscreen
            ? 'btn btn-fullscreen'
            : 'btn btn-fullscreen';
        const zoomInClassName = 'btn btn-zoom-in';
        const zoomOutClassName = 'btn btn-zoom-out';
        const rotateClassName = 'btn btn-rotate';

        return (
            <div
                className="gallery"
                style={style}
                ref={(div) => {
                    this.refGallery = div;
                }}
            >
                <div
                    className="gallery-actions"
                    ref={(div) => {
                        this.refActions = div;
                    }}
                >
                    {screenfull.enabled ? (
                        <button
                            type="button"
                            className={fullScreenClassName}
                            onClick={this.onClickFullscreen}
                        />
                    ) : null}
                    <button
                        type="button"
                        className={zoomInClassName}
                        onClick={this.onClickZoomIn}
                    />
                    <button
                        type="button"
                        className={zoomOutClassName}
                        onClick={this.onClickZoomOut}
                    />
                    <button
                        type="button"
                        className={rotateClassName}
                        onClick={this.onClickRotate}
                    />
                </div>
                <div className="gallery-viewer" style={viewerStyle}>
                    {this.state.windowWidth >= 768 ? (
                        <Draggable
                            key={imageKey}
                            onStart={this.onStartDrag}
                            onDrag={(e) => {
                                e.preventDefault();
                            }}
                            onStop={this.onStopDrag}
                        >
                            <div className="dragmove">
                                <button className="image" style={imageStyle}>
                                    <img
                                        style={imgStyle}
                                        src={backgroundImage}
                                        alt={backgroundImage}
                                        draggable={false}
                                    />
                                </button>
                            </div>
                        </Draggable>
                    ) : (
                        <div className="dragmove">
                            <button className="image" style={imageStyle}>
                                <img
                                    style={imgStyle}
                                    src={backgroundImage}
                                    alt={backgroundImage}
                                    draggable={false}
                                />
                            </button>
                        </div>
                    )}
                </div>
                <div
                    className="gallery-nav"
                    ref={(div) => {
                        this.refNav = div;
                    }}
                >
                    {this.props.items.length > 1 ? (
                        <div className="cols cols-top">
                            <div className="col col-left">
                                <button
                                    type="button"
                                    className="btn btn-arrow btn-arrow-left"
                                    onClick={this.onClickPrevious}
                                />
                            </div>
                            <div className="col col-expand">
                                <div className="gallery-menu" style={menuStyle}>
                                    <div>{menuItems}</div>
                                </div>
                            </div>
                            <div className="col col-right">
                                <button
                                    type="button"
                                    className="btn btn-arrow btn-arrow-right"
                                    onClick={this.onClickNext}
                                />
                            </div>
                        </div>
                    ) : null}
                    {this.props.items.length > 0 ? (
                        <CreditsHtml text={title} caption={caption} />
                    ) : null}
                </div>
            </div>
        );
    }
}

Gallery.propTypes = propTypes;
Gallery.defaultProps = defaultProps;

const GalleryContainer = (props) => {
    const { gallery, defaultCredits } = props;

    if (!gallery || !gallery.slides || !gallery.slides.length) {
        return null;
    }

    return (
        <div className="container-gallery">
            <Gallery
                title={gallery.title}
                items={gallery.slides}
                width="100%"
                height="100%"
                defaultCredits={defaultCredits}
            />
        </div>
    );
};

GalleryContainer.propTypes = {
    gallery: React.PropTypes.shape({
        title: React.PropTypes.string,
        slides: React.PropTypes.array,
    }).isRequired,
    defaultCredits: React.PropTypes.string,
};

GalleryContainer.defaultProps = {
    defaultCredits: '',
};

Gallery.RelayComponent = Relay.createContainer(GalleryContainer, {
    fragments: {
        gallery: () =>
            Relay.QL`
                fragment on Gallery {
                    title
                    slides {
                        title
                        subtitle
                        description
                        picture_full: picture(filter:"big"){
                            url
                        }
                        picture_mini: picture(filter:"mini"){
                            url
                        }
                        picture_thumbnail: picture(filter:"thumbnail"){
                            url
                        }
                    }
                }
            `,
    },
});

export default GalleryContainer;
