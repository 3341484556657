import React, { Component } from 'react';
import { withRouter } from 'react-router';
import withUrl from '../../lib/withUrl';

const propTypes = {
    categories: React.PropTypes.arrayOf(
        React.PropTypes.shape({
            id: React.PropTypes.string,
            name: React.PropTypes.string,
        }),
    ).isRequired,
    section: React.PropTypes.string.isRequired,
    updateFilter: React.PropTypes.func.isRequired,
};

const defaultProps = {
    categories: [],
    section: 'productions',
};

class CategoriesSection extends Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick(e, value) {
        const { section } = this.props;
        // console.log('value', value);
        this.props.updateFilter(`${section}_categories`, [value]);
        this.goToSection();
    }

    goToSection() {
        const { section } = this.props;
        const sectionRoute = this.props.url.route(`catalog.${section}.index`);
        if (location.pathname !== sectionRoute) {
            this.props.router.push(sectionRoute);
        }
    }

    render() {
        const { categories, section } = this.props;
        if (!categories) return;

        return (
            <section className="page-section page-section-categories category-items">
                {categories.map((category, idx) => {
                    const onClick = e => this.onClick(e, category.id);
                    return (
                        <div className="category-item" key={`cat-${idx}-${category.id}-${section}`}>
                            <button type="button" className="btn inline" onClick={onClick}>
                                <p className="padding">
                                    <span className="label">{category.name}</span>
                                </p>
                            </button>
                        </div>
                    );
                })}
                <div className="clearfix" />
            </section>
        );
    }
}

CategoriesSection.propTypes = propTypes;
CategoriesSection.defaultProps = defaultProps;

export default withUrl(withRouter(CategoriesSection));
