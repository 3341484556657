import * as PIXI from 'pixi.js';
import vertex from '../default.vert';
import fragment from './tv.frag';

/**
 * A TV filter
 */

export default class TvFilter extends PIXI.Filter {
    constructor() {
        super(vertex, fragment);
        // this.uniforms.u_resolution = new Uint8Array(2); // [0, 0]; // new Float32Array(2);

        this.time = 0.0;
        this.distortion = 3.0;
        this.distortion2 = 4.0;
        this.speed = 0.2;
        this.rollSpeed = 0.1;
    }

    get time() {
        return this.uniforms.time;
    }
    set time(value) {
        this.uniforms.time = value;
    }

    get distortion() {
        return this.uniforms.distortion;
    }
    set distortion(value) {
        this.uniforms.distortion = value;
    }

    get distortion2() {
        return this.uniforms.distortion2;
    }
    set distortion2(value) {
        this.uniforms.distortion2 = value;
    }

    get speed() {
        return this.uniforms.speed;
    }
    set speed(value) {
        this.uniforms.speed = value;
    }

    get rollSpeed() {
        return this.uniforms.rollSpeed;
    }
    set rollSpeed(value) {
        this.uniforms.rollSpeed = value;
    }
}
